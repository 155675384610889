import { useEffect, useRef, useState } from 'react';
import User1 from '../assets/images/imgs/user1.svg';
import MoreIcon from '../assets/images/icons/ico_more.svg';
import LikeIcon from '../assets/images/icons/ico_heart.svg';
import LikeBlackIcon from '../assets/images/icons/ico_like.png';
import CommentIcon from '../assets/images/icons/ico_comment.svg';
import PlayIcon from '../assets/images/icons/ico_play.svg';
import LockIcon from '../assets/images/icons/ico_lock.svg';
import { useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
import { dispatch } from '../redux';
// import { userDashboardFeed } from "../redux/action";
import { PAYMENT_TYPE, POST_TYPE } from '../constants/App-constant';
import { S3_BASE_URL } from '../service/aws/config';
import CommentModel from './models/CommentModel';
import ShareModel from './models/ShareModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getToken } from '../utils/utility';
import SubsctiptionModel from './models/SubscriptionModel';
import UnlocakContentModel from './models/UnlockContentModel';
// import ReactPlayer from "react-player";
import defaultImg from '../assets/images/imgs/img-1.png';
import { addLike } from '../redux/action/postActions';
import DonateModel from './models/Donate';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box, Card, CardActions, CardMedia, Paper } from '@mui/material';
import playIconBlue from '../assets/images/icons/play-blue.svg';
import DefaultImage from '../assets/images/imgs/andrea-enriquez-cousino-C1HhAQrbykQ-unsplash.jpg';
import { v4 as uuid } from 'uuid';
import Masonry from '@mui/lab/Masonry';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(0.5),
  textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

export default function PostFeed({
  feedList,
  setPage,
  page,
  hasMore,
}: // deleteFeedDependenncy,
// setdeleteFeedDependenncy,
any) {
  const [storeId, SetStoreId] = useState('');
  const [showPostComment, setShowPostComment] = useState(false);
  const [showShareModel, setShowShareModel] = useState(false);
  const [showSubscriberModel, setShowSubscriberModel] = useState(false);
  const [showUnlockModel, setShowUnlockModel] = useState(false);
  const [postId, setPostId] = useState();
  const [userId, setUserId] = useState();
  const [isFavourite, setIsFavourite] = useState(false);
  const [donate, setDonate] = useState(false);
  const [donateData, setDonateData] = useState<any>(null);
  const [donatePostId, setDonatePostId] = useState<any>(null);
  const [Like, setLike] = useState<any>(null);
  const [unlockPost, setUnlockPost] = useState<any>(null);
  const currentUser = getToken('userId');
  const userFeedListData: any = useSelector(
    (state: Store) => state.userDataReducer.userTrandingFeedList
  );
  const loading: any = useSelector(
    (state: Store) => state.userDataReducer.loading
  );
  const [shareLinkInfo, setShareLinkInfo] = useState<{
    web_url: string;
    title: string;
    text: string;
  }>({ web_url: '', title: '', text: '' });
  const { t } = useTranslation();
  // case USER_FEEDS.FEED_LIKE:
  // const PostLike =
  // return {
  //     ...state,
  //     isLiked:true,
  //     loading: false
  //   };
  // const handleMouseEnter = (ids: any) => {
  //   SetStoreId(ids);
  // };

  // const handleMouseLeave = () => {
  //   SetStoreId("");
  // };
  const handleDonate = (data: any) => {
    setDonate(!donate);
    setDonateData(data?.user);
    setDonatePostId(data?.id);
  };
  const handleImageError = (event: any) => {
    event.target.src = DefaultImage;
  };
  return (
    <>
      <Box className="box-container explore-grid">
        <InfiniteScroll
          dataLength={feedList?.length ? feedList?.length : 0}
          next={() => {
            setPage(page + 1);
          }}
          hasMore={hasMore}
          loader={loading && <h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>{t('home.yah! you have seen it all')}</b>
            </p>
          }
          style={{ overflow: 'hidden !imprtant' }}
        >
          <Masonry columns={3} spacing={2} className="post-lists ps-0 mb-0">
            {/* <ul className="post-lists ps-0 mb-0"> */}
            {userFeedListData &&
              userFeedListData?.length > 0 &&
              userFeedListData?.map((feedData: any, index: number) => {
                if (!feedData.post_type_link?.includes('https://')) {
                  if (feedData.post_type_link)
                    feedData.post_type_link =
                      S3_BASE_URL + feedData.post_type_link;
                }
                return (
                  <Item>
                    <Card>
                      <CardMedia>
                        {feedData.post_type === POST_TYPE.POST_TYPE_IMAGE && (
                          <>
                            {feedData?.is_lock == true &&
                            feedData?.user_id != currentUser ? (
                              feedData.payment_type !==
                                PAYMENT_TYPE.PAYMENT_TYPE_FREE &&
                              feedData?.post_type_link && (
                                <div>
                                  <div className="post-lock">
                                    <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                      <img
                                        alt="lock"
                                        src={LockIcon}
                                        className="img-fluid"
                                      />
                                      {feedData.payment_type ===
                                      PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() =>
                                            setShowSubscriberModel(true)
                                          }
                                        >
                                          {t('home.subscribe to unlock')}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() => {
                                            setUnlockPost(feedData);
                                            setShowUnlockModel(true);
                                          }}
                                        >
                                          {t('home.unlock')}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <img
                                    onClick={() => {
                                      setShowPostComment(true);
                                      setPostId(feedData.id);
                                    }}
                                    src={
                                      feedData?.post_type_link
                                        ? feedData?.post_type_link
                                        : DefaultImage
                                    }
                                    // onMouseEnter={() =>
                                    //   handleMouseEnter(feedData.id)
                                    // }
                                    className="grid-img"
                                    alt="feed"
                                    onError={handleImageError}
                                  />
                                </div>
                              )
                            ) : (
                              <div className="post-image">
                                {feedData?.post_type_link &&
                                feedData?.post_type_link.includes(',') ? (
                                  <img
                                    onClick={() => {
                                      setShowPostComment(true);
                                      setPostId(feedData.id);
                                    }}
                                    src={feedData.post_type_link.split(',')[0]}
                                    className="grid-img"
                                    alt="bgimg"
                                    onError={handleImageError}
                                    // onMouseEnter={() =>
                                    //   handleMouseEnter(feedData.id)
                                    // }
                                  />
                                ) : (
                                  <img
                                    onClick={() => {
                                      setShowPostComment(true);
                                      setPostId(feedData.id);
                                    }}
                                    src={
                                      feedData.post_type_link
                                        ? feedData?.post_type_link
                                        : DefaultImage
                                    }
                                    className="grid-img"
                                    alt="bgimg"
                                    onError={handleImageError}
                                    // onMouseEnter={() =>
                                    //   handleMouseEnter(feedData.id)
                                    // }
                                  />
                                )}
                              </div>
                              // <div className="post-image">
                              //   <img
                              //     onClick={() => {
                              //       setShowPostComment(true);
                              //       setPostId(feedData.id);
                              //     }}
                              //     src={
                              //       feedData?.post_type_link
                              //         ? S3_BASE_URL + feedData?.post_type_link
                              //         : defaultImg
                              //     }
                              //     className="grid-img"
                              //     alt="bgimg"
                              //     onMouseEnter={() =>
                              //       handleMouseEnter(feedData.id)
                              //     }
                              //   />
                              // </div>
                            )}
                          </>
                        )}

                        {feedData.post_type === POST_TYPE.POST_TYPE_VIDEO && (
                          <>
                            {feedData?.is_lock == true &&
                            feedData?.user_id != currentUser ? (
                              feedData.payment_type !==
                                PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                                <div className="post-image">
                                  <div className="post-lock">
                                    <div className="right-button">
                                      <button className="btn btn-teaser">
                                        <img
                                          src={PlayIcon}
                                          className="img-fluid me-1"
                                          alt="icon"
                                        />
                                        Teaser
                                      </button>
                                    </div>
                                    <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                      <div className="lock_icon">
                                        <img
                                          src={LockIcon}
                                          className="img-fluid"
                                          alt="lock"
                                        />
                                        <button
                                          className="btn btn-lock"
                                          onClick={() => {
                                            setUnlockPost(feedData);
                                            setShowUnlockModel(true);
                                          }}
                                        >
                                          {t('home.unlock')}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <img
                                    onClick={() => {
                                      setShowPostComment(true);
                                      setPostId(feedData.id);
                                    }}
                                    // onMouseEnter={() =>
                                    //   handleMouseEnter(feedData.id)
                                    // }
                                    src={S3_BASE_URL + feedData?.thumb_image}
                                    className="grid-img"
                                    alt="bgimg"
                                    onError={handleImageError}
                                  />
                                </div>
                              )
                            ) : (
                              <div
                                className="post-image"
                                style={{ position: 'relative' }}
                              >
                                <div
                                  className="post-lock"
                                  style={{ backdropFilter: 'none' }}
                                >
                                  <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100"></div>
                                </div>
                                {feedData?.post_type_link ? (
                                  <>
                                    <img
                                      src={S3_BASE_URL + feedData?.thumb_image}
                                      className="img-fluid"
                                      alt="bgimg"
                                    />
                                    <button
                                      className="btn btn-teaser11"
                                      onClick={() => {
                                        setShowPostComment(true);
                                        setPostId(feedData.id);
                                      }}
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%,-50%)',
                                        background: ' #fff',
                                        height: '44px',
                                        width: '44px',
                                        borderRadius: '50%',
                                        // position: "absolute",
                                        // top: "50%",
                                        // left: "50%",
                                        // transform: "translate(-50%, -50%)",
                                        // msTransform: "translate(-50%, -50%)",
                                        // backgroundColor: "#555",
                                        // color: "blue",
                                        // fontSize: "16px",
                                        // transform: "translate(-50%,-50%)",
                                        // background: " #fff",
                                        // height: "44px",
                                        // width: "44px",
                                        // borderRadius: "50%",
                                        // padding: "12px 24px",
                                        // border: "none",
                                        // cursor: "pointer",
                                        // borderRadius: "5px",
                                        // textAlign: "center",
                                      }}
                                    >
                                      <img
                                        src={playIconBlue}
                                        className="img-fluid ml-1"
                                        style={{
                                          width: '12px',
                                          height: '14px',
                                        }}
                                        alt="icon"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  // <video
                                  //   onClick={() => {
                                  //     setShowPostComment(true);
                                  //     setPostId(feedData.id);
                                  //   }}
                                  //   // onMouseEnter={() =>
                                  //   //   handleMouseEnter(feedData.id)
                                  //   // }
                                  //   src={
                                  //     S3_BASE_URL + feedData?.post_type_link
                                  //   }
                                  //   poster={
                                  //     S3_BASE_URL + feedData?.thumb_image
                                  //   }
                                  //   width="100%"
                                  //   height="100%"
                                  //   autoPlay={false}
                                  //   controls={true}
                                  //   className="grid-img"
                                  // />
                                  <img
                                    onClick={() => {
                                      setShowPostComment(true);
                                      setPostId(feedData.id);
                                    }}
                                    // onMouseEnter={() =>
                                    //   handleMouseEnter(feedData.id)
                                    // }
                                    src={defaultImg}
                                    className="grid-img"
                                    alt="bgimg"
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                        {feedData.post_type === POST_TYPE.POST_TYPE_AUDIO && (
                          <>
                            {feedData?.is_lock == true &&
                            feedData?.user_id != currentUser ? (
                              feedData.payment_type !==
                                PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                                <div className="post-image">
                                  <div className="post-lock">
                                    <div className="right-button">
                                      <button className="btn btn-teaser">
                                        <img
                                          src={PlayIcon}
                                          className="img-fluid me-1"
                                          alt="icon"
                                        />
                                        Teaser
                                      </button>
                                    </div>
                                    <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                      <img
                                        src={LockIcon}
                                        className="img-fluid"
                                        alt="lock"
                                      />
                                      <button
                                        className="btn btn-lock"
                                        onClick={() => {
                                          setUnlockPost(feedData);
                                          setShowUnlockModel(true);
                                        }}
                                      >
                                        {t('home.unlock')}
                                      </button>
                                    </div>
                                  </div>
                                  <img
                                    onClick={() => {
                                      setShowPostComment(true);
                                      setPostId(feedData.id);
                                    }}
                                    src={S3_BASE_URL + feedData?.thumb_image}
                                    className="grid-img"
                                    alt="bgimg"
                                  />
                                </div>
                              )
                            ) : (
                              <div
                                className="post-image"
                                // style={{ background: "#f5f5f5" }}

                                onClick={() => {
                                  setShowPostComment(true);
                                  setPostId(feedData.id);
                                }}
                              >
                                <div
                                  className="post-lock"
                                  style={{ backdropFilter: 'none' }}
                                >
                                  <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100"></div>
                                </div>
                                {feedData?.post_type_link ? (
                                  <div
                                    className="d-flex align-items-center justify-content-center h-100 post_audio_contant-feedpost m-4"
                                    // style={{
                                    //   backgroundImage: `url(${S3_BASE_URL}${feedData?.thumb_image})`,
                                    // }}
                                  >
                                    <button
                                      className="post_audio_btn"
                                      onClick={() => console.log('')}
                                    >
                                      <img
                                        src={`${S3_BASE_URL}${feedData?.thumb_image}`}
                                        alt=""
                                        style={{
                                          objectFit: 'cover',
                                          height: '100%',
                                          width: '100%',
                                        }}
                                        onError={handleImageError}
                                      />
                                    </button>
                                  </div>
                                ) : (
                                  // <audio
                                  //   src={S3_BASE_URL + feedData?.post_type_link}
                                  //   autoPlay={false}
                                  //   controls={true}
                                  // />
                                  <img
                                    src={defaultImg}
                                    className="img-fluid"
                                    alt="bgimg"
                                  />
                                )}
                              </div>
                              // <div className="post-image">
                              //   <div
                              //     className="post-lock"
                              //     style={{ backdropFilter: "none" }}
                              //   >
                              //     <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100"></div>
                              //   </div>

                              //   <img
                              //     onClick={() => {
                              //       setShowPostComment(true);
                              //       setPostId(feedData.id);
                              //     }}
                              //     // onMouseEnter={() =>
                              //     //   handleMouseEnter(feedData.id)
                              //     // }
                              //     src={
                              //       feedData?.thumb_image
                              //         ? S3_BASE_URL + feedData?.thumb_image
                              //         : defaultImg
                              //     }
                              //     className="grid-img"
                              //     alt="bgimg"
                              //   />

                              // </div>
                            )}
                          </>
                        )}

                        {feedData.post_type === POST_TYPE.POST_TYPE_TEXT && (
                          <>
                            {feedData?.is_lock == true &&
                            feedData?.user_id != currentUser ? (
                              feedData.payment_type !==
                                PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                                <div className="post-image">
                                  <div className="post-lock">
                                    <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                      <img
                                        src={LockIcon}
                                        alt="lock"
                                        className="img-fluid"
                                      />
                                      {feedData.payment_type ===
                                      PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() =>
                                            setShowSubscriberModel(true)
                                          }
                                        >
                                          {t('home.subscribe to unlock')}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() => {
                                            setUnlockPost(feedData);
                                            setShowUnlockModel(true);
                                          }}
                                        >
                                          {t('home.unlock')}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <img
                                    onClick={() => {
                                      setShowPostComment(true);
                                      setPostId(feedData.id);
                                    }}
                                    // onMouseEnter={() =>
                                    //   handleMouseEnter(feedData.id)
                                    // }
                                    src={
                                      feedData?.thumb_image
                                        ? S3_BASE_URL + feedData?.thumb_image
                                        : defaultImg
                                    }
                                    className="grid-img"
                                    alt="bgimg"
                                  />
                                </div>
                              )
                            ) : (
                              <div>
                                <img
                                  onClick={() => {
                                    setShowPostComment(true);
                                    setPostId(feedData.id);
                                  }}
                                  // onMouseEnter={() =>
                                  //   handleMouseEnter(feedData.id)
                                  // }
                                  src={
                                    feedData?.thumb_image
                                      ? S3_BASE_URL + feedData?.thumb_image
                                      : defaultImg
                                  }
                                  className="grid-img"
                                  alt="bgimg"
                                />
                              </div>
                            )}
                          </>
                        )}
                      </CardMedia>
                      {/* <CardActions>
                          {storeId === feedData.id && (
                            <div className="post-comments">
                              <div className="post-likes d-flex align-items-start justify-content-between">
                                <div className="like-button d-flex">
                                  <div className="d-flex align-items-center flex-column me-4">
                                    <button
                                      className="btn btn-like"
                                      onClick={() => {
                                        dispatch(addLike(feedData.id));
                                      }}
                                    >
                                      <img
                                        src={
                                          feedData.is_like
                                            ? LikeIcon
                                            : LikeBlackIcon
                                        }
                                        className="img-fluid"
                                        alt="bgimg"
                                      />
                                    </button>
                                    <small>{feedData?.post_like_counter}</small>
                                  </div>
                                  <div className="d-flex align-items-center flex-column">
                                    <button
                                      className="btn btn-like"
                                      onClick={() => {
                                        setShowPostComment(true);
                                        setPostId(feedData.id);
                                      }}
                                    >
                                      <img
                                        src={CommentIcon}
                                        className="img-fluid"
                                        alt="bgimg"
                                      />
                                    </button>
                                    <small>
                                      {feedData?.post_comment_counter}
                                    </small>
                                  </div>
                                </div>
                                <div className="donate-button">
                                  <button
                                    className="btn btn-donate"
                                    onClick={() => handleDonate(feedData)}
                                  >
                                    $ Donate
                                  </button>
                                </div>
                              </div>
                              <div className="comment-description"> */}
                      {/* <p>
                            {feedData.post_type !== POST_TYPE.POST_TYPE_TEXT &&
                              feedData?.description}
                              
                            </p> */}
                      {/* {feedData?.hash_tags && feedData?.hash_tags?.length > 0 &&
                            feedData?.hash_tags?.map((id: number, data: any) => {
                              <span className="text-blue">#{data}</span>;
                            })} */}
                      {/* <p className="mb-0 post-time">
                            {moment(feedData.created_at).fromNow()}
                          </p> */}
                      {/* </div>
                            </div>
                          )}
                        </CardActions> */}
                    </Card>
                  </Item>
                );
              })}
            {/* </ul> */}
          </Masonry>
        </InfiniteScroll>
      </Box>
      <CommentModel
        showPostComment={showPostComment}
        setShowPostComment={setShowPostComment}
        postId={postId}
      ></CommentModel>
      <ShareModel
        showShareModel={showShareModel}
        setShowPostComment={setShowShareModel}
        postId={postId}
        page={page}
        // deleteFeedDependenncy={deleteFeedDependenncy}
        // setdeleteFeedDependenncy={setdeleteFeedDependenncy}
        userId={userId}
        isFavourite={isFavourite}
        shareLinkInfo={shareLinkInfo}
      />
      <SubsctiptionModel
        showPostComment={showSubscriberModel}
        setShowPostComment={setShowSubscriberModel}
      ></SubsctiptionModel>
      <UnlocakContentModel
        showPostComment={showUnlockModel}
        setShowPostComment={setShowUnlockModel}
        post={unlockPost}
      ></UnlocakContentModel>
      <DonateModel
        showDonate={donate}
        setDonate={setDonate}
        data={donateData}
        postId={donatePostId}
      />
    </>
  );
}

// position: "absolute",
// top: "50%",
// left: "50%",
// transform: "translate(-50%,-50%)",
// background: " #fff",
// height: "44px",
// width: "44px",
// borderRadius: "50%",
