/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, login } from "../redux/action";
import { useFormik } from "formik";
import { changePasswordSchema, LoginSchema } from "../validationScrema/user";
import { Store } from "../redux/Actions";
import { AppDispatch } from "../redux";
import PhoneScreen from "../assets/images/imgs/phone-screen.svg";
import Logo from "../assets/images/imgs/logo.svg";
import EyeIcon from "../assets/images/icons/ico_eye.svg";
import CrossEyeIcon from "../assets/images/icons/dolor-icon.svg";
import PlayStore from "../assets/images/imgs/google-play.svg";
import AppStore from "../assets/images/imgs/app-store.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

export default function ChangePassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isChangePasswordSuccess: boolean = useSelector(
    (state: Store) => state.userDataReducer.isChangePasswordSuccess
  );
  const loading: boolean = useSelector(
    (state: Store) => state.userDataReducer.loading
  );

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      dispatch(changePassword(values));
    },
  });
  const handleOldPasswordVisiblity = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleNewPasswordVisiblity = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (isChangePasswordSuccess) {
      formik.resetForm();
    }
  }, [isChangePasswordSuccess]);
  const { errors, touched } = formik;
  return (
    <>
      {loading && <Loader />}
      <h2 className="accordion-header d-lg-none" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#changepsw"
          aria-expanded="false"
          aria-controls="changepsw"
        >
          {t("changepassword.changepassword")}
        </button>
      </h2>
      <div
        id="changepsw"
        className="accordion-collapse collapse d-lg-block"
        aria-labelledby="headingThree"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            <h2 className="setting-title">
              {t("changepassword.changepassword")}
            </h2>
            <div className="change-psw-form">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group current-psw">
                  <label className="d-block">
                    {t("changepassword.currentpassword")}
                  </label>
                  <div className="input-group">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      className="form-control border-end-0 pe-0"
                      placeholder={
                        t("changepassword.currentpassword") ||
                        "current Password"
                      }
                      aria-label="Password"
                      aria-describedby="psw"
                      name="old_password"
                      value={formik.values.old_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="input-group-text" id="psw">
                      {showOldPassword ? (
                        <i
                          className="las la-eye-slash"
                          onClick={handleOldPasswordVisiblity}
                        ></i>
                      ) : (
                        <i
                          className="las la-eye"
                          onClick={handleOldPasswordVisiblity}
                        ></i>
                      )}
                    </span>
                  </div>
                  {errors.old_password && touched.old_password && (
                    <div className="text-danger mt-2">
                      {errors.old_password}
                    </div>
                  )}
                  {/* <a href="forgot-password.html" className="forgot-link d-flex align-items-center justify-content-end"> */}
                  <Link
                    to={"/forget-password"}
                    className="forgot-link d-flex align-items-center justify-content-end"
                  >
                    {t("changepassword.forgotpassword")}
                  </Link>
                  {/* </a> */}
                </div>
                <div className="form-group">
                  <label className="d-block">
                    {t("changepassword.new password")}
                  </label>
                  <div className="input-group">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="form-control border-end-0 pe-0"
                      placeholder={
                        t("changepassword.new password") || "New Password"
                      }
                      aria-label="Password"
                      aria-describedby="psw"
                      name="new_password"
                      value={formik.values.new_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="input-group-text" id="psw">
                      {showNewPassword ? (
                        <i
                          className="las la-eye-slash"
                          onClick={handleNewPasswordVisiblity}
                        ></i>
                      ) : (
                        <i
                          className="las la-eye"
                          onClick={handleNewPasswordVisiblity}
                        ></i>
                      )}
                    </span>
                  </div>
                  {errors.new_password && touched.new_password && (
                    <div className="text-danger mt-2">
                      {errors.new_password}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="d-block">
                    {t("changepassword.confirmpassword")}
                  </label>
                  <div className="input-group">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control border-end-0 pe-0"
                      placeholder={
                        t("changepassword.confirmpassword") ||
                        "Confirm Password"
                      }
                      aria-label="Password"
                      aria-describedby="confirm-psw"
                      name="confirm_new_password"
                      value={formik.values.confirm_new_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="input-group-text" id="confirm-psw">
                      {showConfirmPassword ? (
                        <i
                          className="las la-eye-slash"
                          onClick={handleConfirmPasswordVisiblity}
                        ></i>
                      ) : (
                        <i
                          className="las la-eye"
                          onClick={handleConfirmPasswordVisiblity}
                        ></i>
                      )}
                    </span>
                  </div>
                  {errors.confirm_new_password &&
                    touched.confirm_new_password && (
                      <div className="text-danger mt-2">
                        {errors.confirm_new_password}
                      </div>
                    )}
                </div>
                <div className="btn-section">
                  <Button type="submit" className="btn btn-login">
                    {t("changepassword.save")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
