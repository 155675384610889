import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User1 from "../assets/images/imgs/follow1.svg";
import { AppDispatch } from "../redux";
import { editBanking, userDetail } from "../redux/action";
import { Store } from "../redux/Actions";
import { useFormik } from "formik";
import { EditBankingSchema } from "../validationScrema/user";
import { profile } from "console";
import { S3_BASE_URL } from "../service/aws/config";
import { getToken } from "../utils/utility";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
export default function Banking(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const userDetailData: any = useSelector((state: Store) => state.userDataReducer.userDetail);
  const loading: any = useSelector((state: Store) => state.userDataReducer.loading);
  //   const { countryList, stateList }: any = useSelector(
  //     (state: Store) => state.userDataReducer
  //   );
  const [imgData, setImgData] = useState();
  useEffect(() => {
    // if(!userDetailData){
    const userId = getToken("userId");
    dispatch(userDetail(Number(userId)));
    formik.resetForm()
    // }
  }, [props.currentPage]);
  const dispatch = useDispatch<AppDispatch>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bank_holder_name: userDetailData?.bank_holder_name || "",
      bank_name: userDetailData?.bank_name || "",
      bank_account_number: userDetailData?.bank_account_number || "",
      bank_country: userDetailData?.bank_country || "",
      bank_state: userDetailData?.bank_state || "",
      bank_city: userDetailData?.bank_city || "",
      bank_routing: userDetailData?.bank_routing || "",
      bank_address: userDetailData?.bank_address || "",
      bank_swift_code: userDetailData?.bank_swift_code || "",
      // acceptTerms: false,
    },
    validationSchema: EditBankingSchema,
    onSubmit: async (values) => {
      values = {
        ...values,
      };

      dispatch(editBanking(values));
    },
  });
  // useEffect(() => {
  //   formik.resetForm()
  // }, [props.currentPage]);
  const { errors, touched } = formik;
  return (
    <>
      {loading && <Loader />}
      <h2 className='accordion-header  d-lg-none' id='headingOne'>
        <button className='accordion-button ' type='button' data-bs-toggle='collapse' data-bs-target='#editProfile' aria-expanded='true' aria-controls='editProfile'>
          {t("editprofile.edit profile")}
        </button>
      </h2>
      <div id='editProfile' className='accordion-collapse collapse show  d-lg-block banking-page' aria-labelledby='headingOne' data-bs-parent='#myTabContent'>
        <div className='accordion-body'>
          <div className='profile-content box-container'>
            <h2 className='setting-title'> {t("banking.banking")}</h2>

            <div className='profile-box text-center'>
              <form onSubmit={formik.handleSubmit}>
                <div className='input-main row'>
                  <div className='col-md-6 padding input-part'>
                    <input
                      type='text'
                      placeholder={t("banking.bank holder name") || "Bank Holder Name"}
                      className='form-control'
                      name='bank_holder_name'
                      value={formik.values.bank_holder_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {errors.bank_holder_name && touched.bank_holder_name && (
                      <p className='text-danger'>
                        <>{errors?.bank_holder_name}</>
                      </p>
                    )}
                  </div>
                  <div className='col-md-6 input-part'>
                    <input
                      type='text'
                      placeholder={t("banking.bank name") || "Bank Name"}
                      className='form-control'
                      name='bank_name'
                      value={formik.values.bank_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.bank_name && touched.bank_name && (
                      <p className='text-danger'>
                        <>{errors.bank_name}</>
                      </p>
                    )}
                  </div>
                  <div className='col-md-6 padding input-part'>
                    <input
                      type='text'
                      placeholder={t("banking.account number") || "Account Number"}
                      className='form-control'
                      name='bank_account_number'
                      value={formik.values.bank_account_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.bank_account_number && touched.bank_account_number && (
                      <p className='text-danger'>
                        <>{errors.bank_account_number}</>
                      </p>
                    )}
                  </div>

                  <div className='col-md-6 input-part'>
                    <input
                      type='text'
                      placeholder={t("banking.country") || "Country"}
                      className='form-control'
                      name='bank_country'
                      value={formik.values.bank_country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.bank_country && touched.bank_country && (
                      <p className='text-danger'>
                        <>{errors.bank_country}</>
                      </p>
                    )}
                  </div>
                  <div className='col-md-12 input-part'>
                    <input
                      type='text'
                      placeholder={t("banking.state") || "State"}
                      className='form-control'
                      name='bank_state'
                      value={formik.values.bank_state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.bank_state && touched.bank_state && (
                      <p className='text-danger'>
                        <>{errors.bank_state}</>
                      </p>
                    )}
                  </div>
                  <div className='col-md-6 padding  input-part'>
                    <input
                      type='text'
                      className='form-control'
                      name='bank_city'
                      placeholder={t("banking.city") || "City"}
                      value={formik.values.bank_city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.bank_city && touched.bank_city && (
                      <p className='text-danger'>
                        <>{errors.bank_city}</>
                      </p>
                    )}
                  </div>
                  <div className='col-md-6 input-part'>
                    <input
                      type='text'
                      className='form-control'
                      name='bank_routing'
                      placeholder={t("banking.bank routing") || "Bank Routing"}
                      value={formik.values.bank_routing}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.bank_routing && touched.bank_routing && (
                      <p className='text-danger'>
                        <>{errors.bank_routing}</>
                      </p>
                    )}
                  </div>
                  <div className='col-md-12 input-part'>
                    <textarea
                      placeholder={t("editprofile.address") || "Address"}
                      className='addresstext form-control'
                      rows={3}
                      name='bank_address'
                      value={formik.values.bank_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                    {errors.bank_address && touched.bank_address && (
                      <p className='text-danger'>
                        <>{errors.bank_address}</>
                      </p>
                    )}
                  </div>
                  <div className='col-md-6 padding  input-part'>
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      name="state"
                      placeholder={t("editprofile.select") || 'Select'}
                      value={formik.values.state}
                    //   onChange={formik.handleChange}
                    onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                    >
                      <option>{t("editprofile.select") || 'Select'}</option>
                      {stateList &&
                        stateList.length > 0 &&
                        stateList.map((data: any) => {
                          return <option value={data?.id}>{data?.name}</option>;
                        })}
                    </select> */}
                    <input
                      type='text'
                      placeholder={t("banking.bank shift code") || "Bank swift code"}
                      className='form-control'
                      name='bank_swift_code'
                      value={formik.values.bank_swift_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.bank_swift_code && touched.bank_swift_code && (
                      <p className='text-danger'>
                        <>{errors.bank_swift_code}</>
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <button className='btn save_btn' type='submit'>
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
