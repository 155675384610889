import {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment,
  PureComponent,
} from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/Actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux';
import { S3_BASE_URL } from '../../service/aws/config';
import Cropper from 'react-easy-crop';
import getCroppedImg from './Crop';
import { Button, Carousel, Image, Modal, ModalBody } from 'react-bootstrap';
import Select from 'react-select';
import CustomOption from './CustomeOption';
import {
  createTextPost,
  getSearchedUserList,
} from '../../redux/action/postActions';
import SearchableSelect from '../SearchableSelect';
import { apiCall, METHOD } from '../../service/baseApiCall';
import {
  CREATE_POST_URL,
  GET_PEOPLE_LIST_URL,
  SEARCH_TAGS,
} from '../../redux/ApiEndPoints';
import { uploadFileAmazonS3 } from '../../service/index.';
import { toast } from 'react-toastify';
import { addPostSuccess } from '../../redux/action';
import { Spinner } from 'react-bootstrap';
import { getRandomColor } from '../../utils/createImage';
import { createImageFromInitials } from '../../utils/createImage';
import { Point, Area } from 'react-easy-crop/types';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {
  Crop,
  centerCrop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import Slider from 'react-slick';
import { canvasPreview } from './CanvasPreview';
import { useDebounceEffect } from './UseDebounce';
import { imgPreview } from './ImagePreview';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../utils/utility';
import { MEDIA_SIZE } from '../../constants/App-constant';

export default function ImagePost({
  showPostComment,
  setShowPostComment,
}: any) {
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  const { t } = useTranslation();
  const postOptions = [
    { label: t('home.free to all'), value: 0 },
    { label: t('home.for subscribers'), value: 1 },
    { label: t('home.paid content'), value: 2 },
  ];
  const local = localStorage.getItem('local');
  // const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 15,
    y: 15,
    width: 30,
    height: 30,
  });
  const [result, setResult] = useState<any>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedCanvasArea, setCroppedCanvasArea] = useState<any>(null);
  // const [src,setSrc]=useState<any>(null);
  const [cropImagedArea, setCropImageArea] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>();
  const [croppedImageFile, setCroppedImageFile] = useState<any>(null);
  const [image, setImage] = useState<any>([]);
  const [imageAllData, setImageAllData] = useState<any>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [imagePostErr, setImagePostErr] = useState<boolean>(false);
  const [amountError, setAmountError] = useState<boolean>(false);
  const [textlimitError, setTextlimitError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageErrAmount, setImageErrAmount] = useState<boolean>(false);
  const [file, setSelectedFile] = useState<any>(null);
  const [selectList, setSelectList] = useState<any>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [zoom, setZoom] = useState(1);
  const [imageErrorModal, setImageErrorModal] = useState(false);
  const [isCropped, setIsCropped] = useState<boolean>(false);
  const [isCroppedId, setIsCroppedId] = useState<any>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
    height: 96,
    unit: 'px',
    width: 144,
    x: 72,
    y: 48,
  });
  const [cropImagePixals, setCropImagePixals] = useState<any>({
    height: null,
    width: null,
  });
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  const blobUrlRef = useRef('');
  const [imageSizeError, setImageSizeError] = useState<boolean>(false);
  const [isShown, setIsShown] = useState(false);

  const [formData, setFormData] = useState<{
    caption: string;
    contentType: string;
    people: string[];
    hashTag: string[];
    amount: string;
    height: any;
    width: any;
    aspect_ratio: any;
  }>({
    caption: '',
    contentType: '0',
    people: [],
    hashTag: [],
    amount: '',
    height: null,
    width: null,
    aspect_ratio: null,
  });

  useEffect(() => {
    if (!showPostComment) {
      imgRef.current = null;
    }
  }, [showPostComment]);
  const handlePeopleSearch = async (keyword: any, onSuccess: any) => {
    await apiCall(
      GET_PEOPLE_LIST_URL,
      { search: keyword },
      (res: any) => {
        onSuccess(
          res.data.map((i: any) => ({
            label: `${i?.first_name} ${i?.last_name}`,
            value: i?.id,
          }))
        );
      },
      () => {
        onSuccess([]);
      },
      METHOD.POST
    );
  };
  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        setCropImagePixals({
          height: completedCrop?.height,
          width: completedCrop?.width,
        });
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
          // rotate,
        );
      }
    },
    100,
    [completedCrop, scale, isCropped]
  );

  const handleTagSearch = async (keyword: any, onSuccess: any) => {
    await apiCall(
      SEARCH_TAGS,
      { search: keyword, page: 1 },
      (res: any) => {
        onSuccess(
          res.data.map((i: any) => ({
            label: i?.hash_tag,
            value: i?.hash_tag,
          }))
        );
      },
      () => {
        onSuccess([]);
      },
      METHOD.POST
    );
  };

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 0,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const handleChange = (key: any, value: any) => {
    if (key === 'contentType' && !userDetail.user_verified) {
      setImageErrorModal(true);
      return;
    }
    if (key === 'amount' && value > 0) {
      setAmountError(false);
    }
    if (formData.caption.length > 240) {
      setTextlimitError(true);
    } else {
      setTextlimitError(false);
    }
    setFormData({ ...formData, [key]: value });
  };
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      setAspect(undefined);
      const { width, height } = e.currentTarget;
      setCrop({
        unit: '%', // Can be 'px' or '%'
        x: 15,
        y: 15,
        width: 30,
        height: 30,
      });
    }

    // if (aspect) {
    //   const { width, height } = e.currentTarget
    //   setCrop(centerAspectCrop(width, height, aspect))
    // }
  }

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const handleClickOutside = (event: any) => {
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowPostComment]);

  function onImageChange(event: any) {
    const imgFile = event.target.files[0];
    setImageSizeError(false);
    if (imgFile?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      if (imgFile) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new window.Image(); // Add 'window.' to access the Image constructor.
          image.onload = () => {
            setCropImagePixals({
              height: image.height,
              width: image.width,
            });
          };
          image.src = event.target?.result as string;
        };
        reader.readAsDataURL(imgFile);
      }

      if (formData.amount == '0') {
        setImage([]);
        setImageErrAmount(true);
      } else if (!imgFile?.name.match(/\.(jpg|jpeg|png)$/)) {
        setImagePostErr(true);
        setImageError(false);
        return false;
      } else {
        setImage([...image, ...event.target.files]);
        setImageAllData([...image, ...event.target.files]);
        setImagePostErr(false);
      }
      setSelectedFile(event.target.files[0]);
      // if (event.target.files && event.target.files[0]) {
      //   setImage([...image, ...event.target.files]);

      // }
      //  if(formData.amount == "0"){
      //   setImage([])
      //   setImageErrAmount(true)
      //  }
    } else {
      setImageSizeError(true);
    }
  }
  const ontagChange = (option: any) => {
    setTagList(option);
    const ids = option.map((i: any) => i.value);
    handleChange('people', ids);
  };
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }

    previewCanvasRef?.current?.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob');
      }
      if (blobUrlRef?.current) {
        URL.revokeObjectURL(blobUrlRef?.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      let fileNew: any = null;
      image.map((img: any, idx: any) => {
        const myFile = new File([blob], `${img.name}-cropped`, {
          type: 'image/jpeg',
        });
        fileNew = myFile;
      });
      const newImages: any = image.map((elem: any, index: any) => {
        if (index === isCroppedId) {
          return fileNew;
        }
        return elem;
      });
      setImage(newImages);
      setCroppedImageFile(newImages);
      setIsCropped(false);
      setIsCroppedId(null);
    });
  }

  // if (!croppedImageFile){
  // uploadFileAmazonS3(image)
  // }
  // const showCroppedImage = useCallback(async () => {
  //   try {
  //     let croppedImage: any = await getCroppedImg(image, croppedAreaPixels);
  //     setCroppedImage(croppedImage.blobURL);
  //     let fileNew: any = null;
  //     image.map((img, idx) => {
  //       const myFile = new File([croppedImage.imgFile], img.name, {
  //         type: croppedImage.imgFile.type,
  //       });
  //       fileNew = myFile;
  //     });
  //     setCroppedImageFile(fileNew);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, [croppedAreaPixels, image]);

  const resetCrop = (id: any) => {
    const resetData: any = imageAllData[id];
    const resetNewData: any = image.map((elem: any, index: any) => {
      if (index === id) {
        return resetData;
      }
      return elem;
    });
    setCroppedImage('');
    setImage(resetNewData);
    setIsCropped(false);
    setIsCroppedId(null);
    setCrop({
      unit: '%', // Can be 'px' or '%'
      x: 15,
      y: 15,
      width: 30,
      height: 30,
    });
    setCompletedCrop({
      height: 96,
      unit: 'px',
      width: 144,
      x: 72,
      y: 48,
    });
    setCroppedImageFile(null);
  };

  function onChangeImage() {
    //const imgFile = event.target.files[0];
    setImage([]);
    setImageAllData([]);
    setSelectedFile([]);
    setCroppedImageFile(null);
    // if (event.target.files && event.target.files[0]) {
    //   setImage([...image, ...event.target.files]);

    // }
    //  if(formData.amount == "0"){
    //   setImage([])
    //   setImageErrAmount(true)
    //  }
  }
  // const showCanvasImage = useCallback(async () => {
  //   try {
  //     let completedCrop: any = await imgPreview(image,croppedCanvasArea);
  //     setCompletedCrop(completedCrop.blobURL);
  //     let fileNew: any = null;
  //     image.map((img, idx) => {
  //       const myFile = new File([completedCrop.imgFile], img.name, {
  //         type: completedCrop.imgFile.type,
  //       });
  //       fileNew = myFile;
  //     });
  //     setCroppedCanvasImg(fileNew);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, []);

  const handleCreatePost = async () => {
    if (
      image.length === 0 ||
      (!formData.amount && formData.contentType === '2')
    ) {
      if (image.length === 0) {
        setImageError(true);
        //  setImageErrAmount(true)
      } else if (!Number(formData.amount) && formData.contentType === '2') {
        setAmountError(true);
      }
    } else if (formData.amount == '0') {
      setImageErrAmount(true);
    } else {
      setAmountError(false);
      setImageError(false);
      setLoading(true);
      // setImagePostErr(false);
      let postImagesUpload: any;

      // postImagesUpload = Promise.allSettled(
      //   image.map((img: any, idx: number) =>
      //     uploadFileAmazonS3(img).then(({ Key, Location }) => {
      //       return { Key, Location };
      //     })
      //   )
      // );

      if (croppedImageFile === null) {
        postImagesUpload = Promise.allSettled(
          image.map((img: any, idx: number) =>
            uploadFileAmazonS3(img, 'post/images/').then(
              ({ Key, Location }) => {
                return { Key, Location };
              }
            )
          )
        );
      } else {
        postImagesUpload = Promise.allSettled(
          image.map((img: any, idx: number) =>
            uploadFileAmazonS3(
              croppedImageFile?.find((elem: any, i: any) => idx === i),
              'post/images/'
            ).then(({ Key, Location }) => {
              return { Key, Location };
            })
          )
        );
      }
      postImagesUpload.then((data: any) => {
        const postData = new FormData();

        postData.append('post_type', '0');
        postData.append('description', formData.caption);
        postData.append(
          'images',
          data?.map((i: any) => i?.value?.Key)
        );
        postData.append('payment_type', formData.contentType);
        postData.append(
          'payment_amount',
          formData.contentType !== '2' ? '0' : formData.amount
        );
        postData.append(
          'tags',
          formData.people.map((i: any) => i.value).join(',')
        );
        postData.append(
          'hash_tags',
          formData.hashTag.map((i: any) => i.value).join(',')
        );
        if (cropImagePixals.width) {
          postData.append(
            'width',
            cropImagePixals.width ? cropImagePixals.width : null
          );
        }
        if (cropImagePixals.height) {
          postData.append(
            'height',
            cropImagePixals.height ? cropImagePixals.height : null
          );
        }
        if (cropImagePixals.width && cropImagePixals.height) {
          const ascapt_ration_value: any =
            cropImagePixals.width / cropImagePixals.height;
          postData.append(
            'aspect_ratio',
            ascapt_ration_value ? ascapt_ration_value : null
          );
        }
        // const postData = {
        //   post_type: 0,
        //   description: formData.caption,
        //   images: data?.map((i: any) => i?.value?.Key),
        //   payment_type: formData.contentType,
        //   payment_amount: formData.contentType === "0" ? 0 : formData.amount,
        //   tags: formData.people.map((i: any) => i.value),
        //   hash_tags: formData.hashTag.map((i: any) => i.value),
        //   // width: 680,
        //   // height: 451,
        //   // aspect_ratio: 1.507760532150776,
        // };
        apiCall(
          CREATE_POST_URL,
          postData,
          (data: any) => {
            toast.success('Post created successfully');
            setShowPostComment(false);
            dispatch(addPostSuccess(data.data));
            setLoading(false);
            setImage([]);
            setImageAllData([]);
            setIsCropped(false);
            setIsCroppedId(null);
            setCroppedImage('');
            setCroppedImageFile(null);
            setCrop({
              unit: '%', // Can be 'px' or '%'
              x: 15,
              y: 15,
              width: 30,
              height: 30,
            });
            setImageSizeError(false);
            setCompletedCrop({
              height: 96,
              unit: 'px',
              width: 144,
              x: 72,
              y: 48,
            });
            formData.caption = '';
            formData.amount = '';
          },
          () => {
            toast.error('Post not created');
          },
          METHOD.POST
        );
        setFormData({
          caption: '',
          contentType: '0',
          people: [],
          hashTag: [],
          amount: '',
          height: null,
          width: null,
          aspect_ratio: 11,
        });
        setCropImagePixals({
          height: null,
          width: null,
        });
      });
    }
  };
  const CROP_AREA_ASPECT = 1 / 1;

  // const Output = ({ croppedArea, img }: any) => {
  //   const scale = 100 / croppedArea.width;
  //   const transform = {
  //     x: `${-croppedArea.x * scale}%`,
  //     y: `${-croppedArea.y * scale}%`,
  //     scale,
  //     width: "calc(100% + 0.5px)",
  //     height: "auto",
  //   };

  //   const imageStyle = {
  //     transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
  //     width: transform.width,
  //     height: transform.height,
  //   };

  //   return (
  //     <div
  //       className="output"
  //       style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
  //     >
  //       <img src={img} alt="" style={imageStyle} />
  //     </div>
  //   );
  // };
  const handleClick = (e: any) => {
    setIsShown(true);
  };
  return (
    <Modal
      show={showPostComment}
      onHide={() => {
        setShowPostComment(false);
        setCroppedImage('');
        setCroppedImageFile(null);
        setImage([]);
        setImageAllData([]);
        setIsCropped(false);
        setIsCroppedId(null);
        setImageSizeError(false);
        setImagePostErr(false);
        setImageError(false);
        setImagePostErr(false);
        setTextlimitError(false);
        setCompletedCrop({
          height: 96,
          unit: 'px',
          width: 144,
          x: 72,
          y: 48,
        });
        setCrop({
          unit: '%', // Can be 'px' or '%'
          x: 15,
          y: 15,
          width: 30,
          height: 30,
        });
        setFormData({
          caption: '',
          contentType: '0',
          people: [],
          hashTag: [],
          amount: '',
          height: null,
          width: null,
          aspect_ratio: 1,
        });
        setCropImagePixals({
          height: null,
          width: null,
        });
      }}
      className="upload-image-popup"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close d-md-none"
          data-bs-dismiss="modal"
        ></button>

        <div className="main-section m-0">
          <div className="left-section">
            <div className="file-label file-carousel">
              {image.length > 0 ? (
                <div
                  className="justify-content-center carousel-main-div"
                  style={{
                    height: 'max-content',
                    width: 'max-content',
                    gap: '30px',
                    flexDirection: 'column',
                  }}
                >
                  {/* {imagePostErr && ( <div className="pt-2 text-danger pl-2">
                      Upload only .jpeg/.jpg/.png files
                    </div>) } */}
                  <>
                    <Carousel
                      interval={null}
                      controls={isCropped || image.length <= 1 ? false : true}
                    >
                      {!isCropped
                        ? image?.map((item: any, index: any) => (
                            <Carousel.Item>
                              {!croppedImage ? (
                                isCropped ? (
                                  <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) =>
                                      setCrop(percentCrop)
                                    }
                                    onComplete={(c) => {
                                      setCompletedCrop(c);
                                    }}
                                    aspect={aspect}
                                  >
                                    <img
                                      ref={imgRef}
                                      alt="Crop"
                                      src={URL.createObjectURL(item)}
                                      style={{
                                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                                      }}
                                      onLoad={onImageLoad}
                                    />
                                  </ReactCrop>
                                ) : (
                                  <img
                                    ref={imgRef}
                                    alt="Crop"
                                    src={URL.createObjectURL(item)}
                                    style={{
                                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                                      borderRadius: '10px',
                                    }}
                                    onLoad={onImageLoad}
                                  />
                                )
                              ) : (
                                <img
                                  className="cropedimg"
                                  src={croppedImage}
                                  alt="croppedimage"
                                />
                              )}
                              <br />
                              <div>
                                <button
                                  onClick={() => {
                                    setIsCropped(true);
                                    setIsCroppedId(index);
                                  }}
                                  className="buttoncrop btn btn-secondary pt-0 mb-0 ml-2 mt-3"
                                >
                                  Crop
                                </button>
                                <button
                                  onClick={() => resetCrop(index)}
                                  className="buttoncrop btn btn-secondary pt-0 mb-0 ml-2 mt-3"
                                >
                                  Reset
                                </button>
                                <button
                                  className="buttoncrop btn btn-secondary pt-0 mb-0 ml-2 mt-3"
                                  onClick={onChangeImage}
                                >
                                  Change Image
                                </button>
                              </div>
                              {/* <button onClick ={handleClick}>crop it</button> 
                       {isShown  ?     
                                            <ReactCrop
                            // minHeight={0}
                            // minWidth={0}
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                          >
                            <img
                              ref={imgRef}
                              alt="Crop"
                              src={URL.createObjectURL(item)}
                              style={{
                                transform: `scale(${scale}) rotate(${rotate}deg)`,
                              }}
                              onLoad={onImageLoad}
                            />
                          </ReactCrop>
                        
: 


<img
className="cropedimg"
src={URL.createObjectURL(item)}
alt="croppedimage"
/>

} */}

                              {/* <div>
          <button onClick={handleToggleAspectClick}>
            Toggle aspect {aspect ? 'off' : 'on'}
          </button>
        </div> */}

                              {imagePostErr && (
                                <div className="text-danger pl-2">
                                  {t('home.upload file')}
                                </div>
                              )}
                              {/* {!!completedCrop && (
                          <>
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop.width,
                                  height: completedCrop.height,
                                }}
                              />
                            </div>
                            {!croppedImage ? (
                              <div>
                                <button onClick={onDownloadCropClick}>
                                  Crop
                                </button>
                                <a
                                  ref={hiddenAnchorRef}
                                  download
                                  style={{
                                    position: "absolute",
                                    top: "-200vh",
                                    visibility: "hidden",
                                  }}
                                >
                                  Hidden download
                                </a>
                              </div>
                            ) : (
                              <div>
                                <button onClick={resetCrop}>Reset</button>
                              </div>
                            )}
                          </>
                        )} */}

                              {/* {!croppedImage ? (
                          <Cropper
                            image={URL.createObjectURL(item)}
                            crop={crop}
                            aspect={CROP_AREA_ASPECT}
                            cropSize={{ width: 300, height: 200 }}
                            zoom={zoom}
                            onZoomChange={setZoom}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                          />
                        ) : (
                          <img
                            className="cropedimg"
                            src={croppedImage}
                            alt="croppedimage"
                          />
                        )} */}

                              {/* <div
                        style={{
                          height: "50vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        >
                        </div> */}
                            </Carousel.Item>
                          ))
                        : image?.map((items: any, index: any) => {
                            if (isCroppedId === index) {
                              return (
                                <>
                                  <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) =>
                                      setCrop(percentCrop)
                                    }
                                    onComplete={(c) => {
                                      setCompletedCrop(c);
                                    }}
                                    aspect={aspect}
                                  >
                                    <img
                                      ref={imgRef}
                                      alt="Crop"
                                      src={URL.createObjectURL(items)}
                                      style={{
                                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                                      }}
                                      onLoad={onImageLoad}
                                    />
                                  </ReactCrop>
                                  {!croppedImage &&
                                  !!completedCrop &&
                                  isCropped ? (
                                    <div>
                                      <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                          border: '1px solid black',
                                          objectFit: 'contain',
                                          width: completedCrop.width,
                                          height: completedCrop.height,
                                          display: 'none',
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {!croppedImage ? (
                                    <div className="mt-3 testing">
                                      {isCropped && (
                                        <button
                                          onClick={onDownloadCropClick}
                                          className="buttoncrop btn btn-secondary pt-0 mb-0 ml-2"
                                          disabled={
                                            completedCrop?.height ||
                                            completedCrop?.width
                                              ? false
                                              : true
                                          }
                                        >
                                          Done
                                        </button>
                                      )}
                                      <a
                                        ref={hiddenAnchorRef}
                                        download
                                        style={{
                                          position: 'absolute',
                                          top: '-200vh',
                                          visibility: 'hidden',
                                        }}
                                      >
                                        Hidden download
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      <button
                                        onClick={resetCrop}
                                        className="buttoncrop btn btn-secondary ml-2 mb-1"
                                        style={{ position: 'absolute' }}
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  )}
                                </>
                              );
                            }
                          })}
                      {/* {!croppedImage ? (
                      <button
                      className="buttoncrop btn btn-secondary"
                      onClick={showCroppedImage}
                      >
                        Crop
                      </button>
                    ) : (
                      <button
                      className="buttoncrop btn btn-secondary"
                      onClick={resetCrop}
                      >
                        Reset Image
                      </button>
                    )} */}
                    </Carousel>
                  </>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center">
                  <div className="upload-image-text">
                    <label>
                      <input
                        type="file"
                        multiple
                        accept="image/jpeg,image/jpg,image/png"
                        onChange={onImageChange}
                        hidden
                      />

                      <span>{t('home.upload image from your computer')}</span>
                    </label>
                  </div>
                  {imageSizeError && (
                    <div className="text-danger text-center mb-4">
                      Please Select less then {MEDIA_SIZE.IMAGE_SIZE / 1024} MB
                      Image file
                    </div>
                  )}
                  {imagePostErr && imageError ? (
                    <div className="text-danger text-center mb-4">
                      {t('home.please upload image')}
                    </div>
                  ) : (
                    <>
                      {imagePostErr ? (
                        <div className="text-danger text-center mb-4">
                          {t('home.upload file')}
                        </div>
                      ) : (
                        <>
                          {imageError && (
                            <div className="text-danger text-center mb-4">
                              {t('home.please upload image')}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {/* {imagePostErr && (
                    <div className="text-danger text-center">
                      Upload only .jpeg/.jpg/.png files
                    </div>
                  )}

                  {imageError && (
                    <div className=" text-danger text-center">
                      Please upload image
                    </div>
                  )} */}
                  {/* {imagePostErr && imageError ? (<div className="text-danger text-center mt-4">Please upload image</div>) : 
                    <>
                    {imagePostErr ? (
                        <div className="text-danger text-center mt-4">
                          {" "}
                          Upload only .jpeg/.jpg/.png files{" "}
                        </div>
                      ) : (
                        <>
                        {imageError &&  <div className="text-danger text-center mt-4">Please upload image</div>}
                        </>
                   
                  )}
                    </>} */}
                  {imageErrAmount && (
                    <div className="pt-2 text-danger pl-2">
                      {t('home.amount 0')}
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* <div
              className="container"
              style={{
                display:
                  image === null || croppedImage !== null ? "block" : "none",
              }}
            ></div> */}
          </div>
          <div className="right-section">
            <div className="caption-control">
              <div className="post-user post-user-control d-flex align-items-start p-0">
                <div className="post-user-logo">
                  <img
                    src={
                      userDetail?.profile_photo
                        ? S3_BASE_URL + userDetail?.profile_photo
                        : createImageFromInitials(
                            500,
                            userDetail?.first_name,
                            getRandomColor()
                          )
                    }
                    className="img-fluid"
                    style={{ height: '45px', width: '45px' }}
                    alt="test"
                  />
                </div>

                {/* <div className="post-user-logo">
                  <img
                    src={S3_BASE_URL + userDetail?.profile_photo}
                    className="img-fluid"
                    alt="test"
                  />
                </div> */}
                <textarea
                  placeholder={`${t('home.caption')}`}
                  className="form-control p-0 border-0"
                  rows={4}
                  value={formData.caption}
                  onChange={(e) =>
                    handleChange('caption', e.target.value as string)
                  }
                ></textarea>
              </div>
            </div>
            {textlimitError && (
              <span
                className="text-danger ml-2"
                style={{ fontSize: 'smaller' }}
              >
                {t('home.please enter less then 240 word')}
              </span>
            )}
            <div
              className={`radio-price-group radio-price-group-circle ${
                local == 'es' ? 'langauge-validation' : ''
              }`}
            >
              {postOptions.map((item) => (
                <Fragment key={`Item${item.value}`}>
                  <input
                    type="radio"
                    className="btn-check"
                    id={`Content-Item${item.value}`}
                    checked={formData.contentType === String(item.value)}
                    value={item.value}
                    onChange={(e) => {
                      handleChange('contentType', e.target.value);
                    }}
                  />
                  <label
                    className="btn btn-secondary"
                    htmlFor={`Content-Item${item.value}`}
                  >
                    {item.label}
                  </label>
                </Fragment>
              ))}
            </div>

            <label className="caption-label">
              <b>{t('tag people')}</b> <i>@</i>{' '}
              {/* <input
                type="text"
                placeholder="username"
                className="form-control"
              /> */}
              <SearchableSelect
                isMulti
                placeholder={`${t('home.select')}`}
                onSearch={handlePeopleSearch}
                onChange={(val) => handleChange('people', val)}
                value={formData.people}
              />
            </label>
            <label className="caption-label">
              <b>Hashtag</b> <i>#</i>{' '}
              <SearchableSelect
                isMulti
                placeholder={`${t('home.select')}`}
                onSearch={handleTagSearch}
                onChange={(val) => handleChange('hashTag', val)}
                value={formData.hashTag}
              />
            </label>
            {/* {formData.contentType==="2"&& (
            <label className="caption-label border-0 mb-md-5">
            <b>Amount</b> <i>$</i>{" "}
            <input
              type="text"
              placeholder="5"
              className="form-control"
              value={formData.amount}
              onChange={(e) => handleChange("amount", e.target.value)}
              disabled={formData.contentType !== "2"}
            />
          </label>

            )} */}
            {formData.contentType == '2' && (
              <>
                <label className="caption-label border-0 mb-md-2">
                  <b>{t('home.amount')}</b> <i>$</i>{' '}
                  <input
                    type="text"
                    placeholder="5"
                    className="form-control"
                    value={formData.amount}
                    onChange={(e) => handleChange('amount', e.target.value)}
                    // disabled={formData.contentType !== "2"}
                  />
                </label>

                {amountError && (
                  <span className="text-danger">{t('home.enter amount')}</span>
                )}
                {imageErrAmount && (
                  <span className="text-danger">{t('home.amount 0')}</span>
                )}
                {/* <span className="text-danger">Please add amount</span> */}
              </>
            )}
            <div className="modal-footer justify-content-center border-0 p-0 mt-auto">
              <Button
                type="button"
                className="btn btn-login w-100 py-4"
                data-bs-dismiss="modal"
                onClick={handleCreatePost}
                disabled={
                  loading || amountError || imageErrAmount || textlimitError
                }
              >
                {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <>{t('home.post')}</>
                )}
              </Button>
              {/* {loading ? (
                   <div className="loader">
                  <Spinner animation="border" variant="primary" />
                  </div>
                ):(
                ""
                )} */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal
        show={imageErrorModal}
        onHide={() => {
          setImageErrorModal(false);
        }}
      >
        <ModalBody>
          <h6>{t('home.varified profile error')}</h6>
          <div style={{ textAlign: 'end', marginLeft: '17px' }}>
            <Button onClick={() => setImageErrorModal(false)}>
              {t('home.close')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Modal>
  );
}
