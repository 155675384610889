import axios from 'axios';
import moment from 'moment';
import CryptoJs from 'crypto-js';

export const getToken = (key: string) => {
  const localData = localStorage.getItem(key);
  if (localData === null) {
    return null;
  }
  const secretKry = 'U$9u3boYh%HiwuMxr925Cv5hodnLRK43DElKd*nhA!!y^K$MjR';
  try {
    let BecryptData = CryptoJs.AES.decrypt(localData, secretKry);
    const DecryptData = BecryptData.toString(CryptoJs.enc.Utf8);
    return JSON.parse(DecryptData);
  } catch (e: any) {
    return null;
  }
};
export const valiadteToken = () => {
  const token = getToken('authToken');

  // const isLoggedIn = getToken("isLoggedIn");
  if (token) {
    return true;
  }
  return false;
};

export const setToken = (key: string, token: any) =>
  Promise.resolve().then(() => {
    const secretKry = 'U$9u3boYh%HiwuMxr925Cv5hodnLRK43DElKd*nhA!!y^K$MjR';
    let EncryptData = CryptoJs.AES.encrypt(
      JSON.stringify(token),
      secretKry
    )?.toString();
    localStorage.setItem(key, EncryptData);
  });

export const getPostData = async () => {
  const response = await axios.get(
    'https://jsonplaceholder.typicode.com/posts'
  );
  const { data } = response;
  return data;
  // });
};

export const formatDate = (dateJson: any, format = 'YYYY-MM-DD') => {
  const date = new Date(dateJson);
  return moment(date).format(format);
};

export const formatDateTime = (dateJson: any) => {
  const date = new Date(dateJson);
  const format1 = 'YYYY-MM-DD HH:mm:s';
  return moment(date).format(format1);
};

export const getTimeofDayInLetter = (dateTime: any) => {
  //----logic----
  const returnValue: Array<string> = [];
  for (const i of dateTime) {
    const value = formatDate(i, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    const day = new Date(value);
    const currentHour: number = day.getHours();

    if (currentHour >= 0 && currentHour <= 2) {
      returnValue.push('A');
    } else if (currentHour > 2 && currentHour <= 6) {
      returnValue.push('E');
    } else if (currentHour > 5 && currentHour <= 9) {
      returnValue.push('N');
    } else {
      returnValue.push('M');
    }
  }

  return returnValue.toString();
};

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const getDeviceType = (type: number) => {
  const device_type = type === 1 ? 'Android' : 'iOS';
  return device_type;
};
export const formatDateMM = (dateJson: any, format = 'MM-DD-YYYY') => {
  const date = new Date(dateJson);
  return moment(date).format(format);
};

// export const formatTime = (dateJson: any, format = "MM:DD:YYYY") => {
//   const date = new Date(dateJson);
//   return moment(date).format(format);
// };

export const formatTime = (dateJson: any) => {
  const date = new Date(dateJson);
  const format1 = 'hh:mma z';
  return moment(date).format(format1);
};

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function arrayToStr(arr: any, field: string) {
  const name: any = [];
  if (arr) {
    arr.map((i: any) => {
      if (Object.prototype.hasOwnProperty.call(i, field)) {
        name.push(i[field]);
      }
    });
    return name.join(',');
  }
  return '';
}

export function getFileName(fileURL: any) {
  const url = fileURL;
  const filename = fileURL.split('/').pop(); // url.substring(url.lastIndexOf('/') + 1);
  return filename;
}

export function get_url_extension(fileName: string) {
  // return url.split(/[#?]/)[0].split('.').pop().trim();
  const extension = fileName.split('.').pop();
  return extension;
}

export function getFileDetail(arrURL: any) {
  const tmp: any = [];
  arrURL.map((i: any) => {
    tmp.push({
      path: i,
      name: getFileName(i),
      extention: get_url_extension(getFileName(i)),
    });
  });
  return tmp;
}
//03:00 Am to 11:59 Am M
//12:00 Pm to 05:59 Pm A
//6 to 9 E
//9 to 3

export const setCurrentPage = (pageNum: number) => {
  localStorage.setItem('currentPage', pageNum.toString());
  return 0;
};

export const getCurrentPage = (pageNum: number) => {
  if (
    localStorage.getItem('currentPage') === undefined ||
    localStorage.getItem('currentPage') === null
  ) {
    return 1;
  } else {
    const page: string = localStorage.getItem('currentPage') || '0';
    return parseInt(page);
  }
};

export const getAPIEndPoint = (endPoint: string, pageNumber: number) => {
  if (pageNumber > 0) {
    return `${endPoint}?page=${pageNumber}&perPage=${process.env.REACT_APP_RECORDS_PER_PAGE}`;
  }
  return endPoint;
};

export const toBase64 = (arr: any) => {
  // arr = new Uint8Array(arr) // if it's an ArrayBuffer
  const a = btoa(
    arr.reduce((data: any, byte: any) => data + String.fromCharCode(byte), '')
  );
  return 'data:image/jpg;base64,' + atob(a);
};
export const getDays = (expiryDate: string, startData: string) => {
  var date1 = new Date(startData);
  var date2 = new Date(expiryDate);

  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
};
