import { GO_LIVE } from '../constants/action-types';
import { profileLiveStreaming } from '../Actions';
import { ActionTypes } from '../action/userActions';

const initialState = {
  isLiveStreaming: false,
  liveStreamJoined: {},
  subscriberToken: { rtcToken: '', rtmToken: '' },
  hostToken: { rtcToken: '', rtmToken: '' },
  title: '',
  description: '',
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state: profileLiveStreaming = initialState, action: ActionTypes) => {
  switch (action.type) {
    case GO_LIVE.START_LIVE_STREAMING:
      return {
        ...state,
        ...action.payload,
        isLiveStreaming: true,
      };
    case GO_LIVE.STOP_LIVE_STREAMING:
      return {
        ...state,
        notificationList: action.payload,
        isLiveStreaming: false,
      };

    case GO_LIVE.JOIN_LIVE_STREAM:
      return {
        ...state,
        liveStreamJoined: action.payload,
      };
    case GO_LIVE.EXIT_LIVE_STREAM:
      return {
        ...state,
        liveStreamJoined: {},
      };

    case GO_LIVE.SET_HOST_TOKEN:
      return {
        ...state,
        hostToken: action.payload,
      };
    case GO_LIVE.SET_SUBSCRIBER_TOKEN:
      return {
        ...state,
        subscriberToken: action.payload,
      };
    default:
      return state;
  }
};
