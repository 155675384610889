import { combineReducers } from "redux";
import userDataReducer from "./userDataReducer";
import settingsDataReducer from "./settingsDataReducer";
import postDataReducer from "./postDataReducer";
import chatDataReducer from "./chatDataReducer";
import notificationReducer from "./notificationReducer";
import walletReducer from "./walletReducer";
import mysubscriberReducer from "./mysubscriberReducer";
import payoutReducer from "./payoutReducer";
import myPlanReducer from "./myPlanReducer";
import goLiveReducer from "./goLiveReducer";
// Combine all reducers as root reducer
export default combineReducers({
  userDataReducer,
  settingsDataReducer,
  postDataReducer,
  chatDataReducer,
  notificationReducer,
  walletReducer,
  mysubscriberReducer,
  payoutReducer,
  myPlanReducer, 
  goLiveReducer
});
