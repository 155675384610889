import { apiCall, chatApiCall, METHOD } from '../../service/baseApiCall';
import {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  OTP_VERIFICATION,
  RESEND_OTP,
  RESET_PASSWORD,
  USER_DETAIL,
  LOGOUT,
  USER_TRANDING,
  USER_TRANDING_FEEDS,
  GET_COUNTRY,
  GET_STATE,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_LOCAL,
  USER_FEEDS,
  USER_DASHBOARD_FEEDS,
  OTP_VERIFICATION_USER,
  FOLLOW_USER,
  UNFOLLOW_USER,
  GET_FOLLOWERS,
  BLOCK_USER,
  UNBLOCK_USER,
  GET_BLOCK,
  EDIT_BANKING,
  STORIES,
  USER_PROFILE,
  MY_SUBSCRIBER,
  SUBSCRIPTION,
  FOLLOWING,
  SEARCH_USER_DETAIL,
  HASH_TAGS,
  POST_VOAT,
} from '../constants/action-types';
import { Store, UserData } from '../Actions';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  CHAT_LOGIN_URL,
  CHANGE_PASSWORD_URL,
  EDIT_PROFILE_URL,
  FOLLOW_URL,
  FORGET_PASSWORD_URL,
  GET_CONTRY_URL,
  GET_STATE_URL,
  LOGIN_URL,
  LOGOUT_URL,
  OTP_USER_VERIFICATION_URL,
  OTP_VERIFICATION_URL,
  REGISTER_URL,
  RESEND_OTP_URL,
  RESET_PASSWORD_URL,
  UNFOLLOW_URL,
  USER_DASHBOARD_FEEDS_URL,
  USER_DETAIL_URL,
  USER_FEEDS_URL,
  USER_TRANDING_FEEDS_URL,
  USER_TRANDING_URL,
  GET_FOLLOWER_LIST_URL,
  BLOCK_LIST_URL,
  UNBLOCK_URL,
  BLOCK_URL,
  EDIT_BANKING_URL,
  GET_STORIES_URL,
  MY_SUBSCRIBERS_URL,
  SUBSCRIPTION_URL,
  USER_FOLLOWING,
  GET_HASH_TAGS,
  GIVE_VOTE_URL,
} from '../ApiEndPoints';
import { toast } from 'react-toastify';
import { getToken, valiadteToken } from '../../utils/utility';
import Subscription from '../../components/Subscriptions';

export type ActionTypes =
  | { type: typeof LOGIN.LOGIN_INITLIZATION; payload: UserData[] }
  | { type: typeof LOGIN.LOGIN_SUCCESS; payload: UserData[] | any }
  | { type: typeof LOGIN.LOGIN_ERORR; payload: UserData[] }
  | { type: typeof REGISTER.REGISTER_INITLIZATION; payload: Object }
  | { type: typeof REGISTER.REGISTER_SUCCESS; payload: Object }
  | { type: typeof REGISTER.REGISTER_ERORR; payload: Object }
  | {
      type: typeof FORGOT_PASSWORD.FORGOT_PASSWORD_INITLIZATION;
      payload: Object;
    }
  | { type: typeof FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS; payload: Object }
  | { type: typeof FORGOT_PASSWORD.FORGOT_PASSWORD_ERORR; payload: Object };

export const translate =
  (local: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHANGE_LOCAL,
      payload: local,
    });
  };

export const login =
  (values: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(loginInit(values));
  };

export const loginInit =
  (values: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: LOGIN.LOGIN_INITLIZATION,
    });
    apiCall(
      LOGIN_URL,
      values,
      (res: any) => {
        if (res.success) {
          dispatch(loginSuccess({ res, values }));
        } else {
          dispatch(loginError('Wrong Credentials'));
        }
      },
      (err: any) => {
        dispatch(loginError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const loginSuccess =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    type credentials = {
      username: string;
      password: string;
    };
    const values = {} as credentials;
    values.username = data?.values?.email;
    values.password = data?.values?.password;
    dispatch({
      type: LOGIN.LOGIN_SUCCESS,
      payload: data.res,
    });
    chatApiCall(
      CHAT_LOGIN_URL,
      values,
      (res: any) => {
        dispatch(chatLoginSuccess(res));
      },
      (err: any) => {
        dispatch(chatLoginError(err));
      },
      METHOD.POST,
      {}
    );

    toast.success(data?.res.message);
  };

export const loginError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: LOGIN.LOGIN_ERORR,
    });
    toast.error(err);
  };
//Hint: Chat login success and error dispatch
export const chatLoginSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: LOGIN.CHAT_LOGIN_SUCCESS,
      payload: res,
    });
  };

export const chatLoginError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: LOGIN.CHAT_LOGIN_ERROR,
    });
    // toast.error(err.message)
  };

export const register =
  (data: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(registerInit(data));
  };
const registerInit =
  (data: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REGISTER.REGISTER_INITLIZATION,
    });
    apiCall(
      REGISTER_URL,
      data,
      (res: any) =>
        res.data
          ? dispatch(registerSuccess(res))
          : dispatch(registerError(res)),
      (err: any) => dispatch(registerError(err)),
      METHOD.POST,
      {}
    );
  };
const registerSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REGISTER.REGISTER_SUCCESS,
      payload: res,
    });
    toast.success(res.message);
  };
const registerError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REGISTER.REGISTER_ERORR,
    });
    toast.error(err.message);
  };
export const otpVerification =
  (data: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(otpVerificationInit(data));
  };
const otpVerificationInit =
  (data: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: OTP_VERIFICATION.OTP_VERIFICATION_INITLIZATION,
    });
    apiCall(
      OTP_VERIFICATION_URL,
      data,
      (res: any) => {
        dispatch(otpVerificationSuccess(res));
      },
      (err: any) => {
        dispatch(otpVerificationError(err));
      },
      METHOD.POST,
      {}
    );
  };
const otpVerificationSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: OTP_VERIFICATION.OTP_VERIFICATION_SUCCESS,
      payload: res,
    });
    if (res.data) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
const otpVerificationError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: OTP_VERIFICATION.OTP_VERIFICATION_ERORR,
    });
    toast.error(err.message);
  };
export const resendOtp =
  (data: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(resendOtpInit(data));
  };
const resendOtpInit =
  (data: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_INITLIZATION,
    });
    apiCall(
      RESEND_OTP_URL,
      data,
      (res: any) => dispatch(resendOtpSuccess(res.data)),
      (err: any) => dispatch(resendOtpError(err)),
      METHOD.POST,
      {}
    );
  };
const resendOtpSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_SUCCESS,
      payload: res.data,
    });

    toast.success(res.message);
  };
const resendOtpError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_ERORR,
    });
    toast.error(err.message);
  };

export const forgotPassword =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(forgotPasswordInit(data));
  };
const forgotPasswordInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FORGOT_PASSWORD.FORGOT_PASSWORD_INITLIZATION,
    });
    apiCall(
      FORGET_PASSWORD_URL,
      data,
      (res: any) => {
        if (res.success) {
          dispatch(forgotPasswordSuccess(res));
        } else {
          dispatch(forgotPasswordError(res));
        }
      },
      (err: any) => dispatch(forgotPasswordError(err)),
      METHOD.POST,
      {}
    );
  };
const forgotPasswordSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS,
      payload: res.data,
    });
    toast.success(res.message);
  };
const forgotPasswordError =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FORGOT_PASSWORD.FORGOT_PASSWORD_ERORR,
    });
    toast.error(res.message);
  };

export const resetPassword =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(resetPasswordInit(data));
  };
const resetPasswordInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: RESET_PASSWORD.RESET_PASSWORD_INITLIZATION,
    });
    const userId = getToken('userId');
    apiCall(
      `${RESET_PASSWORD_URL}/${userId}`,
      data,
      (res: any) => dispatch(resetPasswordSuccess(res)),
      (err: any) => dispatch(resetPasswordError(err)),
      METHOD.POST,
      {}
    );
  };
const resetPasswordSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: RESET_PASSWORD.RESET_PASSWORD_SUCCESS,
      payload: res.success,
    });
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
const resetPasswordError =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: RESET_PASSWORD.RESET_PASSWORD_ERORR,
    });
    toast.error(res.message);
  };
export const userDetail =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(userDetailInit(id));
  };

export const userDetailInit =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DETAIL.USER_DETAIL_INITLIZATION,
    });
    // const userId=getToken("userId")
    apiCall(
      // USER_DETAIL_URL,
      USER_FEEDS_URL,
      { user_id: id, page: 1 }, // pending page should be dynamic
      (res: any) => {
        dispatch(userDetailSuccess(res));
      },
      (err: any) => {
        dispatch(userDetailError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const userDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DETAIL.USER_DETAIL_SUCCESS,
      payload: res,
    });

    // toast.success(res.message)
  };

export const userDetailError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DETAIL.USER_DETAIL_ERORR,
    });
    // toast.error(err.message)
  };

export const searchUserDetail =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(searchUserDetailInit(id));
  };

export const searchUserDetailInit =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: SEARCH_USER_DETAIL.SEARCH_USER_DETAIL_INITLIZATION,
    });
    // const userId=getToken("userId")
    apiCall(
      // SEARCH_USER_DETAIL_URL,
      USER_FEEDS_URL,
      { user_id: id, page: 1 }, // pending page should be dynamic
      (res: any) => {
        dispatch(searchUserDetailSuccess(res));
      },
      (err: any) => {
        dispatch(searchUserDetailError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const searchUserDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: SEARCH_USER_DETAIL.SEARCH_USER_DETAIL_SUCCESS,
      payload: res,
    });

    // toast.success(res.message)
  };

export const searchUserDetailError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: SEARCH_USER_DETAIL.SEARCH_USER_DETAIL_ERORR,
    });
    // toast.error(err.message)
  };

export const userProfileData =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(userProfileInit(id));
  };

export const userProfileInit =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_PROFILE.USER_PROFILE_INITLIZATION,
    });
    // const userId=getToken("userId")
    apiCall(
      USER_DETAIL_URL,
      { userid: id },
      (res: any) => {
        dispatch(userProfileSuccess(res));
      },
      (err: any) => {
        dispatch(userProfileError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const userProfileSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_PROFILE.USER_PROFILE_SUCCESS,
      payload: res,
    });

    // toast.success(res.message)
  };

export const userProfileError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_PROFILE.USER_PROFILE_ERORR,
    });
    // toast.error(err.message)
  };

export const logout =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch(logoutInit());
  };

export const logoutInit =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    // dispatch({
    //   type: LOGOUT.LOGOUT_INITLIZATION
    // });

    dispatch(logoutSuccess());

    // apiCall(
    //   LOGOUT_URL,
    //   {},
    //   (res: any) => {
    //
    //     dispatch(logoutSuccess(res))},
    //   (err:any) =>{
    //
    //     dispatch(logoutError(err))},
    //   METHOD.POST,
    //   {}
    // );
  };

export const logoutSuccess =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch({
      type: LOGOUT.LOGOUT_SUCCESS,
      // payload:res
    });
    //
    // toast.success(res.message)
  };

export const logoutError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: LOGOUT.LOGOUT_ERORR,
    });
    // toast.error(err.message)
  };

export const userTranding =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch(userTrandingInit());
  };

export const userTrandingInit =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch({
      type: USER_TRANDING.USER_TRANDING_INITLIZATION,
    });
    apiCall(
      USER_TRANDING_URL,
      {},
      (res: any) => {
        dispatch(userTrandingSuccess(res));
      },
      (err: any) => {
        dispatch(userTrandingError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const userTrandingSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_TRANDING.USER_TRANDING_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const userTrandingError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_TRANDING.USER_TRANDING_ERORR,
    });
    // toast.error(err.message)
  };

export const userTrandingFeed =
  (pageNo?: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(userTrandingFeedInit(pageNo));
  };

export const userTrandingFeedInit =
  (pageNo?: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_TRANDING_FEEDS.USER_TRANDING_FEEDS_INITLIZATION,
    });
    apiCall(
      !pageNo ? USER_TRANDING_FEEDS_URL : `/api/feeds/trending?page=${pageNo}`,
      {},
      (res: any) => {
        dispatch(userTrandingFeedSuccess(res, pageNo));
      },
      (err: any) => {
        dispatch(userTrandingFeedError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const userTrandingFeedSuccess =
  (res: any, page: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_TRANDING_FEEDS.USER_TRANDING_FEEDS_SUCCESS,
      payload: { res, page },
    });
    //
    // toast.success(res.message)
  };

export const userTrandingFeedError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_TRANDING_FEEDS.USER_TRANDING_FEEDS_ERORR,
    });
    // toast.error(err.message)
  };

// get contry list
export const getCountry =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch(getCountryInit());
  };

export const getCountryInit =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch({
      type: GET_COUNTRY.GET_COUNTRY_INITLIZATION,
    });
    apiCall(
      GET_CONTRY_URL,
      {},
      (res: any) => {
        dispatch(getCountrySuccess(res));
      },
      (err: any) => {
        dispatch(getCountryError(err));
      },
      METHOD.GET,
      {}
    );
  };

export const getCountrySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_COUNTRY.GET_COUNTRY_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const getCountryError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_COUNTRY.GET_COUNTRY_INITLIZATION,
    });
    // toast.error(err.message)
  };

// get contry list
export const getState =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getStateInit(data));
  };

export const getStateInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_STATE.GET_STATE_INITLIZATION,
    });
    apiCall(
      GET_STATE_URL,
      data,
      (res: any) => {
        dispatch(getStateSuccess(res));
      },
      (err: any) => {
        dispatch(getStateError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const getStateSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_STATE.GET_STATE_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const getStateError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_STATE.GET_STATE_INITLIZATION,
    });
    // toast.error(err.message)
  };

// edit profile

export const editProfile =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(editProfileInit(values));
  };

export const editProfileInit =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_PROFILE.EDIT_PROFILE_INITLIZATION,
    });
    apiCall(
      EDIT_PROFILE_URL,
      values,
      (res: any) => {
        dispatch(editProfileSuccess(res));
      },
      (err: any) => {
        dispatch(editProfileError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const editProfileSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_PROFILE.EDIT_PROFILE_SUCCESS,
      payload: res.data,
    });
    //
    if (res.data) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

export const editProfileError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_PROFILE.EDIT_PROFILE_INITLIZATION,
    });
    // toast.error(err.message)
  };
export const changePassword =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(changePasswordInit(values));
  };

export const changePasswordInit =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_INITLIZATION,
    });
    apiCall(
      CHANGE_PASSWORD_URL,
      values,
      (res: any) => {
        dispatch(changePasswordSuccess(res));
      },
      (err: any) => {
        dispatch(changePasswordError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const changePasswordSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS,
      payload: res.data,
    });
    //
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

export const changePasswordError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_INITLIZATION,
    });
    // toast.error(err.message)
  };

export const userFeed =
  (page = 1, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(userFeedInit(page, id));
  };

export const userFeedInit =
  (
    page: number,
    user_id: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_FEEDS.USER_FEEDS_INITLIZATION,
    });
    apiCall(
      USER_FEEDS_URL,
      { page, user_id },
      (res: any) => {
        dispatch(userFeedSuccess(res, page));
      },
      (err: any) => {
        dispatch(userFeedError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const userFeedSuccess =
  (res: any, page: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_FEEDS.USER_FEEDS_SUCCESS,
      payload: { res, page },
    });
  };
export const userFeedError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_FEEDS.USER_FEEDS_ERORR,
    });
    // toast.error(err.message)
  };

export const userDashboardFeed =
  (page = 1): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(userDashboardFeedInit(page));
  };

export const userDashboardFeedInit =
  (page: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DASHBOARD_FEEDS.USER_DASHBOARD_FEEDS_INITLIZATION,
    });
    apiCall(
      USER_DASHBOARD_FEEDS_URL,
      { page },
      (res: any) => {
        dispatch(userDashboardFeedSuccess(res, page));
      },
      (err: any) => {
        dispatch(userDashboardFeedError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const userDashboardFeedSuccess =
  (res: any, page: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DASHBOARD_FEEDS.USER_DASHBOARD_FEEDS_SUCCESS,
      payload: { res, page },
    });
  };
export const userDashboardFeedError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DASHBOARD_FEEDS.USER_DASHBOARD_FEEDS_ERORR,
    });
    // toast.error(err.message)
  };

export const otpVerificationUser =
  (data: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(otpVerificationUserInit(data));
  };
const otpVerificationUserInit =
  (data: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: OTP_VERIFICATION_USER.OTP_VERIFICATION_USER_INITLIZATION,
    });
    apiCall(
      OTP_USER_VERIFICATION_URL,
      data,
      (res: any) => {
        dispatch(otpVerificationUserSuccess(res));
      },
      (err: any) => {
        dispatch(otpVerificationUserError(err));
      },
      METHOD.POST,
      {}
    );
  };
const otpVerificationUserSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: OTP_VERIFICATION_USER.OTP_VERIFICATION_USER_SUCCESS,
      payload: res,
    });
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
const otpVerificationUserError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: OTP_VERIFICATION_USER.OTP_VERIFICATION_USER_ERORR,
    });
    toast.error(err.message);
  };

// =====================================Follow user======================================
export const followUser =
  (
    id: number,
    reducerName = ''
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(followUserInit(id, reducerName));
  };

export const followUserInit =
  (
    id: number,
    reducerName: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FOLLOW_USER.FOLLOW_USER_INITLIZATION,
    });
    // const userId=getToken("userId")
    apiCall(
      FOLLOW_URL,
      { user_id: id },
      (res: any) => {
        if (res.success) {
          dispatch(followUserSuccess(id, reducerName));
        }
      },
      (err: any) => {
        dispatch(followUserError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const followUserSuccess =
  (
    res: any,
    reducerName: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({ type: FOLLOW_USER.FOLLOW_USER_FAVORITE, payload: res });
    dispatch({ type: FOLLOW_USER.FOLLOW_USER_PROFILE, payload: res });

    switch (reducerName) {
      case 'user-trending-list':
        dispatch({
          type: FOLLOW_USER.FOLLOW_USER_SUCCESS_FOR_SUGGESTION,
          payload: res,
        });
        break;
      default:
        dispatch({
          type: FOLLOW_USER.FOLLOW_USER_SUCCESS,
          payload: res,
        });
    }

    // toast.success(res.message)
  };

export const followUserError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FOLLOW_USER.FOLLOW_USER_ERORR,
    });
    // toast.error(err.message)
  };
// =========================================Unfollow user===============================================

export const unfollowUser =
  (
    id: number,
    reducerName = ''
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(unfollowUserInit(id, reducerName));
  };

export const unfollowUserInit =
  (
    id: number,
    reducerName: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: UNFOLLOW_USER.UNFOLLOW_USER_INITLIZATION,
    });
    // const userId=getToken("userId")
    apiCall(
      UNFOLLOW_URL,
      { user_id: id },
      (res: any) => {
        if (res.success) {
          dispatch(unfollowUserSuccess(id, reducerName));
        }
      },
      (err: any) => {
        dispatch(unfollowUserError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const unfollowUserSuccess =
  (
    res: any,
    reducerName: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({ type: UNFOLLOW_USER.UNFOLLOW_USER_FAVORITE, payload: res });
    dispatch({ type: UNFOLLOW_USER.UNFOLLOW_USER_USER_PROFILE, payload: res });
    switch (reducerName) {
      case 'user-trending-list':
        dispatch({
          type: UNFOLLOW_USER.UNFOLLOW_USER_SUCCESS_FOR_SUGGESTION,
          payload: res,
        });
        break;
      default:
        dispatch({
          type: UNFOLLOW_USER.UNFOLLOW_USER_SUCCESS,
          payload: res,
        });
    }

    // toast.success(res.message)S
  };

export const unfollowUserError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: UNFOLLOW_USER.UNFOLLOW_USER_ERORR,
    });
    // toast.error(err.message)
  };

// Hint: get follower list
export const getFollowerList =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getFollowerListInit(data));
  };

export const getFollowerListInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_FOLLOWERS.GET_FOLLOWER_LIST_INITLIZATION,
    });
    apiCall(
      GET_FOLLOWER_LIST_URL,
      { user_id: data.userId, page: data.page },
      (res: any) => {
        dispatch(getFollowerListSuccess(res));
      },
      (err: any) => {
        dispatch(getFollowerListError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const getFollowerListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_FOLLOWERS.GET_FOLLOWER_LIST_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const getFollowerListError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_FOLLOWERS.GET_FOLLOWER_LIST_ERROR,
    });
    // toast.error(err.message)
  };

export const gerFollowingList =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(gerFollowingListInit(data));
  };

export const gerFollowingListInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FOLLOWING.GET_FOLLOWING_INITLIZATION,
    });
    apiCall(
      USER_FOLLOWING,
      { user_id: data.userId, page: data.page },
      (res: any) => {
        dispatch(gerFollowingListSuccess(res));
      },
      (err: any) => {
        dispatch(gerFollowingListError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const gerFollowingListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FOLLOWING.GET_FOLLOWING_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const gerFollowingListError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FOLLOWING.GET_FOLLOWING_ERROR,
    });
    // toast.error(err.message)
  };

//Hint: Block User
// =====================================Block User======================================
export const blockUser =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(blockUserInit(id));
  };

export const blockUserInit =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: BLOCK_USER.BLOCK_USER_INITLIZATION,
    });
    // const userId=getToken("userId")
    apiCall(
      BLOCK_URL,
      { user_id: id },
      (res: any) => {
        dispatch(blockUserSuccess(res));
      },
      (err: any) => {
        dispatch(blockUserError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const blockUserSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: BLOCK_USER.BLOCK_USER_SUCCESS,
      payload: res,
    });

    // toast.success(res.message)
  };

export const blockUserError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: BLOCK_USER.BLOCK_USER_ERORR,
    });
    // toast.error(err.message)
  };
// =========================================Unblock user===============================================

export const unblockUser =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(unblockUserInit(id));
  };

export const unblockUserInit =
  (id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: UNBLOCK_USER.UNBLOCK_USER_INITLIZATION,
    });
    // const userId=getToken("userId")
    apiCall(
      UNBLOCK_URL,
      { user_id: id },
      (res: any) => {
        dispatch(unblockUserSuccess(res));
      },
      (err: any) => {
        dispatch(unblockUserError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const unblockUserSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: UNBLOCK_USER.UNBLOCK_USER_SUCCESS,
      payload: res,
    });

    // toast.success(res.message)S
  };

export const unblockUserError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: UNBLOCK_USER.UNBLOCK_USER_ERORR,
    });
    // toast.error(err.message)
  };

// Hint: get follower list
export const getBlockList =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getBlockListInit(data));
  };

export const getBlockListInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_BLOCK.GET_BLOCK_LIST_INITLIZATION,
    });
    apiCall(
      BLOCK_LIST_URL,
      { user_id: data.userId, page: data.page },
      (res: any) => {
        dispatch(getBlockListSuccess(res));
      },
      (err: any) => {
        dispatch(getBlockListError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const getBlockListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_BLOCK.GET_BLOCK_LIST_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const getBlockListError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_BLOCK.GET_BLOCK_LIST_ERROR,
    });
    // toast.error(err.message)
  };

export const editBanking =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(editBankingInit(values));
  };

export const editBankingInit =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_BANKING.EDIT_BANKING_INITLIZATION,
    });
    apiCall(
      EDIT_BANKING_URL,
      values,
      (res: any) => {
        dispatch(editBankingSuccess(res));
      },
      (err: any) => {
        dispatch(editBankingError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const editBankingSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_BANKING.EDIT_BANKING_SUCCESS,
      payload: res.data,
    });
    //
    toast.success(res.message);
  };

export const editBankingError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_BANKING.EDIT_BANKING_INITLIZATION,
    });
    // toast.error(err.message)
  };

export const getStories =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch(getStoriesInit());
  };

export const getStoriesInit =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch({
      type: STORIES.GET_STORIES_INITLIZATION,
    });
    apiCall(
      GET_STORIES_URL,
      {},
      (res: any) => {
        if (res.success) {
          dispatch(getStoriesSuccess({ res }));
        } else {
          dispatch(getStoriesError('Error Occured while getting stories'));
        }
      },
      (err: any) => {
        dispatch(getStoriesError(err));
      },
      METHOD.GET,
      {}
    );
  };

export const getStoriesSuccess =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: STORIES.GET_STORIES_SUCCESS,
      payload: data.res,
    });
  };

export const getStoriesError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: STORIES.GET_STORIES_ERROR,
    });
    // toast.error(err);
  };

export const addPostSuccess =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_FEEDS.ADD_POST,
      payload: data,
    });
  };

export const deletePostSuccess =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_FEEDS.DELETE_POST,
      payload: data,
    });
  };

// =========================================subscribe user===============================================

export const subscribe =
  (
    package_user_id: number,
    package_id: number
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(subScribersInit(package_user_id, package_id));
  };

export const subScribersInit =
  (
    package_user_id: number,
    package_id: number
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: SUBSCRIPTION.SUBCRIPTION_INITLIZATION,
    });
    apiCall(
      SUBSCRIPTION_URL,
      { package_user_id: package_user_id, package_id: package_id },
      (res: any) => {
        dispatch(subscribeSuccess(res, package_user_id));
      },
      (err: any) => {
        dispatch(subscribeError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const subscribeSuccess =
  (
    res: any,
    package_user_id: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: SUBSCRIPTION.SUBSCRIPTION_SUCCESS,
      payload: { res, package_user_id },
    });
    if (res.success) {
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };

export const subscribeError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: SUBSCRIPTION.MY_SUBSCRIBER_ERORR,
    });
    toast.error(err?.message);
  };

// ----------------------------------Get hash tag post -----------------------------------------------

export const getHashTagPost =
  (value: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getHashTagPostInit(value));
  };

export const getHashTagPostInit =
  (value: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: HASH_TAGS.GET_HASH_TAGS_INITLIZATION,
    });
    apiCall(
      GET_HASH_TAGS,
      value,
      (res: any) => {
        if (res.success) {
          dispatch(getHashTagPostSuccess(res, value));
        } else {
          dispatch(
            getHashTagPostError('Error Occured while getting #tags post ')
          );
        }
      },
      (err: any) => {
        dispatch(getHashTagPostError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const getHashTagPostSuccess =
  (data: any, value: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: HASH_TAGS.GET_HASH_TAGS_SUCCESS,
      payload: { data, value },
    });
  };

export const getHashTagPostError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: HASH_TAGS.GET_HASH_TAGS_ERROR,
    });
    // toast.error(err);
  };

export const givePostVote =
  (value: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(givePostVoteInit(value));
  };

export const givePostVoteInit =
  (value: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: POST_VOAT.POST_VOAT_INITLIZATION,
    });
    apiCall(
      GIVE_VOTE_URL,
      value,
      (res: any) => {
        dispatch(givePostVoteSuccess(res, value));
        if (!res.success) {
          toast.error(res.message);
        }
      },
      (err: any) => {
        dispatch(givePostVoteError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const givePostVoteSuccess =
  (data: any, value: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: POST_VOAT.POST_VOAT_SUCCESS,
      payload: { data, value },
    });
    if(data.success){
      toast.success(data.message);
    }
  };

export const givePostVoteError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: POST_VOAT.POST_VOAT_ERROR,
    });
    // toast.error(err);
  };
