import { useEffect, useRef, useState, Fragment } from 'react';
import { Button, Modal, ModalBody, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/Actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux';
import { S3_BASE_URL } from '../../service/aws/config';
import SearchableSelect from '../SearchableSelect';
import { GET_PEOPLE_LIST_URL, SEARCH_TAGS } from '../../redux/ApiEndPoints';
import { apiCall, METHOD } from '../../service/baseApiCall';
import { useFormik } from 'formik';
import {
  MEDIA_SIZE,
  PAYMENT_TYPE,
  POST_TYPE,
} from '../../constants/App-constant';
import { uploadFileAmazonS3 } from '../../service/index.';
import { createTextPost } from '../../redux/action/postActions';
import AudioPlayer from '../AudioPlayer';
import {
  createImageFromInitials,
  getRandomColor,
} from '../../utils/createImage';
import { RiEyeCloseFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../utils/utility';

export default function AudioPost({
  showPostComment,
  setShowPostComment,
}: any) {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const postOptions = [
    { label: t('home.free to all'), value: 0 },
    { label: t('home.for subscribers'), value: 1 },
    { label: t('home.paid content'), value: 2 },
  ];
  const local = localStorage.getItem('local');
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  const [image, setImage] = useState('');
  const [audio, setAudio] = useState('');
  const [imageErr, setImageErr] = useState(false);
  const [audioErr, setAudioErr] = useState(false);
  const [amountErr, setAmountErr] = useState(false);
  const [textlimitError, setTextlimitError] = useState<boolean>(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [audioLoader, setAudioLoader] = useState(false);
  const [audioAmountErr, setAudioAmountErr] = useState(false);
  const [audioTypeErr, setAudioTypeErr] = useState(false);
  const [imageTypeErr, setImageTypeErr] = useState(false);
  const [audioErrorModal, setAudioErrorModal] = useState(false);
  const [audioSizeError, setAudioSizeError] = useState(false);
  const [audioImageSizeError, setAudioImageSizeError] = useState(false);
  const onImageChange = (event: any) => {
    setAudioImageSizeError(false);
    const file = event.target.files?.[0];
    if (file?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      if (!file?.name.match(/\.(jpg|jpeg|png)$/)) {
        setImageTypeErr(true);
        setImageErr(false);
        return false;
      } else {
        setImage(event.target.files[0]);
        setImageTypeErr(false);
      }
    } else {
      setAudioImageSizeError(true);
    }
  };
  const onAudioChange = (event: any) => {
    const file = event.target.files?.[0];
    setAudioSizeError(false);
    if (file?.size / 1024 < MEDIA_SIZE.AUDIO_SIZE) {
      if (!file?.name.match(/\.(mp3)$/)) {
        setAudioTypeErr(true);
        setAudioErr(false);
        return false;
      } else {
        setAudio(event.target.files[0]);
        setAudioTypeErr(false);
      }
    } else {
      setAudioSizeError(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      post_type: POST_TYPE.POST_TYPE_AUDIO,
      description: '',
      hash_tags: [] || '',
      tags: [] || '',
      thumbnail: '',
      payment_amount: 0,
      audio: '',
      audio_title: '',
      payment_type: '0',
    },
    onSubmit: async (values) => {
      let value = {
        ...values,
        hash_tags: values.hash_tags.map((i: any) => i.value).join(','),
        tags: values.tags.map((i: any) => i.value).join(','),
      };
      // if (!values.description) {
      //   setDescriptionErr(true);
      // }
      if (values.payment_type == '2' && !values.payment_amount) {
        setAmountErr(true);
      } else if (!image || !audio) {
        if (!image) {
          setImageErr(true);
        }
        if (!audio) {
          setAudioErr(true);
        }
      } else {
        // if (formik.values.payment_amount===0){
        //   setAudioAmountErr(true)
        // }

        if (image && audio) {
          setAudioLoader(true);
          await uploadFileAmazonS3(audio, 'post/audios/').then((data) => {
            value = { ...value, audio: data.Key };
          });
          await uploadFileAmazonS3(image, 'post/images/').then((data) => {
            value = { ...value, thumbnail: data.Key };
          });
          await dispatch(createTextPost(value));
          await setShowPostComment(false);
          setAudioLoader(false);
          setAudio('');
          setImage('');
          setAudioTypeErr(false);
          setImageTypeErr(false);
          setAudioImageSizeError(false)
          setAudioSizeError(false)
          setImageErr(false);
          setAudioErr(false);
          const userId = getToken('userId');
          dispatch(userDetail(Number(userId)));
          formik.values.description = '';
          formik.resetForm();
        }
      }
    },
  });
  const { errors, touched } = formik;

  const handlePeopleSearch = async (keyword: any, onSuccess: any) => {
    await apiCall(
      GET_PEOPLE_LIST_URL,
      { search: keyword },
      (res: any) => {
        onSuccess(
          res.data.map((i: any) => ({
            label: `${i?.first_name} ${i?.last_name}`,
            value: i?.id,
          }))
        );
      },
      () => {
        onSuccess([]);
      },
      METHOD.POST
    );
  };

  const handleTagSearch = async (keyword: any, onSuccess: any) => {
    await apiCall(
      SEARCH_TAGS,
      { search: keyword, page: 1 },
      (res: any) => {
        onSuccess(
          res.data.map((i: any) => ({
            label: i?.hash_tag,
            value: i?.hash_tag,
          }))
        );
      },
      () => {
        onSuccess([]);
      },
      METHOD.POST
    );
  };
  const handleChangeAudioDescription = (e: any) => {
    if (formik.values.description.length > 240) {
      setTextlimitError(true);
    } else {
      setTextlimitError(false);
    }
    formik.setFieldValue('description', e.target.value);
  };
  const handleChangeAudioPaidContant = (e: any) => {
    if (userDetail?.user_verified) {
      formik.setFieldValue('payment_type', e.target.value);
    } else {
      setAudioErrorModal(true);
    }
  };
  return (
    <Modal
      show={showPostComment}
      onHide={() => {
        setShowPostComment(false);
        setAudio('');
        setImage('');
        setImageTypeErr(false);
        setImageErr(false);
        setAudioTypeErr(false);
        setAudioImageSizeError(false)
        setAudioSizeError(false)
        setAudioErr(false);
        setTextlimitError(false);
        formik.resetForm();
      }}
      className="upload-image-popup photo-modal audio-popup"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close d-md-none"
          data-bs-dismiss="modal"
        ></button>

        <div className="main-section m-0">
          <div className="left-section">
            <div className="file-label mb-3 h-100 d-flex flex-column gap-4">
              {image ? (
                <>
                  <img
                    src={URL.createObjectURL(image as any)}
                    className="audio-cover-img"
                    alt=""
                  />
                  <label>
                    <input
                      type="file"
                      id="picupload"
                      hidden
                      onChange={onImageChange}
                    />
                    {audioImageSizeError && (
                      <div className="text-danger text-center mb-4">
                        Please Select less then {MEDIA_SIZE.IMAGE_SIZE / 1024}{' '}
                        MB Image file
                      </div>
                    )}
                    {imageTypeErr && imageErr ? (
                      <div className="text-danger text-center mb-4">
                        {t('home.cover required')}
                      </div>
                    ) : (
                      <>
                        {imageTypeErr ? (
                          <div className="text-danger text-center mb-4">
                            {t('home.upload file')}
                          </div>
                        ) : (
                          <>
                            {imageErr && (
                              <div className="text-danger text-center mb-4">
                                {t('home.cover required')}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <span className="span">{t('home.change cover')}</span>
                  </label>
                </>
              ) : (
                <div>
                  <label>
                    <input
                      type="file"
                      id="picupload"
                      hidden
                      onChange={onImageChange}
                    />
                    {audioImageSizeError && (
                      <div className="text-danger text-center mb-4">
                        Please Select less then {MEDIA_SIZE.IMAGE_SIZE / 1024}{' '}
                        MB Image file
                      </div>
                    )}
                    {imageTypeErr && imageErr ? (
                      <div className="text-danger text-center mb-4">
                        {t('home.cover required')}
                      </div>
                    ) : (
                      <>
                        {imageTypeErr ? (
                          <div className="text-danger text-center mb-4">
                            {t('home.upload file')}
                          </div>
                        ) : (
                          <>
                            {imageErr && (
                              <div className="text-danger text-center mb-4">
                                {t('home.cover required')}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <span className="span">
                      {t('home.upload cover from your computer')}
                    </span>
                  </label>
                </div>
              )}{' '}
            </div>
            {/* <div className="file-box file-label">
                <div className="audio-thumbnail">
                  <img src="assets/images/imgs/audio-thumb.svg" alt="" />
                </div>
                <div className="btn-group">
                  <label htmlFor="picupload" className="btn">
                    <img src="assets/images/icons/upload.svg" alt="" />
                  </label>
                </div>
              </div> */}
            <div className="file-label file-label-sm mb-md-0 d-flex flex-column">
              {audio ? (
                <>
                  <div className="audio-post-container">
                    {/* <div className="audio-sound">
                  <div className="sound-control">
 */}
                    <AudioPlayer track={URL.createObjectURL(audio as any)} />
                    <label
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      <input type="file"  hidden onChange={onAudioChange} />
                      <span
                        className="span"
                        style={{ marginLeft: '300px', marginTop: '12px' }}
                      >
                        {t('home.change audio')}
                      </span>
                    </label>
                  </div>
                  {/* </div>
</div> */}
                </>
              ) : (
                <div>
                  <label>
                    <input
                      type="file"
                      accept=',audio/*'
                      hidden
                      onChange={onAudioChange}
                    />
                    {audioSizeError && (
                      <div className="text-danger text-center mb-4">
                        Please select less then {MEDIA_SIZE.AUDIO_SIZE / 1024}{' '}
                        MB Audio File
                      </div>
                    )}
                    {audioTypeErr && audioErr ? (
                      <div className="text-danger text-center mb-4">
                        {t('home.audio required')}
                      </div>
                    ) : (
                      <>
                        {audioTypeErr ? (
                          <div className="text-danger text-center mb-4">
                            {t('home.upload only mp3')}
                          </div>
                        ) : (
                          <>
                            {audioErr && (
                              <div className="text-danger text-center mb-4">
                                {t('home.audio required')}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <span className="span">
                      {t('home.upload audio from your computer')}
                    </span>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="right-section">
            <div className="caption-control">
              <div className="post-user post-user-control d-flex align-items-start p-0">
                <div className="post-user-logo">
                  <img
                    src={
                      userDetail?.profile_photo
                        ? S3_BASE_URL + userDetail?.profile_photo
                        : createImageFromInitials(
                            500,
                            userDetail?.first_name,
                            getRandomColor()
                          )
                    }
                    className="img-fluid"
                    style={{ height: '45px', width: '45px' }}
                    alt="icon"
                  />
                </div>
                <textarea
                  placeholder={`${t('home.caption')}`}
                  className="form-control p-0 pt-3 border-0"
                  rows={4}
                  value={formik.values.description}
                  onChange={handleChangeAudioDescription}
                  onFocus={() => setDescriptionErr(false)}
                  required
                ></textarea>
              </div>
            </div>
            {textlimitError && (
              <span
                className="text-danger ml-2"
                style={{ fontSize: 'smaller' }}
              >
                {t('home.please enter less then 240 word')}
              </span>
            )}
            {/* {descriptionErr && (
              <div className="text-danger">description is required</div>
            )} */}
            <div
              className={`radio-price-group radio-price-group-circle ${
                local =='es' ? 'langauge-validation' : ''
              }`}
            >
              {postOptions.map((item) => (
                <Fragment key={`Item${item.value}`}>
                  <input
                    type="radio"
                    className="btn-check"
                    id={`Content-Item${item.value}`}
                    checked={formik.values.payment_type === String(item.value)}
                    value={item.value}
                    onChange={(e) => handleChangeAudioPaidContant(e)}
                  />
                  <label
                    className="btn btn-secondary"
                    htmlFor={`Content-Item${item.value}`}
                  >
                    {item.label}
                  </label>
                </Fragment>
              ))}
            </div>

            {/* <label className="caption-label">
              <b>Audio Title</b>{" "}
              <input
                type="text"
                placeholder="audio title"
                className="form-control"
                value={formik.values.audio_title}
                onChange={(e) =>
                  formik.setFieldValue("audio_title", e.target.value)
                }
              />
            </label> */}
            <label className="caption-label">
              <b>{t('home.tag people')}</b> <i>@</i>{' '}
              <SearchableSelect
                isMulti
                placeholder={`${t('home.select')}`}
                onSearch={handlePeopleSearch}
                onChange={(val) => formik.setFieldValue('tags', val)}
                value={formik.values.tags}
              />
            </label>
            <label className="caption-label">
              <b>Hashtag</b> <i>#</i>{' '}
              <SearchableSelect
                isMulti
                placeholder={`${t('home.select')}`}
                onSearch={handleTagSearch}
                onChange={(val) => formik.setFieldValue('hash_tags', val)}
                value={formik.values.hash_tags}
              />
            </label>
            {formik.values.payment_type === '2' && (
              <>
                <label className="caption-label border-0 mb-md-0 p-1">
                  <b>{t('home.amount')}</b> <i>$</i>{' '}
                  <input
                    required
                    type="text"
                    placeholder="5"
                    className="form-control"
                    onChange={(e) => {
                      formik.setFieldValue('payment_amount', e.target.value);
                    }}
                    onFocus={() => setAmountErr(false)}
                    // onClick ={()=>{setAudioAmountErr(false)}}
                  />
                </label>
                {amountErr && (
                  <div className="text-danger">{t('home.amount required')}</div>
                )}
                {/* {audioAmountErr && <div className="pt-2 text-danger pl-2">
                    Amount must not be 0
                  </div>} */}
              </>
            )}
            <div className="modal-footer justify-content-center border-0 p-0 mt-auto">
              <button
                type="button"
                className="btn btn-login w-100 py-4"
                data-bs-dismiss="modal"
                onClick={() => formik.handleSubmit()}
                disabled={audioLoader || amountErr || textlimitError}
              >
                {audioLoader ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <>{t('home.post')}</>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal
        show={audioErrorModal}
        onHide={() => {
          setAudioErrorModal(false);
        }}
      >
        <ModalBody>
          <h6> {t('home.varified profile error')}</h6>
          <div style={{ textAlign: 'end', marginLeft: '17px' }}>
            <Button onClick={() => setAudioErrorModal(false)}>
              {t('home.close')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Modal>
  );
}
