/* eslint-disable import/no-anonymous-default-export */
import { NOTIFICATION } from "../constants/action-types";
import { NotificationData } from "../Actions";
import { ActionTypes } from "../action/userActions";

const initialState = {
  loading: false,
  notificationList: [],
  hasMoreFeedNotification: true,
};
export default (
  state: NotificationData = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case NOTIFICATION.NOTIFICATION_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case NOTIFICATION.NOTIFICATION_SUCCESS:
      var newDataIds: any = [];
      state.notificationList?.forEach((elem: any) => {
        newDataIds.push(elem.id);
      });
      const upCommingData = action.payload.filter(
        (elem: any) => !newDataIds.includes(elem.id)
      );
      return {
        ...state,
        hasMoreFeedNotification: action.payload.length > 0,
        notificationList: [...state.notificationList, ...upCommingData],
      };
    case NOTIFICATION.NOTIFICATION_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
