/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Store, PlanData } from '../redux/Actions';
import { userPackageList, editPlan } from '../redux/action';
import { dispatch } from '../redux';
//import moment from "moment"
import { getToken } from '../utils/utility';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import { toast } from 'react-toastify';

const Plans = (props: any) => {
  const { t } = useTranslation();
  const { packageList, loading }: PlanData = useSelector(
    (state: Store) => state?.myPlanReducer
  );
  // const [page, setPage] = useState(1);
  const [trialCheck, setTrialCheck] = useState({
    anualStatus: packageList?.Annual?.status === 1 ? true : false,
    monthlyStatus: packageList?.Monthly?.status === 1 ? true : false,
    freeStatus: packageList?.Free?.status === 1 ? true : false,
  });
  const [annualActiveError, setAnnualActiveError] = useState<boolean>(false);
  const [monthalyActiveError, setMonthalyActiveError] =
    useState<boolean>(false);
  const isUserVerifiedFromRedux: boolean = useSelector(
    (state: Store) => state.userDataReducer.userDetail.user_verified
  );
  const user_id = getToken('userId');

  useEffect(() => {
    dispatch(userPackageList(user_id));
  }, [props.currentPage]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      monthly: packageList?.Monthly?.price,
      anual: packageList?.Annual?.price,
      trial: packageList?.Free?.package_validity,
    },
    // validationSchema: EditBankingSchema,
    onSubmit: async (values) => {
      if (trialCheck.freeStatus && Number(values.trial) <= 0) {
        toast.error('Please enter valid free trial day');
      } else if (
        !trialCheck.anualStatus &&
        !trialCheck.monthlyStatus &&
        trialCheck.freeStatus
      ) {
        toast.error('please off free trial plan');
      } else {
        var editableData: any = {}
        if (trialCheck.anualStatus && !values.anual) {
          setAnnualActiveError(true);
          toast.error("Without amount you can't active annual plan");
        } else if (trialCheck.monthlyStatus && !values.monthly) {
          setMonthalyActiveError(true);
          toast.error("Without amount you can't active monthaly plan");
        } else {
          if (values.monthly && trialCheck.monthlyStatus) {
            editableData = { monthly: values.monthly }
          }
          if (values.anual && trialCheck.anualStatus) {
            editableData = { ...editableData, anual: values.anual }
          }
          if (values.trial && trialCheck.freeStatus) {
            editableData = { ...editableData, trial: values.trial }
          }
          var packagesValue: any = {
            ...editableData,
          };
          dispatch(editPlan(packagesValue));
          setAnnualActiveError(false);
          setMonthalyActiveError(false);
        }
      }
    },
  });

  const handleStatus = (value: any) => {
    const { name, checked } = value.target;

    setTrialCheck({ ...trialCheck, [name]: checked });
    setMonthalyActiveError(false);
    setAnnualActiveError(false);
  };
  useEffect(() => {
    setTrialCheck({
      ...trialCheck,
      anualStatus: packageList?.Annual?.status === 1 ? true : false,
      monthlyStatus: packageList?.Monthly?.status === 1 ? true : false,
      freeStatus: packageList?.Free?.status === 1 ? true : false,
    });
  }, [packageList]);
  return (
    <>
      {loading && <Loader />}
      <h2 className="accordion-header d-lg-none" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#subscriptions"
          aria-expanded="false"
          aria-controls="subscriptions"
        >
          {t('plan.myplans')}
        </button>
      </h2>
      <div
        id="subscriptions"
        className="accordion-collapse collapse d-lg-block myplans-page"
        aria-labelledby="headingThree"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            <h2 className="setting-title">{t('plan.myplans')}</h2>
          </div>
          {isUserVerifiedFromRedux && (
            <form onSubmit={formik.handleSubmit}>
              <div className="plans-supscription-box">
                <div className="top-box">
                  <h6> {t('plan.monthlysubscription')}</h6>
                  <div className="check-box">
                    <input
                      type="checkbox"
                      name="monthlyStatus"
                      checked={trialCheck.monthlyStatus}
                      onChange={handleStatus}
                    />
                  </div>
                </div>
                <div className="bottom-box">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>{t('plan.price')}</Form.Label>
                    <Form.Control
                      type="number"
                      name="monthly"
                      value={formik.values.monthly}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        setMonthalyActiveError(false);
                      }}
                      onBlur={formik.handleBlur}
                      onKeyDown={e =>  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                    />
                    {/* <span>$</span> */}
                  </Form.Group>
                </div>
                {monthalyActiveError && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: 'smaller' }}
                  >
                    Amount is required
                  </div>
                )}
              </div>
              <div className="plans-supscription-box">
                <div className="top-box">
                  <h6>{t('plan.annuallysubscription')}</h6>
                  <div className="check-box">
                    <input
                      type="checkbox"
                      name="anualStatus"
                      checked={trialCheck.anualStatus}
                      onChange={handleStatus}
                    />
                  </div>
                </div>
                <div className="bottom-box">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>{t('plan.price')}</Form.Label>
                    <Form.Control
                      type="number"
                      name="anual"
                      value={formik.values.anual}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        setAnnualActiveError(false);
                      }}
                      onBlur={formik.handleBlur}
                      onKeyDown={e =>  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                    />
                    {/* <span>$</span> */}
                  </Form.Group>
                </div>
                {annualActiveError && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: 'smaller' }}
                  >
                    Amount is required
                  </div>
                )}
              </div>
              {trialCheck.monthlyStatus || trialCheck.anualStatus ? (
                <div className="plans-supscription-box">
                  <div className="top-box">
                    <h6>{t('plan.freetrial')}</h6>
                    <div className="check-box">
                      <input
                        type="checkbox"
                        name="freeStatus"
                        checked={trialCheck.freeStatus}
                        onChange={handleStatus}
                      />
                    </div>
                  </div>
                  <div className="bottom-box">
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Duration (Days)</Form.Label>
                      <Form.Control
                        type="number"
                        name="trial"
                        value={formik.values.trial}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onKeyDown={e =>  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                      />
                      {/* <span>$</span> */}
                    </Form.Group>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="plans-btn">
                <Button type="submit">{t('plan.save')}</Button>
              </div>
            </form>
          )}
        </div>
        {!isUserVerifiedFromRedux && (
          <div className="d-flex justify-content-center align-items-center">
            <div className="fw-medium">
              {t('plan.please verify to create your own plan')}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Plans;
