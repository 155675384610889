import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { S3_BASE_URL } from "../../service/aws/config";

export default function SubsctiptionModel({showPostComment,setShowPostComment}:any) {
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );

//  useEffect(()=>{dispatch(userFeed())},[])
useEffect(() => {
  const handleClickOutside = (event:any) => {
    setShowPostComment(false)
  };
  document.addEventListener('click', handleClickOutside, true);
  return () => {
    document.removeEventListener('click', handleClickOutside, true);
  };
}, [ setShowPostComment ]);
  return (
    <div  className={showPostComment?"modal fade custom-modal comment-modal photo-modal show":"modal fade custom-modal comment-modal photo-modal"} style={{display:showPostComment && "block"}} id="subscriptionModal" tabIndex={-1}
    aria-labelledby="commentModalLabel">
    <div className="modal-dialog  modal-content-lg modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content border-0">
        <div className="modal-body">
          <button type="button" className="btn-close d-md-none" data-bs-dismiss="modal"></button>

          <div className="row m-0">
            <div className="col-12 col-md-6 p-0 mb-md-0 d-flex flex-column">
              <input type="file" id="picupload" hidden />
              {/* <!-- <div className="file-label mb-3 h-100">
                <label htmlFor="picupload">
                  <span>Upload Cover from your computer</span>
                </label>
              </div>
              <div className="file-label file-label-sm mb-md-0">
                <label htmlFor="picupload">
                  <span>Upload Cover from your computer</span>
                </label>
              </div> --> */}
              <div className="file-box file-label">
                <div className="audio-thumbnail">
                  <img src="assets/images/imgs/audio-thumb.svg" alt="" />
                </div>
                <div className="btn-group">
                  <label htmlFor="picupload" className="btn">
                    <img src="assets/images/icons/upload.svg" alt="" />
                  </label>
                </div>
              </div>
              <div className="file-label file-label-sm mb-md-0">
                <div className="media-control w-100">
                  <button className="btn p-0 audio-play">
                    <img src="assets/images/icons/button_Play.svg" alt="" />
                  </button>
                  <div className="col">
                    <div className="d-flex justify-content-between">
                      <p className="duration m-0">1:56</p>
                      <p className="duration m-0">4:27</p>
                    </div>
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" aria-valuenow={0} aria-valuemin={0}
                        aria-valuemax={100} style={{width: "25%"}}></div>
                    </div>
                    <div className="text-end mt-2">
                      <button className="btn btn-link-primary ms-auto">
                        Change audio
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 ps-md-4 d-flex flex-column">
              <div className="caption-control">
                <div className="post-user post-user-control d-flex align-items-start p-0">
                  <div className="post-user-logo">
                    <img src={S3_BASE_URL+userDetail?.profile_photo} className="img-fluid"/>
                  </div>
                  <textarea placeholder="Write a caption here..." className="form-control p-0 pt-3 border-0"
                    rows={4}></textarea>
                </div>
              </div>
              <div className="radio-price-group radio-price-group-circle">
                <input type="radio" className="btn-check" name="plan4" id="all2" autoComplete="off" checked />
                <label className="btn btn-secondary" htmlFor="all2">Free to all</label>

                <input type="radio" className="btn-check" name="plan4" id="Subscribers2" autoComplete="off" />
                <label className="btn btn-secondary" htmlFor="Subscribers2">For Subscribers</label>

                <input type="radio" className="btn-check" name="plan4" id="Content2" autoComplete="off" />
                <label className="btn btn-secondary" htmlFor="Content2">Paid Content</label>

              </div>

              <label className="caption-label">
                <b>Audio Title</b> <input type="text" placeholder="username" className="form-control" />
              </label>
              <label className="caption-label">
                <b>Tag people</b> <i>@</i> <input type="text" placeholder="username" className="form-control" />
              </label>
              <label className="caption-label">
                <b>Hashtag</b> <i>#</i> <input type="text" placeholder="hashtag" className="form-control" />
              </label>
              <label className="caption-label border-0 mb-md-4">
                <b>Amount</b> <i>$</i> <input type="text" placeholder="5" className="form-control" />
              </label>
              <div className="modal-footer justify-content-center border-0 p-0 mt-auto">
                <button type="button" className="btn btn-login w-100 py-4" data-bs-dismiss="modal">Post</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
