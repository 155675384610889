// export const AMAZON_S3_USER_VIDEO_BUCKET = "gems-fx-prod/copy_files";
// export const AMAZON_S3_USER_IMAGE_BUCKET = "fanshub"; // 'gems-test-1';//'gems-fx'; //'gems-fx-prod/production'
// export const AWS_S3_ACCESS_ID = "AKIAUYUVL7VP4UR7HDTT";
// export const AWS_S3_SECRET_KEY = "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";
// export const AWS_S3_REGION = "us-east-1";
// export const S3_BASE_URL="https://fanshub.s3.us-east-1.amazonaws.com/"

export const AMAZON_S3_USER_IMAGE_BUCKET = "fanshubapp"; // 'gems-test-1';//'gems-fx'; //'gems-fx-prod/production'
export const AWS_S3_ACCESS_ID:any = process?.env?.REACT_APP_AWS_S3_ACCESS_KEY_ID;
export const AWS_S3_SECRET_KEY:any = process?.env?.REACT_APP_AWS_S3_SECRET_KEY_ID;
// export const AWS_S3_ACCESS_ID = "AKIA4YO3SMWMLTGKOJ65";
// export const AWS_S3_SECRET_KEY = "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";
export const AWS_S3_URL = 'https://fanshubapp.s3.amazonaws.com';

export const AWS_S3_REGION = process?.env?.REACT_APP_AWS_REGION;

export const S3_BASE_URL = "https://d3506qpsjjys8u.cloudfront.net/";

