import { apiCall, METHOD } from '../../service/baseApiCall';
import {
  GET_PAID_PACKAGE,
  GET_MEDIA_PACKAGE_BY_ID,
  CREATE_MEDIA_PACKAGE,
  CREATE_PAID_PACKAGE,
  DOCUMENT_VERIFICATION,
  GET_VERIFICATION_STATUS,
  USER_SUBSCRIPTION,
  EDIT_PAID_PACKAGE,
  PAID_PACKAGE_BY_ID,
  REMOVE_MEDIA_FROM_PACKAGE,
  GET_ENV_VARIABLES,
  REMOVE_PACKAGE,
} from '../constants/action-types';
import { Store } from '../Actions';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  GET_MEDIA_CONTENT_URL,
  CREATE_PACKAGE_URL,
  CREATE_MEDIA_PACKAGE_URL,
  DOCUMENT_VERIFICATION_URL,
  GET_VERIFICATION_STATUS_URL,
  USER_SUBSCRIPTION_URL,
  GET_PACKAGE_LIST_URL,
  EDIT_PAID_PACKAGE_URL,
  GET_PACKAGE_URL,
  DELETE_MEDIA_CONTENT_URL,
  SEND_PACKAGE_URL,
  GET_ENV_VARIABLE,
  CANCEL_SUSCRIPTION_URL,
  DELETE_PACKAGE_URL,
  RENEW_SUBSCRIPTION_URL,
} from '../ApiEndPoints';
import { toast } from 'react-toastify';
import { getToken } from '../../utils/utility';

export const documentVerify =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(documentVerifyInit(values));
  };

export const documentVerifyInit =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_INITLIZATION,
    });
    apiCall(
      DOCUMENT_VERIFICATION_URL,
      values,
      (res: any) => {
        dispatch(documentVerifySuccess(res));
      },
      (err: any) => {
        dispatch(documentVerifyError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const documentVerifySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_SUCCESS,
      payload: res.data,
    });
    
    toast.success(res.message);
  };

export const documentVerifyError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_ERORR,
    });
    // toast.error(err.message)
  };
export const getVerificationStatus = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch(getVerificationStatusInit());
};

export const getVerificationStatusInit = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: GET_VERIFICATION_STATUS.GET_VERIFICATION_STATUS_INITLIZATION,
  });
  apiCall(
    GET_VERIFICATION_STATUS_URL,
    {},
    (res: any) => {
      dispatch(getVerificationStatusSuccess(res));
    },
    (err: any) => {
      dispatch(getVerificationStatusError(err));
    },
    METHOD.POST,
    {}
  );
};

export const getVerificationStatusSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_VERIFICATION_STATUS.GET_VERIFICATION_STATUS_SUCCESS,
      payload: res.data,
    });

    // toast.success(res.message)
  };

export const getVerificationStatusError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_VERIFICATION_STATUS.GET_VERIFICATION_STATUS_ERORR,
    });
    // toast.error(err.message)
  };

export const getUserSubscription = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch(getUserSubscriptionInit());
};

export const getUserSubscriptionInit = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_INITLIZATION,
  });
  const user_id = getToken('userId');
  apiCall(
    USER_SUBSCRIPTION_URL,
    { user_id: 299 },
    (res: any) => {
      dispatch(getUserSubscriptionSuccess(res));
    },
    (err: any) => {
      dispatch(getUserSubscriptionError(err));
    },
    METHOD.POST,
    {}
  );
};

export const getUserSubscriptionSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const getUserSubscriptionError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_ERORR,
    });
    // toast.error(err.message)
  };

//Hint: Cancel subscription
export const cancelSubscription =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(cancelSubscriptionInit(data));
  };

export const cancelSubscriptionInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_INITLIZATION,
    });
    apiCall(
      CANCEL_SUSCRIPTION_URL,
      data,
      (res: any) => {
        dispatch(cancelSubscriptionSuccess(res));
      },
      (err: any) => {
        dispatch(cancelSubscriptionError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const cancelSubscriptionSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_SUCCESS,
      payload: res.data,
    });
    //
    toast.success(res.message);
  };

export const cancelSubscriptionError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_ERORR,
    });
    toast.error(err.message);
  };
//Hint: Renew subscription
export const renewSubscription =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(renewSubscriptionInit(data));
  };

export const renewSubscriptionInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_INITLIZATION,
    });
    apiCall(
      RENEW_SUBSCRIPTION_URL,
      data,
      (res: any) => {
        dispatch(renewSubscriptionSuccess(res));
      },
      (err: any) => {
        dispatch(renewSubscriptionError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const renewSubscriptionSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_SUCCESS,
      payload: res.data,
    });
    //
    toast.success(res.message);
  };

export const renewSubscriptionError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_SUBSCRIPTION.USER_SUBSCRIPTION_ERORR,
    });
    toast.error(err.message);
  };
//Hint: Get Paid Package List
export const getPaidPackageList = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch(getPaidPackageListInit());
};

export const getPaidPackageListInit = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: GET_PAID_PACKAGE.GET_PAID_PACKAGE_INITLIZATION,
  });
  apiCall(
    GET_PACKAGE_LIST_URL,
    {},
    (res: any) => {
      dispatch(getPaidPackageListSuccess(res));
    },
    (err: any) => {
      dispatch(getPaidPackageListError(err));
    },
    METHOD.GET,
    {}
  );
};

export const getPaidPackageListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_PAID_PACKAGE.GET_PAID_PACKAGE_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const getPaidPackageListError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_PAID_PACKAGE.GET_PAID_PACKAGE_ERROR,
    });
    // toast.error(err.message)
  };

//Hint: Create Paid Package
export const CreatePaidPackage =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(createPaidPackageInit(values));
  };

export const createPaidPackageInit =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_PAID_PACKAGE.CREATE_PAID_PACKAGE_INITLIZATION,
    });
    apiCall(
      CREATE_PACKAGE_URL,
      values,
      (res: any) => {
        dispatch(createPaidPackageSuccess(res));
      },
      (err: any) => {
        dispatch(createPaidPackageError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const createPaidPackageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_PAID_PACKAGE.CREATE_PAID_PACKAGE_SUCCESS,
      payload: res.data,
    });
    toast.success(res.message);
  };

export const createPaidPackageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_PAID_PACKAGE.CREATE_PAID_PACKAGE_ERORR,
    });
    // toast.error(err.message)
  };

//Hint: Get package by id
export const getPaidPackageById =
  (packageId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getPaidPackageByIdInit(packageId));
  };

export const getPaidPackageByIdInit =
  (packageId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: PAID_PACKAGE_BY_ID.PAID_PACKAGE_BY_ID_INITLIZATION,
    });
    apiCall(
      `${GET_PACKAGE_URL}`,
      { media_id: packageId },
      (res: any) => {
        if (!res?.success) {
          dispatch(getPaidPackageByIdError(res));
          return;
        }
        dispatch(getPaidPackageByIdSuccess(res));
      },
      (err: any) => {
        dispatch(getPaidPackageByIdError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const getPaidPackageByIdSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: PAID_PACKAGE_BY_ID.PAID_PACKAGE_BY_ID_SUCCESS,
      payload: res.data,
    });
  };

export const getPaidPackageByIdError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: PAID_PACKAGE_BY_ID.PAID_PACKAGE_BY_ID_ERORR,
    });
    toast.error(err?.message);
  };

//Edit Package
export const editPaidPackage =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(editPaidPackageInit(values));
  };

export const editPaidPackageInit =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_PAID_PACKAGE.EDIT_PAID_PACKAGE_INITLIZATION,
    });
    apiCall(
      EDIT_PAID_PACKAGE_URL,
      values,
      (res: any) => {
        dispatch(editPaidPackageSuccess(res));
      },
      (err: any) => {
        dispatch(editPaidPackageError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const editPaidPackageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_PAID_PACKAGE.EDIT_PAID_PACKAGE_SUCCESS,
      payload: res.data,
    });
    //
    toast.success(res.message);
  };

export const editPaidPackageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: EDIT_PAID_PACKAGE.EDIT_PAID_PACKAGE_ERORR,
    });
    // toast.error(err.message)
  };

//Hint: Create Media Package
export const CreateMediaPackage =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(createMediaPackageInit(values));
  };

export const createMediaPackageInit =
  (values: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_MEDIA_PACKAGE.CREATE_MEDIA_PACKAGE_INITLIZATION,
    });
    apiCall(
      CREATE_MEDIA_PACKAGE_URL,
      values,
      (res: any) => {
        //getMediaPackageById(values?.media_package_id)
        dispatch(createMediaPackageSuccess(res));
      },
      (err: any) => {
        dispatch(createMediaPackageError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const createMediaPackageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_MEDIA_PACKAGE.CREATE_MEDIA_PACKAGE_SUCCESS,
      payload: res.data,
    });
    //

    toast.success(res.message);
  };

export const createMediaPackageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_MEDIA_PACKAGE.CREATE_MEDIA_PACKAGE_ERORR,
    });

    toast.error(err.message);
  };

//Hint: Get Media package by id
export const getMediaPackageById =
  (packageId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getMediaPackageByIdInit(packageId));
  };

export const getMediaPackageByIdInit =
  (packageId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_MEDIA_PACKAGE_BY_ID.MEDIA_PACKAGE_BY_ID_INITLIZATION,
    });
    apiCall(
      GET_MEDIA_CONTENT_URL,
      { media_package_id: packageId },
      (res: any) => {
        dispatch(getMediaPackageByIdSuccess(res));
      },
      (err: any) => {
        dispatch(getMediaPackageByIdError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const getMediaPackageByIdSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_MEDIA_PACKAGE_BY_ID.MEDIA_PACKAGE_BY_ID_SUCCESS,
      payload: res.data,
    });
  };

export const getMediaPackageByIdError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_MEDIA_PACKAGE_BY_ID.MEDIA_PACKAGE_BY_ID_ERORR,
    });
    // toast.error(err.message)
  };

//Hint: Remove Media from package
export const removeMediaFromPackage =
  (deleteId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(removeMediaFromPackageInit(deleteId));
  };

export const removeMediaFromPackageInit =
  (deleteId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_MEDIA_FROM_PACKAGE.REMOVE_FROM_MEDIA_INITLIZATION,
    });
    apiCall(
      DELETE_MEDIA_CONTENT_URL,
      { media_content_id: deleteId },
      (res: any) => {
        dispatch(removeMediaFromPackageSuccess(res));
      },
      (err: any) => {
        dispatch(removeMediaFromPackageError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const removeMediaFromPackageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_MEDIA_FROM_PACKAGE.REMOVE_FROM_MEDIA_SUCCESS,
      payload: res.data,
    });
  };

export const removeMediaFromPackageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_MEDIA_FROM_PACKAGE.REMOVE_FROM_MEDIA_ERORR,
    });
    // toast.error(err.message)
  };
//Hint: Remove package
export const removePackage =
  (deleteId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(removePackageInit(deleteId));
  };
export const removePackageInit =
  (deleteId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_PACKAGE.REMOVE_PACKAGE_INITLIZATION,
    });
    apiCall(
      DELETE_PACKAGE_URL,
      { package_media_id: deleteId },
      (res: any) => {
        dispatch(removePackageSuccess(res));
      },
      (err: any) => {
        dispatch(removePackageError(err));
      },
      METHOD.POST,
      {}
    );
  };
export const removePackageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_PACKAGE.REMOVE_PACKAGE_SUCCESS,
      payload: res.data,
    });
    toast.success(res.message);
  };

export const removePackageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_PACKAGE.REMOVE_PACKAGE_ERORR,
    });
    // toast.error(err.message)
  };
//Hint: Send Package to multiple users
// export const SendPackageToMultipleUser = (values:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
//   dispatch(SendPackageToMultipleUserInit(values));
// };

// export const SendPackageToMultipleUserInit = (values:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
//   dispatch({
//     type: SEND_PACKAGE_TO_USER.SEND_PACKAGE_TO_USER_INITLIZATION
//   });
//   apiCall(
//     CREATE_PACKAGE_URL,
//     values,
//     (res: any) => {

//       dispatch(SendPackageToMultipleUserSuccess(res))},
//     (err:any) =>{

//       dispatch(SendPackageToMultipleUserError(err))},
//     METHOD.POST,
//     {}
//   );
// };

// export const SendPackageToMultipleUserSuccess = (res:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {

//   dispatch({
//     type:SEND_PACKAGE_TO_USER.SEND_PACKAGE_TO_USER_SUCCESS,
//     payload:res.data
//   });
//   //
//   toast.success(res.message)
// };

// export const SendPackageToMultipleUserError = (err:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
//   dispatch({
//     type:SEND_PACKAGE_TO_USER.SEND_PACKAGE_TO_USER_ERORR
//   });
//   // toast.error(err.message)
// };
//Hint: Get Env Variables from API
export const getEnvVariables = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch(getEnvVariablesInit());
};

export const getEnvVariablesInit = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: GET_ENV_VARIABLES.GET_ENV_VARIABLES_INITLIZATION,
  });
  apiCall(
    GET_ENV_VARIABLE,
    {},
    (res: any) => {
      dispatch(getEnvVariablesSuccess(res));
    },
    (err: any) => {
      dispatch(getEnvVariablesError(err));
    },
    METHOD.GET,
    {}
  );
};

export const getEnvVariablesSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_ENV_VARIABLES.GET_ENV_VARIABLES_SUCCESS,
      payload: res.data,
    });
    //
    // toast.success(res.message)
  };

export const getEnvVariablesError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_ENV_VARIABLES.GET_ENV_VARIABLES_ERROR,
    });
    // toast.error(err.message)
  };
