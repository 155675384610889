/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFollowerList } from '../redux/action';
import { useFormik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { Store, UserData } from '../redux/Actions';
import { AppDispatch } from '../redux';
import add from '../assets/images/icons/Vector.svg';
import leftArrow from '../assets/images/icons/left-arrow.svg';
import VerifiedIcon from '../assets/images/icons/status_icon.svg';
import user from '../assets/images/imgs/img1.png';
import searchimg from '../assets/images/icons/search.svg';
import { createTrue } from 'typescript';
import { SendPaidPackage } from '../redux/action';
import { S3_BASE_URL } from '../service/aws/config';
import Avatar from 'react-avatar';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import { getToken } from '../utils/utility';
export default function SendPackage({
  packageId,
  packageName,
  sendPackageSection,
  setSendPackageSection,
  t,
}: any) {
  const [userId] = useState<any>(getToken('userId'));
  const [page, setPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState<any>();
  const [checkedState, setCheckedState] = useState<any>();
  const [selectAllState, setSelectAllState] = useState<any>(false);
  const [searchValue, setSearchValue] = useState<any>('');
  const [userList, setUserList] = useState<any>('');
  const dispatch = useDispatch<AppDispatch>();
  const { followerList }: UserData = useSelector(
    (state: Store) => state.userDataReducer
  );
  useEffect(() => {
    const data: { userId: String; page: number } = { userId, page };
    dispatch(getFollowerList(data));
  }, []);
  useEffect(() => {
    setSelectedUsers(followerList);
    setCheckedState(new Array(followerList?.length).fill(false));
  }, [followerList]);
  const selectUser = (position: any) => {
    const updatedCheckedState = checkedState.map((item: any, index: any) =>
      index === position ? !item : item
    );
    if (updatedCheckedState.includes(false)) {
      setSelectAllState(false);
    } else {
      setSelectAllState(true);
    }
    setCheckedState(updatedCheckedState);

    const usersToSendPackage = updatedCheckedState.reduce(
      (sum: any, currentState: any, index: any) => {
        if (currentState === true) {
          sum.push(selectedUsers[index].follower_details?.node_chate_id);
        }
        return sum;
      },
      []
    );
    setUserList(usersToSendPackage);
    // setTotal(totalPrice);
  };
  const handleSendPackage = async () => {
    const data: { package_id: any; user_list: any; package_title: any } = {
      package_id: packageId,
      user_list: userList,
      package_title: packageName,
    };
    dispatch(SendPaidPackage(data));
    setSelectAllState(false);
    const newDeselectSendPackegeAllUser = checkedState.map((elem: any) => {
      return false;
    });
    setCheckedState(newDeselectSendPackegeAllUser);
    setSendPackageSection(false);
  };
  const handleSelectAll = () => {
    if (selectAllState) {
      const newDeselectSendPackegeAllUser = checkedState.map((elem: any) => {
        return false;
      });
      setCheckedState(newDeselectSendPackegeAllUser);
      const deselectAllUsersToSendPackage =
        newDeselectSendPackegeAllUser.reduce(
          (sum: any, currentState: any, index: any) => {
            if (currentState === true) {
              sum.push(selectedUsers[index].follower_details?.node_chate_id);
            }
            return sum;
          },
          []
        );
      setUserList(deselectAllUsersToSendPackage);
    } else {
      const newSelectSendPackegeAllUser = checkedState.map((elem: any) => {
        return true;
      });
      setCheckedState(newSelectSendPackegeAllUser);
      const selectAllUsersToSendPackage = newSelectSendPackegeAllUser.reduce(
        (sum: any, currentState: any, index: any) => {
          if (currentState === true) {
            sum.push(selectedUsers[index].follower_details?.node_chate_id);
          }
          return sum;
        },
        []
      );
      setUserList(selectAllUsersToSendPackage);
    }
    setSelectAllState(!selectAllState);
  };
  return (
    <Modal
      show={sendPackageSection}
      onHide={() => {
        setSendPackageSection(false);
        setSelectAllState(false);
        const newDeselectSendPackegeAllUser = checkedState.map((elem: any) => {
          return false;
        });
        setCheckedState(newDeselectSendPackegeAllUser);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="share-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('paidpackage.sendpackage')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="share-popup-content">
          <div className="search-input">
            <img src={searchimg} alt="searchimg" />
            <input
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t('paidpackage.searchhere')}
            />
          </div>
          {selectedUsers?.length > 0 && (
            <div className="main-select-all">
              <div className="select-all" onClick={handleSelectAll}>
                {selectAllState
                  ? t('paidpackage.deselectall')
                  : t('paidpackage.selectall')}
              </div>
            </div>
          )}
          <div className="user-listing">
            {selectedUsers?.length > 0
              ? selectedUsers
                  ?.filter((elem: any) =>
                    `${elem?.follower_details?.first_name}${elem?.follower_details?.last_name} `
                      ?.toLowerCase()
                      .includes(searchValue.toLowerCase())
                  )
                  ?.map((data: any, index: any) => {
                    return (
                      <div
                        className="user-details"
                        // onClick={() => handlePackageDetail(data)}
                        key={data.id}
                      >
                        <div className="left-section">
                          <Avatar
                            className="avtar"
                            round={true}
                            src={
                              S3_BASE_URL +
                              data?.follower_details?.profile_photo
                            }
                            alt="profile"
                            size="32"
                            name={
                              data?.follower_details?.first_name +
                              ' ' +
                              data?.follower_details?.last_name
                            }
                            maxInitials={2}
                          />
                          {/* <img
                      src={
                        data?.follower_details?.profile_photo
                          ? S3_BASE_URL + data?.follower_details?.profile_photo
                          : user
                      }
                      alt="user"
                    /> */}
                          <h6 style={{ paddingLeft: '10px' }}>
                            {data?.follower_details?.first_name}&nbsp;
                            {data?.follower_details?.last_name}
                            {data?.follower_details?.user_verified ? (
                              <VerifyIcon
                                className="mx-1"
                                height={'11'}
                                width={'11'}
                              />
                            ) : (
                              ''
                            )}
                          </h6>
                        </div>
                        <div className="right-section">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={data?.follower_details?.id}
                            value={data?.follower_details?.id}
                            checked={checkedState[index]}
                            onClick={() => selectUser(index)}
                          />
                          {/* <label htmlFor={`custom-checkbox-${index}`}>
                      {data?.follower_details?.id}
                    </label> */}
                        </div>
                      </div>
                    );
                  })
              : <p className='text-center mt-5' style={{fontSize:"20px"}}>You don't have a followers</p>}
          </div>
          <div className="send-btn">
            <Button onClick={handleSendPackage}>{t('paidpackage.send')}</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
