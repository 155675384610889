import React, { useState } from 'react';
import UserImage from '../assets/images/imgs/user-round.svg';
import ImageIcon from '../assets/images/icons/ico_img.svg';
import VideoIcon from '../assets/images/icons/ico_video.svg';
import AudioIcon from '../assets/images/icons/ico_audio.svg';
import PollIcon from '../assets/images/icons/Vector_poll.svg';
import VideoPost from './models/VideoPost';
import AudioPost from './models/AudioPost';
import ImagePost from './models/ImagePost';
import TextPost from './models/TextModel';
import { useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
import { S3_BASE_URL } from '../service/aws/config';
import { createImageFromInitials, getRandomColor } from '../utils/createImage';
import { useTranslation } from 'react-i18next';
import PollPost from './models/PollPost';

export default function CreatePost() {
  const { t } = useTranslation();
  const [showImagePostModel, setShowImagePostModel] = useState(false);
  const [showVideoPostModel, setShowVideoPostModel] = useState(false);
  const [showAudionModel, setShowAudioPostModel] = useState(false);
  const [showPollPostModal, setShowPollPostModal] = useState(false);
  const [showTextModel, setShowTextPostModel] = useState(false);

  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );

  return (
    <>
      <div className="box-container">
        <div className="user-write d-flex align-items-center">
          <div className="user-logo">
            {(userDetail?.profile_photo || userDetail?.first_name) && (
              <img
                // src={
                //   userDetail?.profile_photo
                //     ? S3_BASE_URL + userDetail?.profile_photo
                //     : UserImage
                // }
                src={
                  userDetail?.profile_photo
                    ? S3_BASE_URL + userDetail?.profile_photo
                    : createImageFromInitials(
                        500,
                        userDetail?.first_name + ' ' + userDetail?.last_name,
                        getRandomColor()
                      )
                }
                alt="user"
                className="img-fluid"
                style={{
                  height: '41px',
                  width: '100%',
                  boxSizing: 'content-box',
                  objectFit: 'cover',
                }}
              />
            )}
            {/* eight: 39px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    box-sizing: content-box; */}
          </div>
          <div className="user-type" onClick={() => setShowTextPostModel(true)}>
            <input
              type="text"
              className="form-control"
              placeholder={`${t('home.write something')}`}
            />
          </div>
        </div>
        <div className="clip-button d-flex align-items-center justify-content-end">
          <button
            className="btn btn-clip ms-0"
            onClick={() => {
              setShowImagePostModel(true);
            }}
          >
            <img src={ImageIcon} alt="icon" className="img-fluid" />
            <span>{t('home.photo')}</span>
          </button>
          <button
            className="btn btn-clip"
            onClick={() => {
              setShowVideoPostModel(true);
            }}
          >
            <img src={VideoIcon} alt="icon" className="img-fluid" />
            <span>{t('home.video')}</span>
          </button>
          <button
            className="btn btn-clip me-0"
            onClick={() => {
              setShowPollPostModal(true);
            }}
          >
            <img src={PollIcon} alt="audio" className="img-fluid" />
            <span>Poll</span>
          </button>
          {/* <button
            className="btn btn-clip me-0"
            onClick={() => {
              setShowAudioPostModel(true);
            }}
          >
            <img src={AudioIcon} alt="audio" className="img-fluid" />
            <span>{t('home.audio')}</span>
          </button> */}
        </div>
      </div>
      <VideoPost
        showPostComment={showVideoPostModel}
        setShowPostComment={setShowVideoPostModel}
      />
      <AudioPost
        showPostComment={showAudionModel}
        setShowPostComment={setShowAudioPostModel}
      />
      <ImagePost
        showPostComment={showImagePostModel}
        setShowPostComment={setShowImagePostModel}
      />
      <TextPost
        showPostComment={showTextModel}
        setShowPostComment={setShowTextPostModel}
      />
      <PollPost
        showPollPostModal={showPollPostModal}
        setShowPollPostModal={setShowPollPostModal}
      />
    </>
  );
}
