import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, NotificationData } from '../redux/Actions';
import { notification, userDetail, userProfileData } from '../redux/action';
import { dispatch } from '../redux';
import { NOTIFICTION_TYPE } from '../constants/App-constant';
import LikePost from '../assets/images/imgs/new_like.png';
import PostComment from '../assets/images/imgs/new_comment.png';
import StartFollow from '../assets/images/imgs/new_follow.png';
import NewStory from '../assets/images/imgs/new_story.png';
import NewTips from '../assets/images/imgs/new_tipss.png';
import ReceivedCoin from '../assets/images/imgs/new_tipss.png';
import UpdateWallate from '../assets/images/imgs/package_buy.png';
import TransferCoin from '../assets/images/imgs/debit_coin.png';
import NewSubscription from '../assets/images/imgs/new_subs.png';
import DocumentApproved from '../assets/images/imgs/doc_approve.png';
import DocumentReject from '../assets/images/imgs/doc_rejected.png';
import NewLiveStream from '../assets/images/imgs/new_live.png';
import NotificationImage from '../assets/images/imgs/ic_notification_icon.png';
import { getToken } from '../utils/utility';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function Notification() {
  const {
    notificationList,
    loading,
    hasMoreFeedNotification,
  }: NotificationData = useSelector(
    (state: Store) => state.notificationReducer
  );
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  useEffect(() => {
    //@ts-ignore
    dispatch(notification({ page }));
    const userId = getToken('userId');
    dispatch(userDetail(Number(userId)));
    dispatch(userProfileData(Number(userId)));
  }, []);
  useEffect(() => {
    dispatch(notification({ page }));
  }, [page]);
  useEffect(() => {
    if(page == 1){
      window.scrollTo(0, 0);
    }
  }, [page]);
  return (
    <div className="center-box">
      <div className="box-container notification-container">
        <h5 className="title text-center">{t("sidebar.notifications")}</h5>
        <div className="notification-content">
          <InfiniteScroll
            dataLength={notificationList?.length ? notificationList?.length : 0} //This is important field to render the next data
            next={() => {
              // setPage(page + 1);
              setPage(page + 1);
            }}
            hasMore={
              hasMoreFeedNotification
              // userFeedListData && userFeedListData.length > 0 ? true : false
            }
            loader={loading}
            endMessage={
              <p style={{ textAlign: 'center' }} className="mt-5">
                <b>{t('home.yah! you have seen it all')}</b>
              </p>
            }
          >
            <ul className="notification-lists notification-lists-scoll ps-0 mb-0">
              {/* <li className="notification-item">
            <a href="#" className="notification-link">
              <div className="notification-logo"><img src={UserNotificationImage} className="img-fluid"/></div>
              <div className="notification-comment">
                <p className="mb-0"><span>Jitendrasingh Jadeja</span>
                <span className="text-blue"> commented on post</span></p>
                <small>2 hours ago</small>
              </div>
            </a>
          </li> */}
              {notificationList &&
                notificationList?.map((elem: any, index: number) => {
                  var imagesPath: any;
                  if (
                    elem.type == NOTIFICTION_TYPE.NOTIFICATION_TYPE_LIKE_POST
                  ) {
                    imagesPath = LikePost;
                  } else if (
                    elem.type == NOTIFICTION_TYPE.NOTIFICATION_TYPE_POST_COMMENT
                  ) {
                    imagesPath = PostComment;
                  } else if (
                    elem.type == NOTIFICTION_TYPE.NOTIFICATION_TYPE_NEW_TIPS
                  ) {
                    imagesPath = NewTips;
                  } else if (
                    elem.type ==
                      NOTIFICTION_TYPE.NOTIFICATION_TYPE_RECEIVED_COIN ||
                    elem.type ==
                      NOTIFICTION_TYPE.NOTIFICATION_TYPE_WALLET_UPDATED
                  ) {
                    imagesPath = ReceivedCoin;
                  } else if (
                    elem.type ==
                    NOTIFICTION_TYPE.NOTIFICATION_TYPE_UPDATE_WALLET
                  ) {
                    imagesPath = UpdateWallate;
                  } else if (
                    elem.type ==
                    NOTIFICTION_TYPE.NOTIFICATION_TYPE_TRANSFER_COIN
                  ) {
                    imagesPath = TransferCoin;
                  } else if (
                    elem.type ==
                    NOTIFICTION_TYPE.NOTIFICATION_TYPE_NEW_LIVESTREAM
                  ) {
                    imagesPath = NewLiveStream;
                  } else if (
                    elem.type == NOTIFICTION_TYPE.NOTIFICATION_TYPE_NEW_STORY
                  ) {
                    imagesPath = NewStory;
                  } else if (
                    elem.type == NOTIFICTION_TYPE.NOTIFICATION_TYPE_START_FOLLOW
                  ) {
                    imagesPath = StartFollow;
                  } else if (
                    elem.type == NOTIFICTION_TYPE.NOTIFICATION_TYPE_NEW_SUBS
                  ) {
                    imagesPath = NewSubscription;
                  } else if (
                    elem.type == NOTIFICTION_TYPE.NOTIFICATION_TYPE_DOC_APPROVED
                  ) {
                    imagesPath = DocumentApproved;
                  } else if (
                    elem.type == NOTIFICTION_TYPE.NOTIFICATION_TYPE_DOC_REJECTED
                  ) {
                    imagesPath = DocumentReject;
                  } else {
                    imagesPath = NotificationImage;
                  }
                  return (
                    <Fragment key={index}>
                      <li className="notification-item">
                        <a href="#" className="notification-link">
                          <div className="notification-logo">
                            <img src={imagesPath} className="img-fluid" />
                          </div>
                          <div className="notification-comment">
                            <p className="mb-0">
                              <span>{elem?.description}</span>
                              {/* <span className="text-blue">Test</span> */}
                            </p>
                            <small>{moment(elem?.created_at).fromNow()}</small>
                          </div>
                        </a>
                      </li>
                    </Fragment>
                  );
                })}
            </ul>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
