/* eslint-disable import/no-anonymous-default-export */
import { MY_SUBSCRIBER } from "../constants/action-types";
import { MysubscriberData } from "../Actions";
import { ActionTypes } from "../action/userActions";

const initialState = {
  loading: false,
  postData: [],
};

export default (state: MysubscriberData = initialState, action: ActionTypes) => {
  switch (action.type) {
    case MY_SUBSCRIBER.MY_SUBSCRIBER_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case MY_SUBSCRIBER.MY_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        postData: action.payload,
      };
    case MY_SUBSCRIBER.MY_SUBSCRIBER_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};