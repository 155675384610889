// import { Upload } from "@aws-sdk/lib-storage";
// import { S3Client, S3 } from "@aws-sdk/client-s3";
import { toast } from 'react-toastify';
import {
  // AMAZON_S3_USER_VIDEO_BUCKET,
  AMAZON_S3_USER_IMAGE_BUCKET,
  AWS_S3_ACCESS_ID,
  AWS_S3_SECRET_KEY,
  AWS_S3_REGION,
  AWS_S3_URL,
} from './config';
import S3 from 'react-aws-s3';
// AWS.config.update({
//   region: AWS_S3_REGION,
//   credentials: new AWS.Credentials(AWS_S3_ACCESS_ID, AWS_S3_SECRET_KEY),
//   // accessKeyId: AWS_S3_ACCESS_ID, //'YOUR_ACCESS_KEY_HERE',
//   // secretAccessKey: AWS_S3_SECRET_KEY, // 'YOUR_SECRET_ACCESS_KEY_HERE'
// });
const config = {
  bucketName: AMAZON_S3_USER_IMAGE_BUCKET,
  region: AWS_S3_REGION,
  accessKeyId: AWS_S3_ACCESS_ID,
  secretAccessKey: AWS_S3_SECRET_KEY,
  s3Url: AWS_S3_URL,
};

const ReactS3Client = new S3(config);
// let s3 = new AWS.S3({
//   // apiVersion: "2006-03-01",
//   params: { Bucket: AMAZON_S3_USER_IMAGE_BUCKET },
// });
const FILE_PATH_POST_IMAGE = 'post/images';
const FILE_PATH_STORY_IMAGE = 'story/images';
const FILE_PATH_POST_THUMB = 'post/thumbs';
const FILE_PATH_POST_AUDIO = 'post/audios';
const FILE_PATH_POST_VIDEO = 'post/videos';
const FILE_PATH_POST_TEASER = 'post/teasers';
const FILE_PATH_PROFILE_PICS = 'user/profile';
export const uploadFileAmazonS3 = async (file: any, path?: string) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  function generateString(length: number) {
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  const keyName = `${path}${generateString(10).trim()}${file.name}`;

  // const result = ReactS3Client
  //   .upload(file, keyName/*{
  //     Key: keyName,
  //     Body: file,
  //     Bucket: AMAZON_S3_USER_IMAGE_BUCKET,
  //     ACL: "public-read",
  //   }*/)
  //   .promise();
  // let uploadObj = { Key: '', Location: '' };
  try{

    const result = ReactS3Client.uploadFile(file, `${keyName}`)
    .then((data: any) => {
      // uploadObj.Key = data.key;
      // uploadObj.Location = data.location;
      return { Key: data.key, Location: data.location };
    })
    .catch((err: any) => {
      console.error(err);
      toast.error(err);
    }); 
    return result;
  }catch(e){
    toast.error("Something when wrong ! Please try again")
  }
};
export const uploadThumbnailFileAmazonS3 = async (file: any) => {
  const result = ReactS3Client.upload(
    file,
    file.name /*{
      Key: keyName,
      Body: file,
      Bucket: AMAZON_S3_USER_IMAGE_BUCKET,
      ACL: "public-read",
    }*/
  ).promise();
  let data;
  data = await result.then((r: any) => {
    data = r;
  });
  return result;
};
