import user from '../assets/images/imgs/user1.svg';
import { useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
// import { mySubScribers } from "../redux/action";
import { dispatch } from '../redux';
import { useEffect } from 'react';
import { mySubScribers } from '../redux/action';
import moment from 'moment';
import { S3_BASE_URL } from '../service/aws/config';
import Avatar from 'react-avatar';
import { ReactComponent as Coin } from '../assets/images/icons/coin.svg';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import { useTranslation } from 'react-i18next';

export default function Subscriber() {
  const { t } = useTranslation();
  const subscriberList = useSelector(
    //@ts-ignore
    (state: Store) => state?.mysubscriberReducer?.postData
  );

  useEffect(() => {
    //@ts-ignore
    dispatch(mySubScribers());
  }, []);

  return (
    <>
      <h2 className="accordion-header d-lg-none" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#subscriptions"
          aria-expanded="false"
          aria-controls="subscriptions"
        >
          My Subscribers
        </button>
      </h2>
      <div
        id="subscriptions"
        className="accordion-collapse collapse d-lg-block subscriber-page"
        aria-labelledby="headingThree"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            <h2 className="setting-title">{t('subscribers.mysubscribers')}</h2>
            {subscriberList &&
              subscriberList?.map((item: any) => {
                return (
                  <div className="subscribe-box active-plan" key={item.id}>
                    <label className="plan-label">
                      {item?.package_type === 0
                        ? item?.user?.next_package?.package_type === 2
                          ? t('subscribers.annual')
                          : item?.user?.next_package?.package_type === 1 &&
                            t('subscribers.monthly')
                        : item?.package_type === 2
                        ? t('subscribers.annual')
                        : item?.package_type === 1 && t('subscribers.monthly')}
                    </label>

                    {/* {item?.package_type !== 0 ? (
                    <label className="plan-label">
                      {item?.package_type === 2
                        ? t("subscribers.annual")
                        : item?.package_type === 1 && t("subscribers.monthly")}
                    </label>
                  ) : (
                    item?.user?.next_package && (
                      <label className="plan-label">
                        {item?.user?.next_package?.package_type === 2
                          ? t("subscribers.annual")
                          : item?.user?.next_package?.package_type === 1 &&
                            t("subscribers.monthly")}
                      </label>
                    )
                  )} */}
                    <div className="post-user post-user-control d-flex align-items-center px-0">
                      <div
                        className="post-user-logo post-user-logo-sm"
                        style={{ overflow: 'hidden' }}
                      >
                        {/* {item?.user?.profile_photo ? (
                        <img
                          src={S3_BASE_URL + item?.user?.profile_photo}
                          className="img-fluid"
                          alt="user"
                        />
                      ) : ( */}
                        <Avatar
                          src={S3_BASE_URL + item?.user?.profile_photo}
                          className="img-fluid"
                          alt="user"
                          size="70"
                          round="true"
                          name={
                            item?.user?.first_name + ' ' + item?.user?.last_name
                          }
                        />
                        {/* )} */}
                      </div>
                      <div className="col">
                        <h5 className="post-user-name justify-content-start">
                          {item?.user?.first_name + ' ' + item?.user?.last_name}
                          {item?.user?.user_verified && (
                            <VerifyIcon className="mx-1" />
                          )}
                        </h5>
                        <p className="text-msg user-comment">
                          @{item?.user?.username}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end flex-wrap ">
                      <Coin className="mr-2 mt-1" />{' '}
                      <p className="sub-amount">
                        {item?.package_type === 0
                          ? item?.user?.next_package?.price
                          : item?.price}
                      </p>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      {item?.user?.next_package ? (
                        <>
                          <label className="dayslabel">
                            {item?.remaining_days +
                              item?.user?.next_package?.remaining_days}
                            {t('subscribers.days remaining')}
                          </label>
                        </>
                      ) : (
                        <>
                          <label className="dayslabel">
                            {item?.remaining_days}
                            &nbsp;{t('subscribers.days remaining')}
                          </label>
                        </>
                      )}
                      <p>
                        {t('subscribers.start date')}:{' '}
                        {moment(item?.start_date).format('DD-MM-YY')}
                      </p>
                    </div>
                    {item?.package_type === 0 && (
                      <div className="mt-3">
                        <p className="trial-text">
                          Free Trial ends in {item?.remaining_days} Days
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}

            {/* <div className="subscribe-box trial-plan">
              <label className="plan-label">Monthly</label>
              <div className="post-user post-user-control d-flex align-items-center px-0">
                <div className="post-user-logo post-user-logo-sm">
                  <img src={user} className="img-fluid" alt="user" />
                </div>
                <div className="col">
                  <h5 className="post-user-name justify-content-start">
                    Ruby Baker
                  </h5>
                  <p className="text-msg user-comment">@karen</p>
                </div>
              </div>
              <div className="d-flex justify-content-end flex-wrap align-items-center">
                <p className="sub-amount">98.99</p>
              </div>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <label className="dayslabel">156 Days Remaining</label>
                <p>Plan start date: 15 June 2021</p>
              </div>
              <p className="trial-text">Free Trial ends in 2 Days</p>
            </div>
            <div className="subscribe-box past-subscribe-box disabled-box">
              <label className="plan-label">Monthly</label>
              <div className="post-user post-user-control d-flex align-items-center px-0">
                <div className="post-user-logo post-user-logo-sm">
                  <img src={user} className="img-fluid" alt="user" />
                </div>
                <div className="col">
                  <h5 className="post-user-name justify-content-start">
                    Ruby Baker
                  </h5>
                  <p className="text-msg user-comment">@karen</p>
                </div>
              </div>
              <div className="d-flex justify-content-end flex-wrap align-items-center">
                <p className="sub-amount">98.99</p>
              </div>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <label className="dayslabel">00 Days Remaining</label>
                <p>Plan start date: 15 June 2021</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
