import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from '../redux';
import { getToken } from '../utils/utility';
import { getHashTagPost } from '../redux/action';
import { useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
import PostFeed from '../components/PostFeed';

const HashTags = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoggedIn = getToken('authToken') ? true : false;
  const [page, setPage] = useState(1);
  const hashTagFeedList: any = useSelector(
    (state: Store) => state.userDataReducer.hashTagFeedList
  );
  const { tag } = useParams();
  if (isLoggedIn === false) {
    navigate('/login');
  }
  useEffect(() => {
    if (page == 1) {
      window.scrollTo(0, 0);
    }
    dispatch(getHashTagPost({ page: page, tag: tag }));
  }, [page]);
  return (
    <div className="center-box">
      <PostFeed
        setPage={setPage}
        page={page}
        feedList={hashTagFeedList}
        userFeedListData={hashTagFeedList}
        fromPage={'favourite'}
        onClick={() => console.log('')}
      />
    </div>
  );
};
export default HashTags;
