import React from "react";
import Routes from "./routes";
import * as dotenv from "dotenv";
import RoutesElement from "./routes";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import "video-react/dist/video-react.css";
dotenv.config();
function App () {
  return <RoutesElement></RoutesElement>
}

export default App;
