import { chatApiCall, apiCall, METHOD } from '../../service/baseApiCall';
import { CHAT, CHANGE_LOCAL } from '../constants/action-types';
import { Store, UserData } from '../Actions';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  GET_ROOM_LIST_URL,
  GET_ROOM_DETAIL_URL,
  GET_ROOM_CHAT_HISTORY_URL,
  SEND_MESSAGE_URL,
  UPLOAD_FILE_URL,
  SEND_PACKAGE_URL,
  UNLOCK_PACKAGE_URL,
  ADD_NEW_USER_CONVERSTION,
  GET_STICKERS,
  POST_STICKERS,
} from '../ApiEndPoints';
import { toast } from 'react-toastify';
import { getToken, valiadteToken } from '../../utils/utility';
import { io } from 'socket.io-client';
import socket from '../../utils/socket';

const token = getToken('jwtToken') ? getToken('jwtToken') : null;

export const socketConnection =
  (data: String): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(socketConnectionInit(data));
  };

const socketConnectionInit =
  (data: String): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(socketConnectionSuccess('connected'));
  };

const socketConnectionSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SOCKET_CONNECTION_SUCCESS,
      payload: res,
    });
  };
const socketConnectionError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SOCKET_CONNECTION_ERROR,
    });
    toast.error(err.message);
  };

export const FetchRecentChatUserList =
  (data: String): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(FetchRecentChatUserListInit(data));
  };

const FetchRecentChatUserListInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    // dispatch({
    //   type: CHAT.FETCH_CHAT_USERLIST_INITLIZATION,
    // });
    chatApiCall(
      GET_ROOM_LIST_URL,
      data,
      (res: any) => {
        dispatch(FetchRecentChatUserListSuccess({ res }));
      },

      (err: any) => {
        dispatch(FetchRecentChatUserListError(err));
      },
      METHOD.POST,
      {}
    );
  };

const FetchRecentChatUserListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.USER_LIST_SUCCESS,
      payload: res.res.rooms,
    });
    // toast.success(res.message)
  };
const FetchRecentChatUserListError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.USER_LIST_ERROR,
    });
    toast.error(err.message);
  };

//Hint: Add User for conversation
export const AddUserToConversation =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(AddUserInit(data));
  };

const AddUserInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.FETCH_CHAT_USERLIST_INITLIZATION,
    });
    chatApiCall(
      ADD_NEW_USER_CONVERSTION,
      data,
      (res: any) => {
        dispatch(AddUserSuccess({ res }));
      },

      (err: any) => {
        dispatch(AddUserError(err));
      },
      METHOD.POST,
      {}
    );
  };

const AddUserSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.ADD_NEW_USER_SUCCESS,
      payload: res.res.room,
    });
  };
const AddUserError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_MESSAGE_ERROR,
    });
    toast.error(err.message);
  };

export const FetchRoomDetail =
  (data: String): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(FetchRoomDetailInit(data));
  };

const FetchRoomDetailInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.FETCH_CHAT_USERLIST_INITLIZATION,
    });
    chatApiCall(
      GET_ROOM_DETAIL_URL,
      { room: data },
      (res: any) => {
        dispatch(FetchRoomDetailSuccess({ res }));
      },
      (err: any) => {
        dispatch(FetchRoomDetailError(err));
      },
      METHOD.POST,
      {}
    );
  };

const FetchRoomDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.ROOM_DETAIL_SUCCESS,
      payload: res.res.room,
    });
    // toast.success(res.message)
  };
const FetchRoomDetailError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.ROOM_DETAIL_ERROR,
    });
    toast.error(err.message);
  };

//Hint: Room chat history
export const FetchRoomChatHistory =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(FetchRoomChatHistoryInit(data));
  };

const FetchRoomChatHistoryInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.FETCH_CHAT_HISTORY_INITLIZATION,
    });
    chatApiCall(
      GET_ROOM_CHAT_HISTORY_URL,
      { room: data?.room },
      (res: any) => {
        dispatch(FetchRoomChatHistorySuccess({ res }));
      },
      (err: any) => {
        dispatch(FetchRoomChatHistoryError(err));
      },
      METHOD.POST,
      {}
    );
  };

const FetchRoomChatHistorySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.ROOM_CHAT_HISTORY_SUCCESS,
      payload: res.res.messages,
    });
    // toast.success(res.message)
  };
const FetchRoomChatHistoryError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.ROOM_CHAT_HISTORY_ERROR,
    });
    toast.error(err.message);
  };

//Hint: Send Chat message
export const SendMessage =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(SendMessageInit(data));
  };

const SendMessageInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_MESSAGE_INITLIZATION,
    });
    chatApiCall(
      SEND_MESSAGE_URL,
      data,
      (res: any) => {
        dispatch(SendMessageSuccess({ res }));
      },
      (err: any) => {
        dispatch(SendMessageError(err));
      },
      METHOD.POST,
      {}
    );
  };

const SendMessageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_MESSAGE_SUCCESS,
      payload: res,
    });
    // toast.success(res.message)
  };
const SendMessageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_MESSAGE_ERROR,
    });
    toast.error(err.message);
  };

//Hint: Send Paid Package
export const SendPackage =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(SendPackageInit(data));
  };

const SendPackageInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_PACKAGE_INITLIZATION,
    });
    chatApiCall(
      SEND_PACKAGE_URL,
      data,
      (res: any) => {
        dispatch(SendPackageSuccess({ res }));
      },
      (err: any) => {
        dispatch(SendPackageError(err));
      },
      METHOD.POST,
      {}
    );
  };

const SendPackageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_PACKAGE_SUCCESS,
      payload: res,
    });
    // toast.success(res.message)
  };
const SendPackageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_PACKAGE_ERROR,
    });
    toast.error(err.message);
  };
//Hint: Upload File
export const UploadFile =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(UploadFileInit(data));
  };

const UploadFileInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.UPLOAD_FILE_INITLIZATION,
    });
    chatApiCall(
      UPLOAD_FILE_URL,
      data,
      (res: any) => {
        dispatch(UploadFileSuccess({ res }));
      },
      (err: any) => {
        dispatch(UploadFileError(err));
      },
      METHOD.POST,
      {}
    );
  };

const UploadFileSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.ATTACH_TO_MESSAGE,
      payload: res.res,
    });
    // toast.success(res.message)
  };
const UploadFileError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.UPLOAD_FILE_ERROR,
    });
    toast.error(err.message);
  };

//Hint: Send Paid Package to multiple user
export const SendPaidPackage =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(SendPaidPackageInit(data));
  };

const SendPaidPackageInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_PAID_PACKAGE_INITLIZATION,
    });
    chatApiCall(
      SEND_PACKAGE_URL,
      data,
      (res: any) => {
        dispatch(SendPaidPackageSuccess({ res }));
      },
      (err: any) => {
        dispatch(SendPaidPackageError(err));
      },
      METHOD.POST,
      {}
    );
  };

const SendPaidPackageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_PAID_PACKAGE_SUCCESS,
      payload: res,
    });
    toast.success('Package send successfully');
  };
const SendPaidPackageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.SEND_PAID_PACKAGE_ERROR,
    });
    toast.error(err.message);
  };

//Hint: Unlock Paid Package
export const UnlockPaidPackage =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(UnlockPaidPackageInit(data));
  };

const UnlockPaidPackageInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.UNLOCK_PAID_PACKAGE_INITLIZATION,
    });
    apiCall(
      UNLOCK_PACKAGE_URL,
      data,
      (res: any) => {
        dispatch(UnlockPaidPackageSuccess({ res }));
      },
      (err: any) => {
        dispatch(UnlockPaidPackageError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const UnlockPaidPackageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.UNLOCK_PAID_PACKAGE_SUCCESS,
      payload: res?.res?.success,
    });
    if (res?.res?.success) {
      toast.success('Content unLocked');
    } else {
      toast.error(res?.res?.message);
    }
    return res?.res?.success;
  };
const UnlockPaidPackageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.UNLOCK_PAID_PACKAGE_ERROR,
    });
    toast.error(err.message);
  };

//Hint: Receive New Message
export const ReceiveNewMessageHistory =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(ReceiveNewMessageInit(data));
  };

const ReceiveNewMessageInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.FETCH_NEW_MESSAGE_INITLIZATION,
    });
    dispatch(ReceiveNewMessageSuccess(data));
  };

const ReceiveNewMessageSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.FETCH_NEW_MESSAGE_SUCCESS,
      payload: res,
    });
    // toast.success(res.message)
  };
const ReceiveNewMessageError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.FETCH_NEW_MESSAGE_ERROR,
    });
    toast.error(err.message);
  };

// Hint get sticker
export const getStickers = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch(getStickersInit());
};

export const getStickersInit = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: CHAT.STICKER_INIT,
  });
  apiCall(
    GET_STICKERS,
    {},
    (res: any) => {
      dispatch(getStickersSuccess(res));
    },
    (err: any) => {
      dispatch(getStickersError(err));
    },
    METHOD.GET,
    {}
  );
};

export const getStickersSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    console.log(res, 'StickerRes');
    dispatch({
      type: CHAT.STICKER_LIST_SUCCESS,
      payload: res,
    });
  };

export const getStickersError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.STICKER_LIST_ERROR,
    });
    toast.error(err.message);
  };

// Hint send sticker
export const sendStickers =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(sendStickersInit(data));
  };

export const sendStickersInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.POST_STICKER_INIT,
    });
    apiCall(
      POST_STICKERS,
      data,
      (res: any) => {
        dispatch(postStickersSuccess(res));
      },
      (err: any) => {
        dispatch(postStickersError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const postStickersSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    if (res.success) {
      dispatch({
        type: CHAT.POST_STICKER_LIST_SUCCESS,
        payload: res,
      });
      // toast.success(res.message);
    } else {
      dispatch({
        type: CHAT.POST_STICKER_LIST_ERROR,
      });
      toast.error(res.message);
    }
  };

export const postStickersError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CHAT.POST_STICKER_LIST_ERROR,
    });
    toast.error(err.message);
  };
