/* eslint-disable import/no-anonymous-default-export */
import { PLAN, EDIT_PLAN } from "../constants/action-types";
import { PlanData } from "../Actions";
import { ActionTypes } from "../action/userActions";

const initialState = {
  loading: false,
  packageList:[],
  isEditPlanSuccess: false
};
export default (state: PlanData = initialState, action: ActionTypes) => {
  switch (action.type) {
                case PLAN.PACKAGE_LIST_INITLIZATION:
                  return {
                    ...state,
                    ...action.payload,
                    loading: true
                  };
                case PLAN.PACKAGE_LIST_SUCCESS:                           
                  return {
                    ...state,
                    packageList:action.payload,
                    loading:false
                    
                  };
                case PLAN.PACKAGE_LIST_ERORR:
                  return {
                    ...state,
                    ...action.payload,
                    loading: false
                  };  

                  case EDIT_PLAN.EDIT_PLAN_INITLIZATION:
                    return {
                      ...state,
                      ...action.payload,
                      loading: true
                    };
                  case EDIT_PLAN.EDIT_PLAN_SUCCESS:
                   
                    return {
                      ...state,
                      ...action.payload,
                      isEditPlanSuccess:true,
                      loading: false
                    };
                  case EDIT_PLAN.EDIT_PLAN_ERORR:
                    return {
                      ...state,
                      ...action.payload,
                      isEditPlanSuccess: false,  
                      loading: false
                    };

                
                 default:
      return state;
  }
};