import React, { useState, useEffect } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { AppDispatch } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { userDashboardFeed, userDetail } from "../redux/action";
import { getToken } from "../utils/utility";
import { useNavigate } from "react-router-dom";
import { Store } from "../redux/Actions";
import people from "../assets/images/imgs/user2.svg";
import PostFeed from "../components/PostFeed";
import hasimg from "../assets/images/icons/has.svg";

const ExploreList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [feedList, setFeedList] = useState<any>([]);
  const isLoggedIn = getToken("authToken") ? true : false;

  if (isLoggedIn === false) {
    navigate("/login");
  }

  useEffect(() => {
    const userId = getToken("userId");
    dispatch(userDetail(Number(userId)));
  }, []);
  const userFeedListData: any = useSelector((state: Store) => state.userDataReducer.userDashboardFeedList);
  const { isLiked, isCommentCreated }: any = useSelector((state: Store) => state.postDataReducer);
  const loading: any = useSelector((state: Store) => state.userDataReducer.loading);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    dispatch(userDashboardFeed(page));
    // feedUpdate()
  }, [page, isLiked, isCommentCreated]);
  useEffect(() => {
    // if(feedList.length<=0){
    // setFeedList(userFeedListData)
    setFeedList((previous: any, newValue: any) => {
      if (previous !== newValue) {
        return [...feedList, ...userFeedListData];
      }
    });

    // }
  }, [userFeedListData]);
  return (
    <div className='center-box'>
      <div className='explore-tab-list'>
        <Tabs defaultActiveKey='Post' id='uncontrolled-tab-example' className='mb-3'>
          <Tab eventKey='Post' title='Post'>
            <PostFeed setPage={setPage} page={page} feedList={feedList} userFeedListData={userFeedListData} fromPage={"exploreList"} />
          </Tab>
          <Tab eventKey='People' title='People'>
            <PeopleList />
          </Tab>
          <Tab eventKey='Tags' title='Tags'>
            <TagsList />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
export default ExploreList;

const PeopleList = () => {
  return (
    <div className='people-list'>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>{" "}
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>{" "}
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>{" "}
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>{" "}
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>{" "}
      <div className='people-list-box'>
        <div className='people-details'>
          <img src={people} alt='people' />
          <h6>Lily Patel</h6>
        </div>
        <Button>Follow</Button>
      </div>
    </div>
  );
};

const TagsList = () => {
  return (
    <div className='tag-list-section'>
      <div className='tag-box'>
        <img src={hasimg} alt='hasimg' />
        <div className='has-details'>
          <h6>#loremipsum</h6>
          <p>18M Post</p>
        </div>
      </div>
      <div className='tag-box'>
        <img src={hasimg} alt='hasimg' />
        <div className='has-details'>
          <h6>#loremipsum</h6>
          <p>18M Post</p>
        </div>
      </div>
      <div className='tag-box'>
        <img src={hasimg} alt='hasimg' />
        <div className='has-details'>
          <h6>#loremipsum</h6>
          <p>18M Post</p>
        </div>
      </div>
      <div className='tag-box'>
        <img src={hasimg} alt='hasimg' />
        <div className='has-details'>
          <h6>#loremipsum</h6>
          <p>18M Post</p>
        </div>
      </div>
      <div className='tag-box'>
        <img src={hasimg} alt='hasimg' />
        <div className='has-details'>
          <h6>#loremipsum</h6>
          <p>18M Post</p>
        </div>
      </div>
      <div className='tag-box'>
        <img src={hasimg} alt='hasimg' />
        <div className='has-details'>
          <h6>#loremipsum</h6>
          <p>18M Post</p>
        </div>
      </div>
      <div className='tag-box'>
        <img src={hasimg} alt='hasimg' />
        <div className='has-details'>
          <h6>#loremipsum</h6>
          <p>18M Post</p>
        </div>
      </div>
      <div className='tag-box'>
        <img src={hasimg} alt='hasimg' />
        <div className='has-details'>
          <h6>#loremipsum</h6>
          <p>18M Post</p>
        </div>
      </div>
    </div>
  );
};
