/* eslint-disable jsx-a11y/alt-text */
import { useEffect, /* useRef, */ useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import useSound from "use-sound";
import {
  getPaidPackageList,
  CreatePaidPackage,
  editPaidPackage,
  CreateMediaPackage,
  getMediaPackageById,
  removeMediaFromPackage,
  removePackage,
} from '../redux/action';
import { Store, SettingsData } from '../redux/Actions';
import { AppDispatch } from '../redux';
import VerifiedIcon from '../assets/images/icons/share.svg';
import { PaidPackageSchema } from '../validationScrema/user';
import add from '../assets/images/icons/Vector.svg';
import leftArrow from '../assets/images/icons/left-arrow.svg';
import CreateNewPackage from './CreateNewPackage';
import EditPaidPackage from './EditPaidPackage';
// import preview from "../assets/images/imgs/img3.png";
import { S3_BASE_URL } from '../service/aws/config';
import {
  uploadFileAmazonS3,
  uploadThumbnailFileAmazonS3,
} from '../service/index.';
import SendPackage from './SendPackage';
import UploadMediaPopup from './UploadMedia';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Loader from './Loader';
import { MEDIA_SIZE } from '../constants/App-constant';

export default function PaidPackage(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const {
    paidPackageList,
    isPackageCreatedSuccess,
    mediaContentData,
    isMediaCreatedSuccess,
    loading,
    isPackageEditedSuccess,
    isDeleted,
    IsImageUpload,
  }: SettingsData = useSelector((state: Store) => state.settingsDataReducer);
  const [create, setCreate] = useState<boolean>(true);
  const [editPackageSection, setEditPackageSection] = useState<boolean>(false);
  const [packageDetail, setPackageDetail] = useState<any>({});
  const [packageSection, setPackageSection] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>('');
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [selectedThumbnail, setSelectedThumbnail] = useState<any>('');
  const [previewImage, setPreviewImage] = useState<any>('');
  const [preview, setPreview] = useState<any>('');
  const [sendPackageSection, setSendPackageSection] = useState<boolean>(false);
  const [packageId, setPackageId] = useState<any>('');
  const [uploadPopup, setUploadPopup] = useState(false);
  const [mediaType, setMediaType] = useState<string>('Image');
  const [mediaTypePreview, setMediaTypePreview] = useState<string>('Image');
  const [addMediaError, setAddMediaError] = useState<string>('');
  const [thumbnailSizeError, setThumbnailSizeError] = useState<any>(false);
  const [meadiPackageSizeError, setMeadiPackageSizeError] =
    useState<string>('');
  const [packageName, setPackageName] = useState<string>('');
  const [packagePrice, setPackagePrice] = useState<number | null>(null);
  const [errors, setErrors] = useState({});
  const [loader1, setLoader1] = useState<boolean>(false);
  useEffect(() => {
    dispatch(getPaidPackageList());
  }, [isPackageCreatedSuccess, isPackageEditedSuccess]);

  useEffect(() => {
    dispatch(getMediaPackageById(packageDetail?.id));
  }, [isMediaCreatedSuccess, packageDetail, dispatch]);

  useEffect(() => {
    setSelectedFiles(mediaContentData);
  }, [isMediaCreatedSuccess, mediaContentData?.length]);

  useEffect(() => {
    if (isDeleted) {
      dispatch(getMediaPackageById(packageDetail?.id));
    } else if (IsImageUpload) {
      dispatch(getMediaPackageById(packageDetail?.id));
    }
  }, [isDeleted, IsImageUpload]);

  function refreshState() {
    setErrors({});
    setPackageName('');
    setPackagePrice(null);
    handleCreate();
    setLoader1(false);
  }

  useEffect(() => {
    refreshState()
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [props.currentPage]);


  const submitPackage = (e: any) => {
    e.preventDefault();
    setLoader1(true);
    const validateValues: any = { packageName, packagePrice };
    const { isValid, errors } = PaidPackageSchema(validateValues);
    if (!isValid) {
      setErrors(errors);
      setLoader1(false);
      return;
    }
    const values = {
      media_package_name: packageName,
      media_package_price: packagePrice,
    };
    dispatch(CreatePaidPackage(values));
    refreshState()
  };
  const submitEditPackage = (e: any) => {
    e.preventDefault();
    setLoader1(true);
    const validateValues: any = { packageName, packagePrice };
    const { isValid, errors } = PaidPackageSchema(validateValues);
    if (!isValid) {
      setErrors(errors);
      setLoader1(false);
      return;
    }
    const values = {
      media_package_name: packageName,
      media_package_price: packagePrice,
      package_media_id: packageId,
    };
    dispatch(editPaidPackage(values));
    setErrors({})
    setPackageName('');
    setPackagePrice(null);
    handleEditSection();
    setLoader1(false);
  };
  const handleCreate = () => {
    setCreate(!create);
  };
  const handleEditSection = () => {
    setPackageName(packageDetail?.media_package_name);
    setEditPackageSection(!editPackageSection);
    setPackagePrice(packageDetail?.media_package_price);
    setPackageSection(false);
    setErrors({})
  };
  const handleEditPackage = () => { };
  const handlePackageDetail = (data: any) => {
    dispatch(getMediaPackageById(data?.id));
    setPackageDetail(data);
    setPackageName(data?.media_package_name);
    setPackagePrice(data?.media_package_price);
    setPackageId(data?.id);
    packageDetailSection();
  };
  const packageDetailSection = () => {
    setPackageSection(!packageSection);
    setPackageName('');
    setPackagePrice(null);
    dispatch(getPaidPackageList());
    if (packageSection === true) {
      setPreviewImage('');
    }
  };

  const handlePreview = async (file: any, mType: any) => {
    setAddMediaError('');
    setMeadiPackageSizeError('');
    setThumbnailSizeError(false);
    if (mType === 'Image' && file?.size / 1024 > MEDIA_SIZE.IMAGE_SIZE) {
      // toast.error(`Please Select ${mType} file only`, { toastId: 'image' });
      setMeadiPackageSizeError('Image');
      return false;
    }
    if (mType === 'Video' && file?.size / 1024 > MEDIA_SIZE.VIDEO_SIZE) {
      // toast.error(`Please Select ${mType} file only`, { toastId: 'image' });
      setMeadiPackageSizeError('Video');
      return false;
    }

    if (mType === 'Audio' && file?.size / 1024 > MEDIA_SIZE.AUDIO_SIZE) {
      // toast.error(`Please Select ${mType} file only`, { toastId: 'image' });
      setMeadiPackageSizeError('Audio');
      return false;
    }
    if (
      (mType === 'Image' &&
        !file?.name?.match(/\.(jpg|jpeg|png|gif|webp|svg)$/)) ||
      (mType == 'Image' && !file.type.includes('image'))
    ) {
      // toast.error(`Please Select ${mType} file only`, { toastId: 'image' });
      setAddMediaError('Image');
      return false;
    }
    if (
      (mType === 'Audio' &&
        !file?.name?.match(
          /\.(MP3|mp3|AAC|aac|Ogg|FLAC|flac|ALAC|alac|WAV|wav)$/
        )) ||
      (mType === 'Audio' && !file.type.includes('audio'))
    ) {
      // toast.error(`Please Select ${mType} file only`, { toastId: 'image' });
      setAddMediaError('Audio');
      return false;
    }

    if (
      (mType === 'Video' &&
        !file?.name?.match(
          /\.(MP4|MOV|WMV|AVI|AVCHD|FLV|WebM|MKV|mp4|mov|mkv)$/
        )) ||
      (mType === 'Video' && !file.type.includes('video'))
    ) {
      // toast.error(`Please Select ${mType} file only`, { toastId: 'image' });
      setAddMediaError('Video');
      return false;
    }
    if (mType === 'Image') {
      setMediaTypePreview('Image');
    } else if (mType === 'Audio') {
      setMediaTypePreview('Audio');
    } else if (mType === 'Video') {
      setMediaTypePreview('Video');
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    setSelectedFile(file);
  };
  const handleThumbnail = async (e: any) => {
    setAddMediaError('');
    setThumbnailSizeError(false);
    const file = e.target.files?.[0];
    if (file?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      if (!file?.name?.match(/\.(jpg|jpeg|png|gif|webp|svg)$/)) {
        setAddMediaError('Thumbnail');
        return false;
      } else {
        setSelectedThumbnail(file);
      }
    } else {
      setThumbnailSizeError(true);
      setSelectedThumbnail('');

      return false;
    }
  };
  //Upload File
  const uploadFile = async (file: any, thumbFile: any) => {
    setLoader1(true);
    let data:any = { Key: '', Location: '' };
    let thumbData:any = { Key: '', Location: '' };
    if (file) {
      data = await uploadFileAmazonS3(file);
    }
    if (thumbFile) {
      thumbData = await uploadFileAmazonS3(thumbFile);
    }
    // if(data.key || thumbData?.key){

    const uploadedFile = data?.Key;
    const thumbUpload = thumbData?.Key;
    const mediaContent = new FormData();
    let contentType: any = '0';
    if (mediaType === 'Video') {
      contentType = '2';
    } else if (mediaType === 'Audio') {
      contentType = '1';
    }

    mediaContent.append('media_content_file', uploadedFile);
    mediaContent.append('media_content_thumb', thumbUpload);
    mediaContent.append('media_package_id', packageId);
    mediaContent.append('media_content_type', contentType);

    setLoader1(false);
    await dispatch(CreateMediaPackage(mediaContent));
    // await dispatch(getMediaPackageById(packageDetail?.id));
  // }else{
  //   toast.error("Something when wrong ! Please try again")
  // }

  };
  const handleDeleteMedia = async (deleteId: any) => {
    try {
      await dispatch(removeMediaFromPackage(deleteId));
      // await dispatch(getMediaPackageById(packageDetail?.id));
    } catch (error: any) {
      console.log("");
    }
  };
  const handleDeletePackage = async (deleteId: any) => {
    setLoader1(true);
    await dispatch(removePackage(deleteId));
    await dispatch(getPaidPackageList());
    setLoader1(false);
    packageDetailSection();
 };
  //
  const handleSendPackageSection = (packageId: any, packageName: any) => {
    setSendPackageSection(!sendPackageSection);
    setPackageId(packageId);
    setPackageName(packageName);
  };

  const handleMediaPopup = () => {
    setUploadPopup(!uploadPopup);
  };
  return (
    <>
      {loader1 && <Loader />}
      {create ? (
        <CreateNewPackage
          t={t}
          handleCreate={handleCreate}
          submitPackage={submitPackage}
          setPackageName={setPackageName}
          setPackagePrice={setPackagePrice}
          packageName={packageName}
          packagePrice={packagePrice}
          errors={errors}
        />
      ) : packageSection ? (
        <PackageDetail
          packageDetailSection={packageDetailSection}
          packageDetail={packageDetail}
          previewImage={previewImage}
          preview={preview}
          selectedFiles={selectedFiles}
          uploadFile={uploadFile}
          handleEditSection={handleEditSection}
          handlePreview={handlePreview}
          handleDeleteMedia={handleDeleteMedia}
          handleDeletePackage={handleDeletePackage}
          handleMediaPopup={handleMediaPopup}
          loading={loading}
          t={t}
        />
      ) : editPackageSection ? (
        <EditPaidPackage
          handleEditSection={handleEditSection}
          handleEditPackage={handleEditPackage}
          submitEditPackage={submitEditPackage}
          setPackagePrice={setPackagePrice}
          setPackageName={setPackageName}
          packageName={packageName}
          packagePrice={packagePrice}
          packageDetail={packageDetail}
          t={t}
          errors={errors}
        />
      ) : (
        <BoxListing
          handleCreate={handleCreate}
          handlePackageDetail={handlePackageDetail}
          paidPackageList={paidPackageList}
          handleSendPackageSection={handleSendPackageSection}
          t={t}
        />
      )}
      <SendPackage
        packageId={packageId}
        packageName={packageName}
        sendPackageSection={sendPackageSection}
        setSendPackageSection={setSendPackageSection}
        t={t}
      />
      <UploadMediaPopup
        selectedFile={selectedFile}
        selectedThumbnail={selectedThumbnail}
        preview={preview}
        handlePreview={handlePreview}
        handleThumbnail={handleThumbnail}
        previewImage={previewImage}
        uploadFile={uploadFile}
        uploadPopup={uploadPopup}
        setUploadPopup={setUploadPopup}
        setMediaType={setMediaType}
        mediaType={mediaType}
        setPreview={setPreview}
        setMediaTypePreview={setMediaTypePreview}
        mediaTypePreview={mediaTypePreview}
        setAddMediaError={setAddMediaError}
        addMediaError={addMediaError}
        setMeadiPackageSizeError={setMeadiPackageSizeError}
        setThumbnailSizeError={setThumbnailSizeError}
        thumbnailSizeError={thumbnailSizeError}
        meadiPackageSizeError={meadiPackageSizeError}
      />
    </>
  );
}

const BoxListing = ({
  handleCreate,
  handlePackageDetail,
  paidPackageList,
  handleSendPackageSection,
  t,
}: any) => {

  function generateRandomLightColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 3; i++) {
      let component = Math.floor(Math.random() * 128) + 128;
      color += letters[component >> 4];
      color += letters[component & 0x0F];
    }

    return color;
  }

  return (
    <div className="paid-package-section">
      <div className="heading-text">
        <h6>{t('paidpackage.mypaidmediapackages')}</h6>
      </div>
      <div className="add-new-package" onClick={handleCreate}>
        {t('paidpackage.createnewpackage')}
        <span>
          <img src={add} alt="add" />
        </span>
      </div>
      <div className="package-listing">
        {paidPackageList &&
          paidPackageList.map((data: any) => (
            <div className="package-box" key={data.id} style={{ backgroundColor: generateRandomLightColor() }}>
              <div
                className="lest-section"
                onClick={() => handlePackageDetail(data)}
              >
                <h6>{data?.media_package_name}</h6>
                <p>
                  <span>
                    {t('paidpackage.mediacount')}: {data?.media_count}
                  </span>
                  <span style={{ marginLeft: '10px' }}>
                    {t('paidpackage.price')}: {data?.media_package_price}
                  </span>
                </p>
              </div>
              <div className="right-section">
                <img
                  onClick={() =>
                    handleSendPackageSection(data.id, data?.media_package_name)
                  }
                  src={VerifiedIcon}
                  alt="VerifiedIcon"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

// const SendPackage = ({packageId}:any) =>{

// }
const PackageDetail = ({
  packageDetailSection,
  packageDetail,
  handleEditSection,
  uploadFile,
  preview,
  selectedFiles,
  handleDeleteMedia,
  handleDeletePackage,
  handlePreview,
  handleMediaPopup,
  handlePlayAudio,
  loading,
  t,
}: any) => {
  return (
    <div className="package-detail">
      {loading && <Loader />}
      <div className="heading-text">
        <img src={leftArrow} alt="leftArrow" onClick={packageDetailSection} />
        <h6>{t('paidpackage.packagedetail')}</h6>
        {/* <p onClick={handleEditSection}>Edit</p> */}
        <div className="action-btns">
          <i className="las la-edit" onClick={handleEditSection}></i>
          <i
            className="las la-trash"
            onClick={() => handleDeletePackage(packageDetail?.id)}
          ></i>
        </div>
      </div>
      <div className="select-media">
        {/* <input
        //  type="file"
        //  onChange={(e: any) => handlePreview(e.target.files[0])}
         // onClick={handleMediaPopup}
          id="upload-img"
        /> */}
        {/* {preview && <img src={preview} alt="leftArrow" />} */}

        <label onClick={handleMediaPopup}>{t('paidpackage.selectmedia')}</label>
        <>
          {selectedFiles &&
            selectedFiles.map((file: any) => (
              <div className="preview-data-list" key={file.id}>
                <div className="preview-data">
                  {/* <p onClick={() => handleDeleteMedia(file?.id)}>Delete</p> */}
                  <i
                    className="las la-trash"
                    onClick={() => handleDeleteMedia(file?.id)}
                  ></i>
                  {file?.media_content_type === Number('2') ? (
                    <video
                      controls
                      width="100%"
                      poster={
                        file?.media_content_thumb
                          ? S3_BASE_URL + file?.media_content_thumb
                          : ''
                      }
                    >
                      <source
                        src={S3_BASE_URL + file?.media_content_file}
                        type="video/mp4"
                      />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  ) : file?.media_content_type === Number('0') ? (
                    <img
                      src={S3_BASE_URL + file?.media_content_file}
                      alt="leftArrow"
                      onClick={packageDetailSection}
                    />
                  ) : file?.media_content_type === Number('1') ? (
                    <>
                      {file?.media_content_thumb && (
                        <img
                          src={S3_BASE_URL + file?.media_content_thumb}
                          alt="leftArrow"
                          style={{ position: 'absolute' }}
                        // onClick={packageDetailSection}
                        />
                      )}
                      <audio
                        src={S3_BASE_URL + file?.media_content_file}
                        controls
                      />
                    </>
                  ) : (
                    //     <button onClick={() => handlePlayAudio(S3_BASE_URL + file?.media_content_file)}>
                    //     Play Sound
                    //  </button>
                    'No media found for this package'
                  )}
                </div>
              </div>
            ))}
        </>

        {/* <button className="changebtn" onClick={uploadFile}>
          Upload
        </button>
        <button className="changebtn" onClick={handleMediaPopup}>
          UploadNew
        </button> */}
      </div>
    </div>
  );
};
