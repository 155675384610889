import {instance, chatInstance} from "./axiosConfig";
import { dispatch } from "../redux";
import { logout } from "../redux/action";

 const METHOD = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch"
};

 const apiCall = (
  endpoint:string,
  params = {},
  onSuccess:Function,
  onFailure:Function,
  method = METHOD.GET,
  DyanamicConfig = {}
) => {
  const request = ():Promise<any> => {
    switch (method) {
      case METHOD.POST:
        return instance.post(endpoint, params, { ...DyanamicConfig });
      case METHOD.GET:
        return instance.get(endpoint, { ...DyanamicConfig });
      case METHOD.DELETE:
        return instance.delete(endpoint, { ...DyanamicConfig });
      case METHOD.PUT:
        return instance.put(endpoint, params, { ...DyanamicConfig });
      case METHOD.PATCH:
        return instance.patch(endpoint, params, { ...DyanamicConfig });
    }
    return new Promise((resolve, reject) => resolve({}));// axios.post<User[]>(endpoint, params, { ...DyanamicConfig });
  };

  const req = request();
  req.then(response => {

    if(response.status === 200 || response.status === 201 || response.status === 204 || response.data) {
      onSuccess(response.data);
    } 
    else {
      onFailure("Something went wrong");
    }
  })
    .catch((error:any) => {
      if (error && error.response) {
        switch (error.response.status) {
          case 401:
            // onFailure(
            //   error.response.data && typeof error.response.data.detail
            //     ? error.response.data.detail
            //     : "Session expired"
            // );
            dispatch(logout());
            break;

          default:
            onFailure(error.response.data ? error.response.data : "Something went wrong");
            break;
        }
      }
      //  else onFailure && onFailure("Something went wrong");
    });
};

 const chatApiCall = (
  endpoint:string,
  params = {},
  onSuccess:Function,
  onFailure:Function,
  method = METHOD.GET,
  DyanamicConfig = {}
) => {
  const request = ():Promise<any> => {
    switch (method) {
      case METHOD.POST:
        return chatInstance.post(endpoint, params, { ...DyanamicConfig });
      case METHOD.GET:
        return chatInstance.get(endpoint, { ...DyanamicConfig });
      case METHOD.DELETE:
        return chatInstance.delete(endpoint, { ...DyanamicConfig });
      case METHOD.PUT:
        return chatInstance.put(endpoint, params, { ...DyanamicConfig });
      case METHOD.PATCH:
        return chatInstance.patch(endpoint, params, { ...DyanamicConfig });
    }
    return new Promise((resolve, reject) => resolve({}));// axios.post<User[]>(endpoint, params, { ...DyanamicConfig });
  };

  const req = request();
  req.then(response => {


    if(response.status === 200 || response.status === 201 || response.status === 204 || response.data) {
      onSuccess(response.data);
    } 
    else {
      onFailure("Something went wrong");
    }
  })
    .catch((error:any) => {
      if (error && error.response) {
        switch (error.response.status) {
          case 401:
            // onFailure(
            //   error.response.data && typeof error.response.data.detail
            //     ? error.response.data.detail
            //     : "Session expired"
            // );
            dispatch(logout());
            break;

          default:
            onFailure(error.response.data ? error.response.data : "Something went wrong");
            break;
        }
      }
      //  else onFailure && onFailure("Something went wrong");
    });
};
 export {chatApiCall, apiCall, METHOD}


