import React, { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import AgoraRTM, { RtmChannel, RtmClient } from "agora-rtm-sdk";
import { ReactComponent as SendButton } from "../../assets/images/icons/sendButton.svg";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";

type MessageType = {
  userName: string;
  userId: string;
  profilePic: string;
  messageType: string;
  media: string;
  message: string;
};

const ChatMessage = ({ messageContent }: { messageContent: MessageType }) => {
  const { userName, profilePic, message, userId, messageType, media } =
    messageContent;

  // const { isStickerSent }: any = useSelector((state: Store) => state.chatDataReducer);

  // useEffect(()=>{
  //   if(isStickerSent) {

  //   }
  // },[isStickerSent])

  return (
    <div
      className="d-flex justify-content-start"
      style={{ marginBottom: "14px" }}
    >
      <div className="p-0 m-0" style={{ minWidth: "40px" }}>
        {/* <img className='rounded-circle mr-1' src={profilePic} alt='profile' onError={handleImageError} style={{ width: '34px ', height: '34px ' }} /> */}
        <Avatar
          round={true}
          src={profilePic}
          alt="profile"
          size="35"
          name={userName}
          maxInitials={2}
        />
      </div>
      <div>
        {/* <Stack> */}
        <h5
          style={{ lineHeight: "17px", fontSize: "14px", marginBottom: "0px" }}
        >
          {userName}
        </h5>
        <p className="mb-0" style={{ fontSize: "12px", lineHeight: "17px" }}>
          {message}
        </p>
        {/* </Stack> */}
      </div>
    </div>
  );
};

const Chat = ({
  token,
  userId,
  channelId,
  appId,
  profilePic,
  userName,
  handleMessageSubmitRef,
  messageType,
  message,
  donetTipMessage,
  setDonetTipMessage,
  tipValue,
  setTipValue,
  setForceToLeaveStreaming,
  setMember,
  endStreamMessage,
  setEndStreamMessage,
  currentSticker,
  setCurrentSticker
}: {
  token: string;
  userId: string;
  channelId: string;
  appId: string;
  profilePic: string;
  userName: string;
  handleMessageSubmitRef?: any;
  messageType: string;
  message: string;
  donetTipMessage?: any;
  setDonetTipMessage?: any;
  tipValue?: any;
  setTipValue?: any;
  setForceToLeaveStreaming: Function
  setMember: Function;
  endStreamMessage?: any
  setEndStreamMessage?: any
  currentSticker?: any
  setCurrentSticker?: Function;
}) => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [newMessage, setNewMessage] = useState(getInitialMessage());
  const [client, setClient] = useState<RtmClient>();
  const [channel, setChannel] = useState<RtmChannel>();

  const { isStickerSent }: any = useSelector((state: Store) => state.chatDataReducer);

  console.log(messages, "messagesmessagesmessages")

  function getInitialMessage() {
    switch (messageType) {
      case "LIVE_MESSAGE_TYPE_TEXT":
        return "";
      case "LIVE_MESSAGE_TYPE_TIP":
        return message;
    }
    return "";
  }

  console.log(currentSticker,"currentSticker in chat.tsx")

  useEffect(() => {
    if (isStickerSent && currentSticker?.sticker_url) {
      const newMessageAsArr11 = {
        userName: userName,
        profilePic: profilePic,
        message: `Sent sticker worth ${currentSticker?.price} coins!`,
        messageType: messageType,
        media: currentSticker?.sticker_url,
        userId: userId,
      };
      setMessages((prev: MessageType[]) => [...prev, { ...newMessageAsArr11 }]);
      if (newMessageAsArr11 && channel) {
        channel
          .sendMessage({ text: JSON.stringify(newMessageAsArr11) })
          .then(() => {
            console.log("Agora RTM message sent:", newMessageAsArr11);
          })
          .catch((err) => {
            console.log("Agora RTM send message error:", err);
          });
      }
      setCurrentSticker && setCurrentSticker({})
    }
  }, [isStickerSent,currentSticker?.sticker_url])

  useEffect(() => {
    // Initialize the Agora RTM client
    const client = AgoraRTM.createInstance(appId);
    setClient(client);

    // Log in to the Agora RTM client with a username
    client
      .login({ token: token, uid: userId })
      .then(async () => {
        // Create a channel and join it
        const channel = client.createChannel(channelId);
        setChannel(channel);
        await channel.join();
        const members = await channel.getMembers()
        setMember(members?.length)
        // Listen for channel messages
        channel.on("ChannelMessage", ({ text, messageType }) => {
          if (text === undefined) return;
          let textObject: any = JSON.parse(text);
          if (textObject?.messageType === "LIVE_MESSAGE_TYPE_END_STREAM") {
            setForceToLeaveStreaming(1)
            // toast.error("Live stream ended")
          }
          setMessages((prev: MessageType[]) => [
            ...prev,
            {
              userName: textObject?.userName,
              profilePic: textObject?.profilePic,
              message: textObject?.message ?? "",
              messageType: textObject?.messageType,
              media: textObject?.media,
              userId: textObject?.userId,
            },
          ]);
        });

        channel.on("MemberJoined", async function (memberId) {

          const members = await channel.getMembers()
          setMember(members.length)
        });
        channel.on("MemberLeft", async function (memberId) {
          const members = await channel.getMembers()
          setMember(members.length)
        });
      })
      .catch((err) => {
        console.log("Agora RTM login error:", err);
      });

    // Clean up function
    return () => {
      if (client) {
        client.logout();
        setClient(undefined);
      }
    };
  }, [userId, token]);

  useEffect(() => {
    if (donetTipMessage) {
      const newMessageAsArr11 = {
        userName: userName,
        profilePic: profilePic,
        message: `Donated ${tipValue} coins!`,
        messageType: messageType,
        media: "",
        userId: userId,
      };
      setMessages((prev: MessageType[]) => [...prev, { ...newMessageAsArr11 }]);
      if (newMessageAsArr11 && channel) {
        // Send the chat message to the Agora RTM channel
        channel
          .sendMessage({ text: JSON.stringify(newMessageAsArr11) })
          .then(() => {
            console.log("Agora RTM message sent:", newMessageAsArr11);
          })
          .catch((err) => {
            console.log("Agora RTM send message error:", err);
          });
      }
      setTipValue("")
      setDonetTipMessage(false);
    }
  }, [donetTipMessage]);

  useEffect(() => {
    if (endStreamMessage?.isEnd) {
      const newMessageAsArr11 = {
        userName: userName,
        profilePic: profilePic,
        message: "",
        messageType: messageType,
        media: "",
        userId: userId,
      };
      setMessages((prev: MessageType[]) => [...prev, { ...newMessageAsArr11 }]);
      if (newMessageAsArr11 && channel) {
        // Send the chat message to the Agora RTM channel
        channel
          .sendMessage({ text: JSON.stringify(newMessageAsArr11) })
          .then(() => {
            console.log("Agora RTM message sent:", newMessageAsArr11);
          })
          .catch((err) => {
            console.log("Agora RTM send message error:", err);
          });
      }
      setEndStreamMessage({ ...endStreamMessage, isEnd: false })
    }
  }, [endStreamMessage?.isEnd]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!newMessage?.length) return;
    const newMessageAsArr = {
      userName: userName,
      profilePic: profilePic,
      message: newMessage,
      messageType: messageType,
      media: "",
      userId: userId,
    };
    setMessages((prev: MessageType[]) => [...prev, { ...newMessageAsArr }]);
    setNewMessage("");

    if (newMessageAsArr && channel) {
      // Send the chat message to the Agora RTM channel
      channel
        .sendMessage({ text: JSON.stringify(newMessageAsArr) })
        .then(() => {
          console.log("Agora RTM message sent:", newMessageAsArr);
        })
        .catch((err) => {
          console.log("Agora RTM send message error:", err);
        });
    }

    //scroll to the bottom of this scrollable div
  };

  // React.useImperativeHandle(handleMessageSubmitRef, () => ({
  //   handleSubmit('')
  // }));

  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current) ref.current.scrollTop = ref.current?.scrollHeight;
  }, [messages]);

  return (
    <>
      {/* <div className='host-live-view'>
        <div className='d-flex align-items-center'>
          <ViewIcon className='mr-1' />
          {'4592'}
        </div>
      </div> */}
      <div className="live-chat-container h-1">
        <div className="d-flex justify-content-center py-2">
          <div className="font-weight-bold">Live Comments</div>
        </div>
        <hr className="m-0 p-0 weigth-bold" />
        <div className="p-4">
          <div
            className="chat-box"
            style={{
              // marginBottom: 30,
              // maxHeight: '360px',
              height: "65vh",
              position: "relative",
            }}
          >
            <div
              className="chat-message-container"
              ref={ref}
              style={{
                maxHeight: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingBottom: "50px",
              }}
            >
              {messages.map((message: MessageType, index: number) => (
                <ChatMessage key={index} messageContent={message} />
              ))}
            </div>
            <Form
              style={{
                position: "absolute",
                width: "100%",
                bottom: 0,
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.stopPropagation();
                  event.preventDefault();
                  handleSubmit(event);
                }
              }}
            >
              <InputGroup className="message-send-btn-container">
                <InputGroup.Text>
                  <Avatar
                    src={profilePic}
                    alt="user"
                    style={{
                      height: "36px !important",
                      width: "36px  !important",
                    }}
                    round={true}
                    size="35"
                  />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Enter your message"
                  value={newMessage}
                  onChange={(event) => setNewMessage(event.target.value)}
                />
                <InputGroup.Text>
                  <SendButton
                    className="mr-2"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSubmit(event);
                    }}
                    ref={handleMessageSubmitRef}
                  />
                </InputGroup.Text>
              </InputGroup>
              {/* <Button variant='primary' type='submit'>
                Send
              </Button> */}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
