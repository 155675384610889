import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { Store } from '../../redux/Actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux';
import { useFormik } from 'formik';
import {
  createTextPost,
  getSearchedUserList,
} from '../../redux/action/postActions';
import Select from 'react-select';
import CustomOption from './CustomeOption';
import { textPostSchema } from '../../validationScrema/user';
import SearchableSelect from '../SearchableSelect';
import { METHOD, apiCall } from '../../service/baseApiCall';
import { GET_PEOPLE_LIST_URL, SEARCH_TAGS } from '../../redux/ApiEndPoints';
import PostFeed from '../PostFeed';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../utils/utility';
import { userDetail } from '../../redux/action';

// const postOptions = [
//   { label: "Free to all", value: 0 },
//   { label: "For Subscribers", value: 1 },
//   { label: "img-fluid", value: 2 },
// ];

export default function TextPost({ showPostComment, setShowPostComment }: any) {
  const { loading, createTextPostSuccess, searchUserList }: any = useSelector(
    (state: Store) => state.postDataReducer
  );
  const [selectList, setSelectList] = useState<any>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [hashtagList, setHashTagList] = useState<any>();
  const [hashtagListData, setHashTagListData] = useState<any>([]);
  const [textLoader, setTextLoader] = useState(false);
  const ref: any = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      post_type: 3,
      description: '',
      hash_tags: [],
      tags: [],
    },
    validationSchema: textPostSchema,
    onSubmit: async (values) => {
      setTextLoader(true);
      var hasTagData: any = [];
      values?.hash_tags?.map((elem: any) => {
        if (elem) {
          hasTagData.push(elem?.value);
        }
      });
      const data = {
        ...values,
        hash_tags: hasTagData.toString(),
        tags: values.tags.map((i: any) => i.value).join(','),
      };
      await dispatch(
        createTextPost(data, () => {
          const userId = getToken('userId');
          dispatch(userDetail(Number(userId)));
          setShowPostComment(false);
          setTextLoader(false);
          formik.values.description = '';
          setTagList([]);
          formik.resetForm();
        })
      );
      setTextLoader(false);
    },
  });
  const { errors, touched, setFieldValue, setErrors } = formik;

  useEffect(() => {
    dispatch(getSearchedUserList(formik.values.tags));
  }, [formik.values.tags]);
  useEffect(() => {
    let data = [];
    data =
      searchUserList &&
      searchUserList.length > 0 &&
      searchUserList.map((data: any) => {
        return {
          value: data.id,
          label: data.created_by,
          username: data.username,
          profile_photo: data.profile_photo,
        };
      });
    if (data && data.length > 0) {
      setSelectList(data);
    }
  }, [searchUserList]);

  useEffect(() => {
    setShowPostComment(false);
  }, [createTextPostSuccess]);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // setShowPostComment(false)
      setErrors({});
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowPostComment]);
  const ontagChange = (option: any) => {
    setTagList(option);
    const ids = option.map((i: any) => i.value);
    formik.setFieldValue('tags', ids);
  };
  const handleKeyDown = (evt: any) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      var value = hashtagList.trim();
      if (value) {
        setHashTagListData([...hashtagListData, value]);
      }
      setHashTagList('');
    }
  };

  const handleDelete = (item: any) => {
    const data = hashtagListData.filter((i: any) => i !== item);
    setHashTagListData(data);
    setHashTagList('');
  };
  useEffect(() => {
    formik.setFieldValue('hash_tags', hashtagListData);
  }, [hashtagListData]);

  const handleTagSearch = async (keyword: any, onSuccess: any) => {
    await apiCall(
      SEARCH_TAGS,
      { search: keyword, page: 1 },
      (res: any) => {
        onSuccess(
          res.data.map((i: any) => ({
            label: i?.hash_tag,
            value: i?.hash_tag,
          }))
        );
      },
      () => {
        onSuccess([]);
      },
      METHOD.POST
    );
  };
  const handlePeopleSearch = async (keyword: any, onSuccess: any) => {
    await apiCall(
      GET_PEOPLE_LIST_URL,
      { search: keyword },
      (res: any) => {
        onSuccess(
          res.data.map((i: any) => ({
            label: `${i?.first_name} ${i?.last_name}`,
            value: i?.id,
          }))
        );
      },
      () => {
        onSuccess([]);
      },
      METHOD.POST
    );
  };
  return (
    <Modal
      show={showPostComment}
      onHide={() => {
        setShowPostComment(false);
        setTagList([]);
        formik.resetForm();
      }}
      className="custom-modal comment-modal text-post-modal"
      centered
    >
      <Modal.Body>
        <div className="text-popup-content">
          <button
            type="button"
            className="btn-close d-md-none"
            data-bs-dismiss="modal"
          ></button>

          <Form onSubmit={formik.handleSubmit}>
            <textarea
              placeholder={`${t('home.caption')}`}
              className="form-control p-0 pt-3 post-text-areacontrol"
              rows={9}
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            >
              {t('home.text placeholder')}
            </textarea>
            {errors.description && touched.description && (
              <p
                className="text-danger ml-2 mt-1"
                style={{ fontSize: 'smaller' }}
              >
                <>{errors?.description}</>
              </p>
            )}
            {/* <div className="radio-price-group radio-price-group-circle"> */}
            {/* {postOptions.map((item) => (
                <Fragment key={`Item${item.value}`}>
                  <input
                    type="radio"
                    className="btn-check"
                    id={`Content-Item${item.value}`}
                    checked={formik.values.payment_type === String(item.value)}
                    value={item.value}
                    onChange={(e) => {
                      formik.setFieldValue("payment_type", e.target.value);
                    }}
                  />
                  <label
                    className="btn btn-secondary"
                    htmlFor={`Content-Item${item.value}`}
                  >
                    {item.label}
                  </label>
                </Fragment>
              ))} */}

            {/* <input
                type="radio"
                className="btn-check"
                name="plan2"
                id="Free"
                autoComplete="off"
                defaultChecked
              />
              <label className="btn btn-secondary" htmlFor="Free">
                Free to all
              </label>

              <input
                type="radio"
                className="btn-check"
                name="plan2"
                id="Subscribers"
                autoComplete="off"
              />
              <label className="btn btn-secondary" htmlFor="Subscribers">
                For Subscribers
              </label>
            </div> */}
            <br />
            <label className="caption-label">
              <b>{t('home.tag people')}</b> <i>@</i>{' '}
              <SearchableSelect
                isMulti
                placeholder={`${t('home.select')}`}
                onSearch={handlePeopleSearch}
                onChange={(val) => formik.setFieldValue('tags', val)}
                value={formik.values.tags}
              />
            </label>

            <label className="caption-label">
              <b>Hashtag</b> <i>#</i>{' '}
              <SearchableSelect
                isMulti
                placeholder={`${t('home.select')}`}
                onSearch={handleTagSearch}
                onChange={(val) => formik.setFieldValue('hash_tags', val)}
                value={formik.values.hash_tags}
              />
            </label>
            {/* {formik.values.hash_tags.map((item) => (
                <div className="tag-item" key={item}>
                  {item}
                  <button
                    type="button"
                    className="button"
                    onClick={() => handleDelete(item)}
                  >
                    &times;
                  </button>
                </div>
              ))}
              <input
                className={"input "}
                value={hashtagList}
                placeholder="hashtag"
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setHashTagList(e.target.value);
                }}
              // onPaste={handlePaste}
              /> */}
            <div className="modal-footer justify-content-center border-0 p-0 mt-auto post-button">
              <Button
                type="submit"
                className="btn btn-login w-100 py-3"
                data-bs-dismiss="modal"
                disabled={textLoader}
              >
                {textLoader ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  'Post'
                )}
                {/* {loading && (
                    <span className="spinner-grow spinner-grow-sm"></span>
                  )} */}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
