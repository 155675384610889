// import './index.css'
import "@fortawesome/fontawesome-free/css/all.min.css";
import React, { StrictMode } from "react";
import {createRoot} from 'react-dom/client';

import { Provider } from "react-redux";
import App from "./App";
import store from "./redux";
import reportWebVitals from "./reportWebVitals";
// import i18n (needs to be bundled ;))
import './utils/i18n';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);
root.render(
  // <StrictMode>
    <Provider store={store}>
          <App />
    </Provider>
  // </StrictMode>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
