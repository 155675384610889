export const documentList=[{id:1,value:"Government-issued ID card"},
{id:2,value:"National Id card"},
{id:3,value:"Passport"},
{id:4,value:"Driving license"}
]

export const postOptions = [
    { label: "Free to all", value: 0 },
    { label: "For Subscribers", value: 1 },
    { label: "Paid content", value: 2 },
  ];
  