/* eslint-disable import/no-anonymous-default-export */
import { LOGIN, CHANGE_LOCAL, CHAT } from '../constants/action-types';
import { ChatData } from '../Actions';
import { ActionTypes } from '../action/userActions';
import { setToken } from '../../utils/utility';

const initialState = {
  loading: false,
  socketStatus: '',
  chatUserList: [],
  roomDetail: '',
  chatHistory: [],
  sendMessageStatus: '',
  sendPackageStatus: '',
  pictures: [],
  unlockResponse: false,
  socketConnect: {},
  fileUploaded: false,
  stickers: {},
  isStickerSent: false,
};
export default (state: ChatData = initialState, action: ActionTypes) => {
  switch (action.type) {
    case CHANGE_LOCAL:
      localStorage.setItem('local', action.payload.toString());
      return {
        ...state,
        local: action.payload.toString(),
      };
    case LOGIN.LOGIN_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHAT.SOCKET_CONNECTION_SUCCESS:
      // const response = action.payload
      return {
        ...state,
        ...action.payload,
        socketStatus: action.payload,
      };
    case CHAT.FETCH_CHAT_USERLIST_INITLIZATION:
      return {
        ...state,
        //  ...action.payload,
        loading: true,
      };
    case CHAT.USER_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        chatUserList: action.payload,
        loading: false,
      };
    case CHAT.ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        chatUserList: [action.payload, ...state.chatUserList],
        loading: false,
      };
    case CHAT.USER_LIST_ERROR:
      return {
        ...state,
        ...action.payload,
        chatUserList: [],
        loading: false,
      };
    case CHAT.ROOM_DETAIL_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHAT.ROOM_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        roomDetail: action.payload,
        loading: false,
      };
    case CHAT.ROOM_DETAIL_ERROR:
      return {
        ...state,
        ...action.payload,
        roomDetail: [],
        loading: false,
      };
    case CHAT.FETCH_CHAT_HISTORY_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHAT.ROOM_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        chatHistory: [...action.payload],
        loading: false,
      };
    case CHAT.ROOM_CHAT_HISTORY_ERROR:
      return {
        ...state,
        ...action.payload,
        chatHistory: [],
        loading: false,
      };
    case CHAT.FETCH_NEW_MESSAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHAT.FETCH_NEW_MESSAGE_SUCCESS:
      const newMessage = [];
      newMessage.push(action.payload);

      return {
        ...state,
        //  ...action.payload,
        chatHistory: [...state.chatHistory, action.payload],
        loading: false,
      };
    case CHAT.FETCH_NEW_MESSAGE_ERROR:
      return {
        ...state,
        ...action.payload,
        chatHistory: [],
        loading: false,
      };
    case CHAT.SEND_MESSAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        // loading: true,
      };
    case CHAT.SEND_MESSAGE_SUCCESS:
      const { status } = action.payload.res;
      return {
        ...state,
        ...action.payload,
        sendMessageStatus: status,
        loading: false,
      };
    case CHAT.SEND_MESSAGE_ERROR:
      return {
        ...state,
        ...action.payload,
        sendMessageStatus: '',
        loading: false,
      };
    case CHAT.UPLOAD_FILE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHAT.UPLOAD_FILE_SUCCESS:
      // const { status } = action.payload.res;
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case CHAT.UPLOAD_FILE_ERROR:
      return {
        ...state,
        ...action.payload,
        sendMessageStatus: '',
        loading: false,
      };

    case 'remove-attachments':
      return {
        ...state,
        pictures: [],
      };
    case CHAT.ATTACH_TO_MESSAGE:
      return {
        ...state,
        // pictures: [...state.pictures, { file: action.payload }],
        pictures: [{ file: action.payload }],
        fileUploaded: !state.fileUploaded,
      };
    case CHAT.SEND_PACKAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHAT.SEND_PACKAGE_SUCCESS:
      const packageStatus = action.payload.res?.status;
      return {
        ...state,
        ...action.payload,
        sendPackageStatus: packageStatus,
        loading: false,
      };
    case CHAT.SEND_PACKAGE_ERROR:
      return {
        ...state,
        ...action.payload,
        sendPackageStatus: false,
        loading: false,
      };
    case CHAT.SEND_PAID_PACKAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHAT.SEND_PAID_PACKAGE_SUCCESS:
      const sendStatus = action.payload.res?.status;
      return {
        ...state,
        ...action.payload,
        sendPaidPackageStatus: sendStatus,
        loading: false,
      };
    case CHAT.SEND_PAID_PACKAGE_ERROR:
      return {
        ...state,
        ...action.payload,
        sendPaidPackageStatus: false,
        loading: false,
      };
    case CHAT.UNLOCK_PAID_PACKAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHAT.UNLOCK_PAID_PACKAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        unlockResponse: action.payload,
        loading: false,
      };
    case CHAT.UNLOCK_PAID_PACKAGE_ERROR:
      return {
        ...state,
        ...action.payload,
        sendPaidPackageStatus: false,
        loading: false,
      };
    case CHAT.STICKER_LIST_SUCCESS:
      return {
        ...state,
        stickers: action.payload,
      };
    case CHAT.STICKER_LIST_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case CHAT.POST_STICKER_INIT:
      return {
        ...state,
        loading: true,
        isStickerSent: false,
      };
    case CHAT.POST_STICKER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isStickerSent: true,
      };
    case CHAT.POST_STICKER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isStickerSent: false,
      };
    case CHAT.POST_STICKER_INIT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
