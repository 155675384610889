import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "../assets/images/icons/ico_home.svg";
import HomeBlueIcon from "../assets/images/icons/ico_home_blue.svg";
import ChatIcon from "../assets/images/icons/ico_chat.svg";
import ChatBlueIcon from "../assets/images/icons/ico_chat_blue.svg";
import MapIcon from "../assets/images/icons/ico_map.svg";
import MapBlueIcon from "../assets/images/icons/ico_map_blue.svg";

import NotificationIcon from "../assets/images/icons/ico_notification.svg";
import NotificationBlueIcon from "../assets/images/icons/ico_notification_blue.svg";
import BookmarkIcon from "../assets/images/icons/ico_bookmark.svg";
import BookmarkBlueIcon from "../assets/images/icons/ico_bookmark_blue.svg";
import SettingsIcon from "../assets/images/icons/ico_setting.svg";
import SettingsBlueIcon from "../assets/images/icons/ico_setting_blue.svg";
import { FormattedMessage } from "react-intl";
import { useTranslation } from "react-i18next";
export default function SideBar() {
  const { t } = useTranslation();
  const [activeId, setActiveId] = useState("1");
  const location: any = useLocation();
  useEffect(() => {
    if (location.pathname === "/home") {
      setActiveId("1");
    } else if (location.pathname === "/messages") {
      setActiveId("2");
    } else if (location.pathname === "/explore") {
      setActiveId("3");
    } else if (location.pathname === "/notification") {
      setActiveId("4");
    } else if (location.pathname === "/favourite") {
      setActiveId("5");
    } else if (location.pathname.includes('settings')) {
      setActiveId("6");
    } else {
      setActiveId("0")
    }
  }, [location?.pathname]);
  return (
    <div className="box-container d-none d-md-block">
      <ul className="nav">
        <li
          className="nav-item"
          id="1"
          onClick={(e) => {
            setActiveId(e.currentTarget.id);
          }}
        >
          {/* <a className="nav-link active" aria-current="page" href="index.html"> */}
          <Link
            className={`nav-link  ${activeId === "1" && "active"}`}
            to={"/home"}
          >
            <img src={HomeIcon} className="img-fluid img-icon" />
            <img src={HomeBlueIcon} className="img-fluid img-icon-active" />
            <span
              id="1"
              onClick={(e) => {
                console.log();
              }}
            >
              <FormattedMessage id="sidebar.home" />
            </span>
          </Link>
          {/* </a> */}
        </li>
        <li
          className="nav-item"
          id="2"
          onClick={(e) => {
            setActiveId(e.currentTarget.id);
          }}
        >
          {/* <a className="nav-link"> */}
          <Link
            className={`nav-link  ${activeId === "2" && "active"}`}
            to={"/messages"}
            id="2"
          >
            <img src={ChatIcon} className="img-fluid img-icon" />
            <img src={ChatBlueIcon} className="img-fluid img-icon-active" />
            <span
              id="2"
              onClick={(e) => {
                console.log();
              }}
            >
              {t("sidebar.messages")}
            </span>
          </Link>
          {/* </a> */}
        </li>
        <li
          className="nav-item"
          id="3"
          onClick={(e) => {
            setActiveId(e.currentTarget.id);
          }}
        >
          {/* <a className={`nav-link  ${activeId ==="1" && "active"}`} href="explore.html"> */}
          <Link
            className={`nav-link  ${activeId === "3" && "active"}`}
            to={"/explore"}
            id="3"
          >
            <img src={MapIcon} className="img-fluid img-icon" />
            <img src={MapBlueIcon} className="img-fluid img-icon-active" />
            <span
              id="3"
              onClick={(e) => {
                console.log();
              }}
            >
              {t("sidebar.explore")}
            </span>
          </Link>
          {/* </a> */}
        </li>
        <li
          className="nav-item"
          id="4"
          onClick={(e) => {
            setActiveId(e.currentTarget.id);
          }}
        >
          {/* <a className={`nav-link  ${activeId ==="1" && "active"}`} href="notification.html"> */}
          <Link
            className={`nav-link  ${activeId === "4" && "active"}`}
            to={"/notification"}
            id="4"
          >
            <img src={NotificationIcon} className="img-fluid img-icon" />
            <img
              src={NotificationBlueIcon}
              className="img-fluid img-icon-active"
            />
            <span>{t("sidebar.notifications")}</span>
          </Link>
          {/* </a> */}
        </li>
        <li
          className="nav-item"
          id="5"
          onClick={(e) => {
            setActiveId(e.currentTarget.id);
          }}
        >
          {/* <a className={`nav-link  ${activeId ==="1" && "active"}`} href="#"> */}
          <Link
            className={`nav-link  ${activeId === "5" && "active"}`}
            to={"/favourite"}
            id="5"
          >
            <img src={BookmarkIcon} className="img-fluid img-icon" />
            <img src={BookmarkBlueIcon} className="img-fluid img-icon-active" />
            <span>{t("sidebar.favorites")}</span>
          </Link>
          {/* </a> */}
        </li>
        <li
          className="nav-item"
          id="6"
          onClick={(e) => {
            setActiveId(e.currentTarget.id);
          }}
        >
          {/* <a className={`nav-link  ${activeId ==="1" && "active"}`} href="settings.html"> */}
          <Link
            className={`nav-link  ${activeId === "6" && "active"}`}
            to={"/settings/edit-profile"}
            id="6"
          >
            <img src={SettingsIcon} className="img-fluid img-icon" />
            <img src={SettingsBlueIcon} className="img-fluid img-icon-active" />
            <span>{t("sidebar.settings")}</span>
          </Link>
          {/* </a> */}
        </li>
      </ul>
    </div>
  );
}
