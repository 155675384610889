import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import coins from '../assets/images/imgs/coins.svg';
import { useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
import { wallet } from '../redux/action';
import { dispatch } from '../redux';
import moment from 'moment';
import PayoutModal from '../components/models/PayoutModal';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';

const MyWallet = () => {
  const { t } = useTranslation();
  const { postData, loading, hasMoreTransaction } = useSelector(
    (state: Store) => state?.walletReducer
  );
  const [showPayoutModal, setShowPayoutModel] = useState(false);
  const [amount, setAmount] = useState<any>('');
  const [packageId, setPackageId] = useState<any>('');
  const [page, setPage] = useState(1);

  const { isInAppLoading, isInAppSuccess }: any = useSelector(
    (state: Store) => state.payoutReducer
  );
  useEffect(() => {
    if (isInAppSuccess) {
      setShowPayoutModel(false);
    }
  }, [isInAppSuccess]);

  useEffect(() => {
    //@ts-ignore
    dispatch(wallet(page));
  }, [page, isInAppSuccess]);

  const addPayoutModal = (e: any) => {
    setShowPayoutModel(true);
    setPackageId(e);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  return (
    <div className="my-wallet-page">
      {loading && <Loader />}
      <div className="title">
        <h2>{t('wallet.mywallet')}</h2>
      </div>
      <div className="your-amount">
        <div className="left-section">
          <p>{t('wallet.yourbalance')}</p>
          <h6>{postData?.wallet_balance}</h6>
        </div>
        <img src={coins} alt="coin" />
      </div>
      <div className="buy-coin-section">
        <p>{t('wallet.buycoins')}</p>
        <div className="slider">
          <Slider {...settings}>
            {postData?.user_packages &&
              postData?.user_packages?.map((item: any) => (
                <div key={item?.id}>
                  <div className="amount-box">
                    <div className="box-content">
                      <img src={coins} alt="coin" />
                      <p>{item?.coin}</p>
                    </div>
                    <div
                      className="head"
                      onClick={(e) => {
                        addPayoutModal(item?.inapp_identifier);
                      }}
                    >
                      ${item?.price}
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
      <div className="transaction-history-section">
        <p>{t('wallet.transactionhistory')}</p>
        <div className="history-box">
          <InfiniteScroll
            dataLength={postData?.transactions ? postData?.transactions : ''} //This is important field to render the next data
            next={() => {
              // setPage(page + 1);
              setPage(page + 1);
            }}
            hasMore={
              hasMoreTransaction
              // userFeedListData && userFeedListData.length > 0 ? true : false
            }
            loader={loading && <h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>{t("home.yah! you have seen it all")}</b>
              </p>
            }
          >
            <div className="top-section" style={{ height: '300px !imporent' }}>
              {postData?.transactions &&
                postData?.transactions?.map((item: any) => {
                  return (
                    <div className="transaction-list" key={item.id}>
                      <div className="left-section">
                        <div className="date">
                          <h6>{moment(item?.created_at).format('DD')}</h6>
                          <p>{moment(item?.created_at).format('MMM')}</p>
                        </div>
                        <div className="details">
                          <h6>{item?.title}</h6>
                          <p>{item?.message}</p>
                        </div>
                      </div>
                      <div className="right-section">
                        <h6
                          className={
                            item?.transaction_type === 1
                              ? 'added-amt'
                              : 'debit-amt'
                          }
                        >
                          {item?.price}
                        </h6>
                      </div>
                    </div>
                  );
                })}
            </div>
          </InfiniteScroll>
          {/* <div className="view-more-history">
            <h6>{t("wallet.viewalltransaction")}</h6>
          </div> */}
        </div>
      </div>
      <PayoutModal
        show={showPayoutModal}
        setShow={setShowPayoutModel}
        // amountAddedByUser={amount}
        packageId={packageId}
      />
    </div>
  );
};
export default MyWallet;
