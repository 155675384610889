import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import User1 from '../assets/images/imgs/follow1.svg';
import { AppDispatch } from '../redux';
import {
  editProfile,
  getCountry,
  getState,
  userDetail,
  userTranding,
} from '../redux/action';
import { Store } from '../redux/Actions';
import UserImg from '../assets/images/imgs/user-round.svg';
import BackImage from '../assets/images/bg/bg-img2.svg';
import FacebookeIcon from '../assets/images/icons/facebook.svg';
import InstagramIcon from '../assets/images/icons/instagram.svg';
import TwitterIcon from '../assets/images/icons/twitter.svg';
import OtherProfile from '../assets/images/icons/link.svg';
import LinkdinIcon from '../assets/images/icons/linkdin.svg';
import InternetIcon from '../assets/images/icons/internet.svg';
import LinkIcon from '../assets/images/icons/link.svg';
import { useFormik } from 'formik';
import { EditProfileSchema } from '../validationScrema/user';
import { uploadFileAmazonS3 } from '../service/index.';
import { profile } from 'console';
import { S3_BASE_URL } from '../service/aws/config';
import { getToken } from '../utils/utility';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import Loader from './Loader';
import { toast } from 'react-toastify';
import { MEDIA_SIZE } from '../constants/App-constant';
export default function EditProfile(props:any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const [loader1, setLoader1] = useState<boolean>(false);
  const userDetailData: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  const loading: any = useSelector(
    (state: Store) => state.userDataReducer.loading
  );
  const { countryList, isProfileUpdated }: any = useSelector(
    (state: Store) => state.userDataReducer
  );
  const [imgData, setImgData] = useState();
  const [imagePostErr, setImagePostErr] = useState<boolean>(false);
  useEffect(() => {
    // if(!userDetailData){
    const userId = getToken('userId');
    dispatch(userDetail(Number(userId)));
    dispatch(getCountry());
    dispatch(getState({ country: 1 }));
    formik.resetForm()
    // }
  }, [props.currentPage, isProfileUpdated]);
  // useEffect(() => {
  //   formik.resetForm()
  // }, [props.currentPage]);
  const onImageChange = async (event: any) => {
    setLoader1(true);
    const imgFile = event.target.files[0];
    if (imgFile?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      if (event.target.files && event.target.files[0]) {
        if (!imgFile?.name.match(/\.(jpg|jpeg|png)$/)) {
          setLoader1(false);
          toast.error(t('home.upload file'));
          return false;
        } else if (event.target.name === 'profile_photo') {
          await uploadFileAmazonS3(event.target.files[0], 'user/profile/').then(
            (data) => {
              setFieldValue('profile_photo', `${data.Key}`);
              setLoader1(false);
            }
          );
        } else {
          await uploadFileAmazonS3(event.target.files[0], 'user/profile').then(
            (data) => {
              setFieldValue('cover_photo', `${data.Key}`);
              setLoader1(false);
            }
          );
        }
      }
    } else {
      setLoader1(false);
      toast.error(
        `Please upload less the ${MEDIA_SIZE.IMAGE_SIZE / 1024} MB Image File`
      );
    }
    // setLoader1(false);
  };

  const dispatch = useDispatch<AppDispatch>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userDetailData && userDetailData?.first_name,
      last_name: userDetailData?.last_name ?? '',
      username: userDetailData?.username ?? '',
      phone_number: userDetailData?.phone_number ?? '',
      email: userDetailData?.email ?? '',
      gender: userDetailData?.gender ?? '',
      country: userDetailData?.country_id ?? '',
      zipcode: userDetailData?.zipcode ?? '',
      state: userDetailData?.state ?? '',
      city: userDetailData?.city ?? '',
      address: userDetailData?.address ?? '',
      date_of_birth: userDetailData?.birth_date ?? '',
      website_url: userDetailData?.website_link ?? '',
      facebook_url: userDetailData?.facebook_link ?? '',
      instagram_url: userDetailData?.instagram_link ?? '',
      twitter_url: userDetailData?.twitter_link ?? '',
      other_profile_url: userDetailData?.other_profile_link ?? '',
      bio: userDetailData?.bio ?? '', 
      profile_photo: userDetailData?.profile_photo ?? '',
      cover_photo: userDetailData?.cover_photo ?? '',
      // acceptTerms: false,
    },
    validationSchema: EditProfileSchema,
    onSubmit: async (values) => {
      let profileData;
      let cover_photo;
      await uploadFileAmazonS3(
        new File([values.cover_photo], 'cover_photo')
      ).then((data) => {
        profileData = data.Key;
      });
      await uploadFileAmazonS3(
        new File([values.profile_photo], 'profile_photo')
      ).then((data) => {
        profileData = data.Key;
      });
      if (profileData && cover_photo) {
        values = {
          ...values,
          cover_photo: cover_photo,
          profile_photo: profileData,
        };
      }

      dispatch(editProfile(values));
    },
  });
  const upload = () => { };
  // useEffect(() => {
  //   // formik.resetForm()
  // }, [userDetailData]);
  const { touched, setFieldValue } = formik;
  const { errors }: any = formik;

  const handleShow = () => {
    setShowProfile(!showProfile);
  };
  return (
    <>
      <h2 className="accordion-header  d-lg-none" id="headingOne">
        <button
          className="accordion-button "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#editProfile"
          aria-expanded="true"
          aria-controls="editProfile"
        >
          {t('settings.edit profile')}
        </button>
      </h2>
      <div
        id="editProfile"
        className="accordion-collapse collapse show  d-lg-block"
        aria-labelledby="headingOne"
        data-bs-parent="#myTabContent"
      >
        {(loading || loader1) && <Loader />}
        <div className="accordion-body">
          <div className="profile-content box-container">
            <h2 className="setting-title"> {t('settings.edit profile')}</h2>
            <div
              className="profile-bg"
              style={{
                backgroundImage: `url(${formik.values.cover_photo
                  ? S3_BASE_URL + formik.values.cover_photo
                  : BackImage
                  })`,
              }}
            >
              <label htmlFor="uploadCover" className="changebtn">
                {t('editprofile.change cover image')}
              </label>
              <input
                type="file"
                placeholder="Last Name"
                onChange={onImageChange}
                name="cover_photo"
                accept="image/jpeg,image/jpg,image/png"
                id="uploadCover"
              />
            </div>
            <div className="profile-box text-center">
              <div className="profile-logo" onClick={handleShow}>
                {/* <img
                  src={
                    typeof formik.values.profile_photo === "string"
                      ? S3_BASE_URL + formik.values.profile_photo
                      : imgData
                  }
                  className="img-fluid w-100"
                  alt="user"
                /> */}
                <Avatar
                  alt="profile"
                  src={
                    typeof formik.values.profile_photo === 'string'
                      ? S3_BASE_URL + formik.values.profile_photo
                      : imgData
                  }
                  name={
                    (userDetailData?.first_name?.replace(" ", "") || '') +
                    ' ' +
                    (userDetailData?.last_name || '')
                  }
                  maxInitials={2}
                  round={true}
                />
              </div>
              <div className="upload-profile-pic">
                <input
                  type="file"
                  placeholder="Last Name"
                  className="profile-link"
                  onChange={onImageChange}
                  name="profile_photo"
                  accept="image/jpeg,image/jpg,image/png"
                  id="uploadProfile"
                />

                <label htmlFor="uploadProfile" className="profile-link">
                  {t('editprofile.change profile image')}
                </label>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="input-main row">
                  <div className="col-md-12 input-part">
                    <textarea
                      placeholder={t('editprofile.bio') || 'Bio'}
                      className="addresstext form-control"
                      rows={3}
                      name="bio"
                      value={formik.values.bio}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                  </div>
                  <div className="col-md-6 padding input-part">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.first_name && touched.first_name && (
                      <div className="text-danger edit-form-error">
                        {errors.first_name}
                      </div>)}
                  </div>
                  <div className="col-md-6 input-part">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {errors.last_name && touched.last_name && (
                      <div className="text-danger edit-form-error">
                        {errors.last_name}
                      </div>)}
                  </div>
                  <div className="col-md-6 padding input-part">
                    <input
                      type="text"
                      placeholder="Username"
                      className="form-control"
                      name="username"
                      value={formik.values.username}
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      read-only
                    />
                  </div>
                  <div className="col-md-6 input-part">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder='Gender'
                    >
                      <option hidden>{t('editprofile.gender')}</option>
                      <option value="0">{t('editprofile.male')}</option>
                      <option value="1">{t('editprofile.female')}</option>
                    </select>
                  </div>
                  <div className="col-md-12 input-part">
                    <input
                      type="email"
                      placeholder={t('editprofile.email') || 'Email'}
                      className="form-control"
                      name="email"
                      value={formik.values.email}
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      read-only
                    />
                  </div>
                  <div className="col-md-6 padding  input-part">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option hidden>{t('editprofile.select') || 'Select'}</option>
                      {countryList &&
                        countryList.length > 0 &&
                        countryList.map((data: any) => {
                          return (
                            <option value={data?.id} key={data.id}>
                              {data?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                   <div className="col-md-6 input-part">
                    <input
                      type="date"
                      className="form-control"
                      name="date_of_birth"
                      value={formik.values.date_of_birth}
                      max={`${new Date().toISOString().split('T')[0]}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="col-md-12 input-part">
                    <textarea
                      placeholder={t('editprofile.address') || 'Address'}
                      className="addresstext form-control"
                      rows={3}
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                  </div>
                  <div className="col-md-6 padding  input-part">
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      name="state"
                      placeholder={t("editprofile.select") || 'Select'}
                      value={formik.values.state}
                    //   onChange={formik.handleChange}
                    onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                    >
                      <option>{t("editprofile.select") || 'Select'}</option>
                      {stateList &&
                        stateList.length > 0 &&
                        stateList.map((data: any) => {
                          return <option value={data?.id}>{data?.name}</option>;
                        })}
                    </select> */}
                    <input
                      type="text"
                      placeholder={t('editprofile.state') || 'State'}
                      className="form-control"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="col-md-6 padding  input-part">
                    <input
                      type="text"
                      placeholder={t('editprofile.city') || 'City'}
                      className="form-control"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  <div className="col-md-6 padding  input-part">
                    <input
                      type="number"
                      className="form-control"
                      placeholder={t('editprofile.zip code') || 'zip code'}
                      name="zipcode"
                      value={formik.values.zipcode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) &&
                        e.preventDefault()
                      }
                    />
                  </div>
                  <div className="col-md-6 padding  input-part">
                    <input
                      type="number"
                      className="form-control"
                      placeholder={
                        t('editprofile.phonenumber') || 'Phone Number'
                      }
                      name="phone_number"
                      value={formik.values.phone_number}
                      read-only
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    />
                  </div>

                  <div className="input-group col-md-12 input-part-error">
                    <span
                      className="input-group-text socialimg"
                      id="basic-addon1"
                    >
                      <img src={InternetIcon} className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('editprofile.website') || ''}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="website_url"
                      value={formik.values.website_url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="error-main-div">
                    {errors.website_url && touched.website_url && (
                      <div className="text-danger mt-2 mb-2 edit-profile-text-error-message">
                        {errors?.website_url}
                      </div>
                    )}
                  </div>
                  <div className="input-group col-md-12 input-part-error">
                    <span
                      className="input-group-text socialimg facebook"
                      id="basic-addon1"
                    >
                      <img src={FacebookeIcon} className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={
                        t('editprofile.paste your facebook profile link') || ''
                      }
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="facebook_url"
                      value={formik.values.facebook_url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="error-main-div">
                    {errors.facebook_url && touched.facebook_url && (
                      <div className="text-danger mt-2 mb-2 edit-profile-text-error-message">
                        {errors?.facebook_url}
                      </div>
                    )}
                  </div>
                  <div className="input-group col-md-12 input-part-error">
                    <span
                      className="input-group-text socialimg insta"
                      id="basic-addon1"
                    >
                      <img src={InstagramIcon} className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={
                        t('editprofile.paste your instagram profile link') || ''
                      }
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="instagram_url"
                      value={formik.values.instagram_url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="error-main-div">
                    {errors.instagram_url && touched.instagram_url && (
                      <div className="text-danger mt-2 mb-2 edit-profile-text-error-message">
                        {errors?.instagram_url}
                      </div>
                    )}
                  </div>
                  <div className="input-group col-md-12 input-part-error">
                    <span
                      className="input-group-text socialimg twitter"
                      id="basic-addon1"
                    >
                      <img src={TwitterIcon} className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('paste your twitter profile link') || ''}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="twitter_url"
                      value={formik.values.twitter_url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="error-main-div">
                    {errors.twitter_url && touched.twitter_url && (
                      <div className="text-danger mt-2 mb-2 edit-profile-text-error-message">
                        {errors?.twitter_url}
                      </div>
                    )}
                  </div>
                  <div className="input-group col-md-12 input-part-error">
                    <span
                      className="input-group-text socialimg twitter"
                      id="basic-addon1"
                    >
                      <img src={OtherProfile} className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('paste your other profile link') || ''}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="other_profile_url"
                      value={formik.values.other_profile_url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="error-main-div">
                    {errors.other_profile_url && touched.other_profile_url && (
                      <div className="text-danger mt-2 mb-2 edit-profile-text-error-message">
                        {errors?.other_profile_url}
                      </div>
                    )}
                  </div>
                  {/* <div className="input-group col-md-12 input-part">
                    <span
                      className="input-group-text socialimg linkdin"
                      id="basic-addon1"
                    >
                      <img src={LinkdinIcon} className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={
                        t("editprofile.paste your linkedin profile link") || ""
                      }
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="other_profile_url"
                      value={formik.values.other_profile_url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div> */}
                  {/* <div className="input-group col-md-12 input-part">
                                                <span className="input-group-text socialimg link" id="basic-addon1"><img src={LinkIcon} className="img-fluid" /></span>
                                                <input type="text" className="form-control" placeholder="Paste your other profile link" aria-label="Username" aria-describedby="basic-addon1" name="first_name" value={formik.values.first_name}/>
                                            </div> */}
                </div>
                <div>
                  <button className="btn save_btn" type="submit">
                    {t('editprofile.save changes') || 'Save Changes'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showProfile}
        onHide={handleShow}
        centered
        className="show-imge-popup"
      >
        <Modal.Body>
          {formik.values.profile_photo ? (
            <img
              src={
                typeof formik.values.profile_photo === 'string'
                  ? S3_BASE_URL + formik.values.profile_photo
                  : imgData
              }
              className="img-fluid w-100"
              alt="user"
            />
          ) : (
            <p className="mt-3" style={{ textAlign: 'center' }}>
              No profile Image found
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
