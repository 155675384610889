/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSubscription, cancelSubscription } from '../redux/action';
import { useFormik } from 'formik';
import { LoginSchema } from '../validationScrema/user';
import { Store } from '../redux/Actions';
import { AppDispatch } from '../redux';
import { ReactComponent as Coin } from '../assets/images/icons/coin.svg';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import PhoneScreen from '../assets/images/imgs/phone-screen.svg';
import Logo from '../assets/images/imgs/logo.svg';
import EyeIcon from '../assets/images/icons/ico_eye.svg';
import PlayStore from '../assets/images/imgs/google-play.svg';
import AppStore from '../assets/images/imgs/app-store.svg';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateMM, getDays } from '../utils/utility';
import { S3_BASE_URL } from '../service/aws/config';
import DeletePopup from './models/DeletePopup';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

export default function Subscription() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cancel, setCancel] = useState<any>(false);
  const [packageId, setPackageId] = useState('');
  const [packageUserId, setPackageUserId] = useState('');
  const [renew, setRenew] = useState(false);
  const [text, setText] = useState('');
  const userSubscriptionList: any = useSelector(
    (state: Store) => state.settingsDataReducer.userSubscriptionList
  );

  const isUserVerifiedFromRedux: boolean = useSelector(
    (state: Store) => state.userDataReducer.userDetail.user_verified
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getUserSubscription());
  }, []);

  const handleCancelSubscription = () => {
    setCancel(!cancel);
    setText('');
    const data: any = { package_id: packageId, package_user_id: packageUserId };
    dispatch(cancelSubscription(data));
    dispatch(getUserSubscription());
  };
  const handleRenewSubscription = () => {
    setText('');
    setRenew(!renew);
    const data: any = { package_id: packageId, package_user_id: packageUserId };
    dispatch(cancelSubscription(data));
    dispatch(getUserSubscription());
  };
  // useEffect(() => {
  // }, [userSubscriptionList]);
  const cancelSubscriptionPopup = (packageId: any, packageUserId: any) => {
    setText('Are you sure you want to cancel this subscription?');
    setPackageId(packageId);
    setPackageUserId(packageUserId);
    setCancel(!cancel);
  };
  const renewSubscriptionPopup = (packageId: any, packageUserId: any) => {
    setText('Are you sure you want to renew this subscription?');
    setPackageId(packageId);
    setPackageUserId(packageUserId);
    setRenew(!renew);
  };
  return (
    <>
      <h2 className="accordion-header d-lg-none" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#subscriptions"
          aria-expanded="false"
          aria-controls="subscriptions"
        >
          Subscriptions
        </button>
      </h2>
      <div
        id="subscriptions"
        className="accordion-collapse collapse d-lg-block"
        aria-labelledby="headingThree"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            <h2 className="setting-title">
              {t('subscriptions.subscriptions')}
            </h2>
            {userSubscriptionList &&
              userSubscriptionList.length > 0 &&
              userSubscriptionList.map((subscriptionData: any) => {
                const remainingDays = getDays(
                  subscriptionData?.expiry_date,
                  subscriptionData?.start_date
                );
                {
                }
                return (
                  <div
                    className={
                      remainingDays > 40
                        ? 'subscribe-box'
                        : 'subscribe-box past-subscribe-box'
                    }
                    key={subscriptionData.id}
                  >
                    <label className="plan-label">
                      {subscriptionData?.package_type === 0
                        ? subscriptionData?.next_package?.package_type === 2
                          ? t('subscribers.annual')
                          : subscriptionData?.next_package?.package_type ===
                              1 && t('subscribers.monthly')
                        : subscriptionData?.package_type === 2
                        ? t('subscribers.annual')
                        : subscriptionData?.package_type === 1 &&
                          t('subscribers.monthly')}
                    </label>
                    {/* <label className="plan-label">{subscriptionData?.user?.package[key]?.title}</label> */}
                    <div className="post-user post-user-control d-flex align-items-center px-0">
                      <div className="post-user-logo post-user-logo-sm">
                        {/* {subscriptionData?.package_user?.profile_photo ? (
                          <img
                            src={
                              S3_BASE_URL +
                              subscriptionData?.package_user?.profile_photo
                            }
                            className="img-fluid"
                          />
                        ) : ( */}
                        <Avatar
                          size="70"
                          src={
                            S3_BASE_URL +
                            subscriptionData?.package_user?.profile_photo
                          }
                          round="true"
                          maxInitials={2}
                          name={
                            subscriptionData?.package_user?.first_name +
                            ' ' +
                            subscriptionData?.package_user?.last_name
                          }
                        />
                        {/* )} */}
                      </div>
                      <div className="col">
                        <h5 className="post-user-name justify-content-start">
                          {subscriptionData?.package_user?.first_name +
                            ' ' +
                            subscriptionData?.package_user?.last_name}
                          {subscriptionData?.package_user?.user_verified && (
                            <VerifyIcon className="mx-1" />
                          )}
                        </h5>
                        <p className="text-msg user-comment">
                          @{subscriptionData?.package_user?.username}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap align-items-center subscriber-body-text">
                      <label className="date-label my-2">
                        {t('subscribers.start date')}:
                        {formatDateMM(
                          subscriptionData?.start_date,
                          'DD MMM YYYY'
                        )}
                      </label>
                      {!!subscriptionData?.price && (
                        <div className="d-flex align-items-center item-price-position">
                          <Coin className="mr-2" />
                          <p className="sub-amount ">
                            {subscriptionData?.package_type === 0
                              ? subscriptionData?.next_package.price
                              : subscriptionData?.price}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <label className="dayslabel">
                        {subscriptionData?.package_type == 0 &&
                        subscriptionData?.next_package
                          ? subscriptionData?.remaining_days +
                            subscriptionData?.next_package?.remaining_days
                          : subscriptionData?.remaining_days || 0}
                        &nbsp;{t('subscribers.days remaining')}
                      </label>
                      <button
                        className="btn btn-link text-danger p-0"
                        onClick={() =>
                          cancelSubscriptionPopup(
                            subscriptionData?.package_id,
                            subscriptionData?.package_user?.id
                          )
                        }
                      >
                        {t('subscriptions.cancel subscription')}
                      </button>

                      {/* <button
                          className="btn btn-link text-info p-0"
                          onClick={() =>
                            renewSubscriptionPopup(
                              subscriptionData?.package_id,
                              subscriptionData?.package_user?.id
                            )
                          }
                        >
                          {t('subscriptions.renew subscription')}
                        </button> */}
                    </div>
                    {subscriptionData?.package_type === 0 && (
                      <div className="d-flex mt-3">
                        <p className="trial-text">
                          Free Trial ends in {subscriptionData?.remaining_days}{' '}
                          Days
                        </p>
                      </div>
                    )}
                  </div>
                );
                // });
                //return data
              })}

            {/* <div className="subscribe-box trial-plan">
              <label className="plan-label">Monthly</label>
              <div className="post-user post-user-control d-flex align-items-center px-0">
                <div className="post-user-logo post-user-logo-sm">
                  <img
                    src="assets/images/imgs/user1.svg"
                    className="img-fluid"
                  />
                </div>
                <div className="col">
                  <h5 className="post-user-name justify-content-start">
                    Ruby Baker
                  </h5>
                  <p className="text-msg user-comment">@karen</p>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <label className="date-label my-2">
                  Plan start date: 16 July 2021
                </label>
                <p className="sub-amount">98.99</p>
              </div>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <label className="dayslabel">156 Days Remaining</label>
                <button className="btn btn-link text-danger p-0">
                  Cancel Subscription
                </button>
              </div>
              <p className="trial-text">Free Trial ends in 2 Days</p>
            </div>
            <div className="subscribe-box past-subscribe-box">
              <label className="plan-label">Monthly</label>
              <div className="post-user post-user-control d-flex align-items-center px-0">
                <div className="post-user-logo post-user-logo-sm">
                  <img
                    src="assets/images/imgs/user1.svg"
                    className="img-fluid"
                  />
                </div>
                <div className="col">
                  <h5 className="post-user-name justify-content-start">
                    Ruby Baker
                  </h5>
                  <p className="text-msg user-comment">@karen</p>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <label className="date-label my-2">
                  Plan start date: 16 July 2021
                </label>
                <p className="sub-amount">98.99</p>
              </div>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <label className="dayslabel">00 Days Remaining</label>
                <button className="btn btn-link text-info p-0">Renew</button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <DeletePopup
        handleClose={renew ? renewSubscriptionPopup : cancelSubscriptionPopup}
        handleSubscription={
          renew ? handleRenewSubscription : handleCancelSubscription
        }
        show={renew || cancel}
        text={text}
        renew={renew}
      />
    </>
  );
}
