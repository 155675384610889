import { Modal, Button, Spinner } from 'react-bootstrap';
import story from '../../assets/images/icons/story.svg';
import bg from '../../assets/images/bg/bg.svg';
import {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { uploadFileAmazonS3 } from '../../service/index.';

import { IoMdTrash } from 'react-icons/io';
import { apiCall, METHOD } from '../../service/baseApiCall';
import { AppDispatch, dispatch } from '../../redux';
import { useDispatch } from 'react-redux';
import { getStories } from '../../redux/action';
import { ADD_STORIES_URL } from '../../redux/ApiEndPoints';

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { reloadResources } from 'i18next';
import { useTranslation } from 'react-i18next';
import { MEDIA_SIZE } from '../../constants/App-constant';
import { getToken } from '../../utils/utility';

const CreateStory = ({
  show,
  handleClose,
  createStoryDep,
  setCreateStoryDep,
}: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [imgArr, setImgArr] = useState<any[]>([]);
  const [selectedImg, setSelectedImg] = useState<any>('');
  const [storyLoader, setStoryLoader] = useState(false);
  const [uploadStoryContent, setUploadStoryContent] = useState(true);
  const [postErr, setPostErr] = useState(false);
  const [storySizeImage, setStorySizeImage] = useState(false);
  const { t } = useTranslation();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const slider = useRef<any>(null);

  function scroll(e: any) {
    if (slider === null) return 0;

    e.wheelDelta > 0
      ? slider?.current?.slickNext()
      : slider?.current?.slickPrev();
  }

  useEffect(() => {
    window.addEventListener('wheel', scroll, true);

    return () => {
      window.removeEventListener('wheel', scroll, true);
    };
  }, [scroll]);
  // const slider = createRef<any>();
  // const scroll = useCallback(
  //     (y:any) => {
  //       if (y > 0) {
  //         return slider?.current?.slickNext();
  //       } else {
  //         return slider?.current?.slickPrev();
  //       }
  //     },
  //     [slider]
  //   );
  //  useEffect(() => {
  //     window.addEventListener("wheel", e => {
  //       scroll(e.deltaY);
  //     });
  //   }, [scroll]);

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setStorySizeImage(false);
    const file: any = e.target.files?.[0];
    if (file?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      if (!file?.name.match(/\.(jpg|jpeg|png)$/)) {
        setPostErr(true);
        return false;
      } else {
        setUploadStoryContent(false);
        setImgArr([...imgArr, e.target.files?.[0]]);
        setSelectedImg(e.target.files?.[0]);
        setPostErr(false);
      }
    } else {
      setStorySizeImage(true);
    }
  };
  const deleteUplaodedImg = () => {
    let newImgArr = imgArr;
    setImgArr(imgArr.filter((img) => img.name !== selectedImg.name));
    newImgArr = newImgArr.filter((img) => img.name !== selectedImg.name);

    setSelectedImg(newImgArr[0]);
  };

  const createStory = async () => {
    setStorySizeImage(false);
    const storyUpload = Promise.allSettled(
      imgArr.map((img, idx) =>
        uploadFileAmazonS3(img, 'story/images/').then(({ Key, Location }) => {
          return { Key, Location };
        })
      )
    );
    setStoryLoader(true);
    storyUpload.then((data: any) => {
      data.map((item: any, idx: any) => {
        apiCall(
          ADD_STORIES_URL,
          {
            user: getToken('userId'),
            story_image: item?.value?.Key,
          },
          (res: any) => {
            if (idx === imgArr?.length - 1 && res.success) {
              dispatch(getStories());
              setCreateStoryDep(!createStoryDep);
              setSelectedImg('');
              setImgArr([]);
              handleModalClose();
              setStoryLoader(false);
            }
          },
          (err: any) => {},
          METHOD.POST,
          {}
        );
      });
    });
  };

  // const createStory = async () => {
  //   const storyUpload = Promise.allSettled(
  //     imgArr.map((img, idx) =>
  //       uploadFileAmazonS3(img).then(({ Key, Location }) => {
  //         return { Key, Location };
  //       })
  //     )
  //   );
  //   setStoryLoader(true);
  //   storyUpload.then((data: any) => {
  //     data.map((item: any, idx: any) => {
  //       apiCall(
  //         ADD_STORIES_URL,
  //         {
  //           user: localStorage.getItem("userId"),
  //           story_image: item.value.Key,
  //         },
  //         (res: any) => {
  //           if (idx === imgArr.length - 1 && res.success) {
  //             dispatch(getStories());
  //             setSelectedImg("");
  //             setImgArr([]);
  //             handleModalClose();
  //             setStoryLoader(false);
  //           }
  //         },
  //         (err: any) => {},
  //         METHOD.POST,
  //         {}
  //       );
  //     });
  //   });
  // };

  const handleModalClose = () => {
    setImgArr([]);
    setStorySizeImage(false);
    setSelectedImg('');
    handleClose();
    setStoryLoader(false);
    setTimeout(() => {
      setUploadStoryContent(true);
    }, 1000);
    dispatch(getStories());
  };
  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      // backdrop="static"
      // keyboard={false}
      className="story-popup upload-story-popup"
      centered
    >
      <Modal.Body>
        {!imgArr.length && (
          <div className="create-story-content">
            <img src={story} alt="story" />
            <h6>{t('home.upload image to create story')}</h6>
            <p>{t('home.story wil disapper after 24 hour')}</p>
            <div className="upload-img">
              {/* <input type="file" id="upload" /> */}
              <input
                type="file"
                id="upload"
                hidden
                onChange={(e) => handleImageUpload(e)}
              />
              {postErr && (
                <div className="text-danger text-center mt-5">
                  {t('home.upload file')}
                </div>
              )}
              {storySizeImage && (
                <div className="text-danger text-center mt-5">
                  Please Select less then {MEDIA_SIZE.IMAGE_SIZE / 1024} MB
                  Image file
                </div>
              )}
              <label htmlFor="upload">{t('home.upload image')}</label>
            </div>
          </div>
        )}
        {imgArr.length > 0 && (
          <div className="upload-story-content">
            <div className="uploadedimg">
              <div
                className="blurbackground"
                style={{
                  backgroundImage: `url(${URL.createObjectURL(selectedImg)})`,
                }}
              ></div>
              {selectedImg && (
                <>
                  <button
                    className="delete-img-btn"
                    onClick={deleteUplaodedImg}
                  >
                    <IoMdTrash color="#fff" />
                  </button>
                  <img src={URL.createObjectURL(selectedImg)} alt="" />
                </>
              )}
            </div>
            <div className="upload-more">
              <label className="add-more">
                <input
                  type={'file'}
                  hidden
                  // accept="image/jpeg,image/jpg,image/png"
                  // value={uploadedImgs[uploadedImgs.length - 1]}
                  onChange={(e) => handleImageUpload(e)}
                />
                <i
                  className="las la-plus"
                  onClick={() => {
                    setPostErr(false);
                  }}
                ></i>
              </label>

              <div className="multiple-img" style={{ width: '100%' }}>
                <Slider {...settings} ref={slider}>
                  {imgArr.map((img, idx) => (
                    <button
                      className={`story-img-t`}
                      key={`UPLOADED_IMG${idx}`}
                      onClick={() => setSelectedImg(img)}
                    >
                      <img
                        src={URL.createObjectURL(img)}
                        alt="bg"
                        width="100px"
                      />
                    </button>
                  ))}
                </Slider>
              </div>
            </div>
            <div></div>

            {postErr && (
              <div className="pt-2 text-danger pl-2">
                Upload only .jpeg/.jpg/.png files
              </div>
            )}

            <Button onClick={createStory} disabled={storyLoader}>
              {storyLoader ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                'Add to story'
              )}
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default CreateStory;
