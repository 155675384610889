import React, { useEffect } from 'react';
import raferralimg from '../assets/images/imgs/referral.svg';
import copyIcon from '../assets/images/icons/copy-i.svg';
import shareIcon from '../assets/images/icons/share-i.svg';
import { toast } from 'react-toastify';
import { RWebShare } from 'react-web-share';
import { getToken } from '../utils/utility';
import { userDetail } from '../redux/action';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux';
import { Store } from '../redux/Actions';
import { useSelector } from 'react-redux';

const Referral = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userDetailData: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  useEffect(() => {
    const userId = getToken('userId');
    dispatch(userDetail(Number(userId)));
  }, []);
  const handleCopyText = (data: any) => {
    navigator.clipboard.writeText(data);
    toast.success('Referral Code Copied');
  };
  return (
    <>
      <div
        id="subscriptions"
        className="accordion-collapse collapse d-lg-block referral-page"
        aria-labelledby="headingThree"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            <div className="referral-box">
              <h2>Unique Referral Code</h2>
              <div className="referral-img">
                <img src={raferralimg} alt="referral" />
              </div>
              <div className="referral-codebox">
                {userDetailData?.refrence_code}
              </div>
              <div className="copy-ion referral-button">
                <div
                  className="btn-code mr-3"
                  onClick={() => handleCopyText(userDetailData?.refrence_code)}
                >
                  <img className="share-btn" src={copyIcon} alt="icon" />
                </div>
                <div className="btn-code ml-2">
                  <RWebShare
                    data={{
                      text: `Hey there! I wanted to share an awesome mobile and web app with you that I've been using to connect with friends and family,if you're intrested in trying it out, i have a referral code that will give you some coins as welcome bonus.Simply enter the code ${userDetailData?.refrence_code} when you sign up and we both win! Let me know if you have any questions,and happy fanshub-ing!`, // pending
                      url: 'https://app.fanshub.live',
                    }}
                    onClick={() => console.log('')}
                  >
                    <img className="share-btn" src={shareIcon} alt="icon" />
                  </RWebShare>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Referral;
