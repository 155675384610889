import Home from "../pages/Home";
import Login1 from "../pages/Login";
import SignUp from "../pages/SignUp";
import ForgetPassword from "../pages/ForgetPassword";
import Explore from "../pages/Explore";
import Notification from "../pages/Notification";
import Settings from "../pages/Settings";
import MyProfile from "../components/MyProfile";
import OtpVerification from "../pages/OtpVerification";
import ResetPassword from "../pages/ResetPassword";
import Message from "../pages/Message";
import Favourite from "../pages/Favourite";
import HashTags from "../pages/HashTags";
import ExploreList from "../pages/ExploreList";
import SearchProfile from "../components/SearchProfile";
export const routes = [
  {
    path: "/login",
    element: Login1
  },
  {
    path: "/signup",
    element: SignUp
  },
  {
    path: "/forget-password",
    element: ForgetPassword
  },
  {
    path: "/reset-password",
    element: ResetPassword
  },
  {
    path: "/otp-verification",
    element: OtpVerification
  },
];
export const privateRoutes = [
  {
    path: "/home",
    element: Home,
    sideBar: true,
    header: true,
    container:true,
    userCard:true
  },
  {
    path: "/",
    element: Home,
    sideBar: true,
    header: true,
    container:true,
    userCard:true,
  },
  {
    path: "/messages",
    element: Message,
    sideBar: false,
    header: true,
    container:false,
    userCard:false
  },
  {
    path: "/explore",
    element: Explore,
    sideBar: true,
    header: true,
    container:false,
    userCard:false
  },
  {
    path: "/explore-list",
    element: ExploreList,
    sideBar: true,
    header: true,
    container:false,
    userCard:false
  },
  {
    path: "/notification",
    element: Notification,
    sideBar: true,
    header: true,
    container:false,
    userCard:false
  },
  {
    path: "/favourite",
    element: Favourite,
    sideBar: true,
    header: true,
    container:false,
    userCard:false
  },
 
  {
    path: "/settings/:page",
    element: Settings,
    sideBar: true,
    header: true,
    container:false,
    userCard:false
  },
  {
    path: "/my-profile",
    element: MyProfile,
    sideBar: true,
    header: true,
    container:false,
    userCard:false
  },
  {
    path: "/tags/:tag",
    element: HashTags,
    sideBar: true,
    header: true,
    container:false,
    userCard:false
  },
  {
    path: "/user-profile/:id",
    element: SearchProfile,
    sideBar: true,
    header: true,
    container:false,
    userCard:false
  }

];

