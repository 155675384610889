import React from "react";
import imgIcon from "../assets/images/icons/imgIcon.svg";
import videoIcon from "../assets/images/icons/videoIcon.svg";
import mediaIcon from "../assets/images/icons/mediaIcon.svg";
import { useTranslation } from "react-i18next";

const UnlockPopup = ({ unlockPopup, unlockPackage, unlockRef, packageData }: any) => {
  const { t } = useTranslation()
  return (
    <div
      ref={unlockRef}
      className={` post-modal file-upload unlock-popup ${unlockPopup ? "show-post-modal" : ""
        }`}
    >
      <h4 className="text-center">Unlock Package</h4>
      <i className="las la-times position-absolute" style={{ right: "8px", top: "10px", fontSize: "28px" }}></i>
      <h6 style={{
        fontSize: '22px',
        textAlign: 'center',
        margin: "6px 0px 0px 0px",
        textTransform: "capitalize",
        color: "#0296dd",
        fontWeight: 600,
      }}>{packageData?.media_package_name}</h6>
      <div className="coins">
        <h6>
          {packageData?.media_package_price} <span style={{ fontWeight: 500, }}>coins</span>
        </h6>
      </div>
      <div className="media-btns">
        <button className="btn hide-input">
          <input type="file" accept="image/*" id="uploadimg" />
          <label htmlFor="uploadimg">
            <img src={imgIcon} alt="" />
            <p>{packageData?.image ?? 0}</p>
          </label>
        </button>
        <button className="btn">
          <img src={videoIcon} alt="" />
          <p>{packageData?.audio ?? 0}</p>
        </button>
        <button className="btn paidPostmodal-control">
          <img src={mediaIcon} alt="" />
          <p>{packageData?.video ?? 0}</p>
        </button>
      </div>
      <div className="modal-footer justify-content-center border-0 pt-0">
        <button
          type="button"
          className="btn btn-login px-4"
          disabled={!packageData?.media_package_price}
          onClick={unlockPackage}
        >
          {t("home.unlock")}
        </button>
      </div>
    </div>
  );
};
export default UnlockPopup;
